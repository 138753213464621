import React from 'react'
import { Navbar,Nav,NavDropdown,Form,FormControl,Button,Dropdown } from 'react-bootstrap'

const menu = {
    "en":{
        "home":"Home", "about":"About", "category":"Category", "apply":"Apply", "awards":"Awards","ourproduct":"Our Products", "dropdown":"Drop Down","rtprice":"Real-time Prices","fnews":"Financial News","histprice":"Historical Data","riskman":"Risk Management","traderecord":"Trade Record","memberrank":"Memberships", "terms":"Rules & Terms","jury":"Jury","contact":"Contact"
    },
    "zh":{
        "home":"主頁", "quote":"實時報價", "aboutus":"關於我們", "awards":"Awards", "ourservice":"我們的業務","ourproduct":"我們的產品", "dropdown":"更多","rtprice":"實時市場價格","fnews":"財經新聞","histprice":"歷史價格圖表","riskman":"風險管理及入市計劃","traderecord":"交易詳情","memberrank":"會員階級制度"
    },
    "cn":{
        "home":"主页", "about":"关于我们", "category":"组别", "apply":"申请", "awards":"奖项","terms":"条款","jury":"评委","contact":"联系我们","links":"联结"
    },
}

class IYFAONavBarTop extends React.Component{
    constructor(props){
        super(props);
        this.state = {  
          
        };
    
        this.clicknav = this.clicknav.bind(this)

      }
componentDidMount(){
    this.props.setval({comp:"iypo-cn",lang:"cn"})
}
      clicknav(page,pos){
        if(page===this.props.pageSelected){
            document.getElementById(pos).scrollIntoView();
        }else {
            this.props.setval({pageSelected:page,pagepos:pos});           
        } 
      }
//{this.props.companyname[this.props.lang]}
    render(){
        return(
            <div>
                <div className="row">
                    <div className={["col-md-12","fullwidth"].join(" ")}>
                       
                            <Navbar bg="light" variant="light" expand="lg" collapseOnSelect >
                                <Navbar.Brand className="navbar-brand" ><img src="../assets/iypo-logo.PNG" width="100px" height="100px"/></Navbar.Brand>
                                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                <Navbar.Collapse className="navbar-collapse" id="basic-navbar-nav">
                                    <Nav className="mr-auto">
                                    <Nav.Link style={{fontFamily:"Arial",fontSize:"1.6em",color:"rgba(125, 104, 0,1)",fontWeight:"bold",marginLeft:"10px"}} href="/iypo-cn" onClick={(e)=>{
                                        //this.props.setval({pageSelected:"home"})
                                        }}>{menu[this.props.lang]["home"]}</Nav.Link>
                                    {/* <Nav.Link style={{fontFamily:"Arial",fontSize:"1.6em",color:"rgba(125, 104, 0,1)",fontWeight:"bold",marginLeft:"10px"}} href="/iypo-cn/about" onClick={(e)=>{
                                        //this.props.setval({pageSelected:"about"})
                                        }}>{menu[this.props.lang]["about"]}</Nav.Link> */}
                                    <Nav.Link style={{fontFamily:"Arial",fontSize:"1.6em",color:"rgba(125, 104, 0,1)",fontWeight:"bold",marginLeft:"10px"}} href="/iypo-cn/apply"onClick={(e)=>{
                                        //this.props.setval({pageSelected:"apply"})
                                        }}>{menu[this.props.lang]["apply"]}</Nav.Link>
                                    <Nav.Link style={{fontFamily:"Arial",fontSize:"1.6em",color:"rgba(125, 104, 0,1)",fontWeight:"bold",marginLeft:"10px"}} href="/iypo-cn/category" onClick={(e)=>{
                                        //this.props.setval({pageSelected:"category"})
                                        }}>{menu[this.props.lang]["category"]}</Nav.Link>
                                    <Nav.Link style={{fontFamily:"Arial",fontSize:"1.6em",color:"rgba(125, 104, 0,1)",fontWeight:"bold",marginLeft:"10px"}} href="/iypo-cn/awards" onClick={(e)=>{
                                        //this.props.setval({pageSelected:"awards"})
                                        }}>{menu[this.props.lang]["awards"]}</Nav.Link>
                                    <Nav.Link style={{fontFamily:"Arial",fontSize:"1.6em",color:"rgba(125, 104, 0,1)",fontWeight:"bold",marginLeft:"10px"}} href="/iypo-cn/terms" onClick={(e)=>{
                                        //this.props.setval({pageSelected:"terms"})
                                        }}>{menu[this.props.lang]["terms"]}</Nav.Link>
                                    {/* <Nav.Link style={{fontFamily:"Arial",fontSize:"1.6em",color:"rgba(125, 104, 0,1)",fontWeight:"bold",marginLeft:"10px"}} href="/iypo-cn/jury" onClick={(e)=>{
                                        //this.props.setval({pageSelected:"jury"})
                                        }}>{menu[this.props.lang]["jury"]}</Nav.Link> */}
                                    <Nav.Link style={{fontFamily:"Arial",fontSize:"1.6em",color:"rgba(125, 104, 0,1)",fontWeight:"bold",marginLeft:"10px"}} href="/iypo-cn/contact" onClick={(e)=>{
                                        //this.props.setval({pageSelected:"jury"})
                                        }}>{menu[this.props.lang]["contact"]}</Nav.Link>
                                        <Nav.Link style={{fontFamily:"Arial",fontSize:"1.6em",color:"rgba(125, 104, 0,1)",fontWeight:"bold",marginLeft:"10px"}} href="/iypo-cn/links" onClick={(e)=>{
                                        //this.props.setval({pageSelected:"jury"})
                                        }}>{menu[this.props.lang]["links"]}</Nav.Link>
                                   
                                    </Nav>
                                   
                                </Navbar.Collapse>
                            </Navbar>
                         
                    </div>
                </div>
            </div>
        )  
    }
}

export default IYFAONavBarTop;