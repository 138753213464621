import '../../App.css';
import React, {Component, Container} from 'react';
import {Row,Col,Image,Navbar, Nav, NavItem, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import YoutubeBackground from 'react-youtube-background';
import Card from './card';
import NavBarTop from "./navbar";
import Footer from "./footer";

var count = []

class IcmtCnWinners extends Component {
  constructor(props){
    super(props);
    this.state = {
       
    };

    this.setval = this.setval.bind(this)
  }

  setval(obj){
    this.setState(obj)
  }
  componentDidMount(){
    this.props.setval({comp:"icmt-cn"}) 
  }      
      
  render(){
    for(let i=1;i<=21;i++){
      count[i] = i

    }
    return (
      <div>

      <div className="home-content-div" style={{textAlign:"justify",textJustify:"inter-word", padding:"4% 6%",fontFamily:"Courgette"}}>
        
        <h2 className='winners-title'>{"2023 国际古典音乐才艺大赛"}</h2>
        <h5 className='winners-title'>{"获奖者"}</h5>

        <div className={["winner-body"].join(" ")} >
         {
           count.map((item)=>{
            return  <img src={"../assets/icmt2023winners/IMG_"+parseInt(item)+".PNG"} className={["winner-card"].join(" ")} />
              
           })
         }  
        </div>
           
        <div style={{fontSize:"0.9rem",marginTop:"65px"}}>{"此页面上的个人资料由参赛者提供，列表并不尽录。"}</div>
      </div>
      </div>
    );
  }
  }
  

export default IcmtCnWinners;
