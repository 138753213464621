const iyfaoGroupCat = {
    "Painting" :{
        "Any desired topic of your choice" : {
            999:{"cn-name":"任何您想选择的主题"},
            1:{
                "id":1,"number":"P01", "name":"Debut", "name-cn":"幼兒", "des":"Age 7 or below", "des-cn":"<=7", "price":20
                },
                2:{
                "id":2,"number":"P02", "name":"Junior", "name-cn":"兒童", "des":"Age 7 to 9", "des-cn":"7-9","price":20
                },
                3:{
                "id":3,"number":"P03", "name":"Emerging", "name-cn":"小童","des":"Age 9 to 12","des-cn":"9-12","price":20
                },
                4:{
                "id":4,"number":"P04", "name":"Youth", "name-cn":"青少年","des":"Age 12 to 15", "des-cn":"12-15","price":20
                },
                5:{
                "id":5,"number":"P05", "name":"Young Artist", "name-cn":"青年","des":"Age 15 to 18","des-cn":"15-18", "price":20
                },
                6:{
                "id":6,"number":"P06", "name":"Artist", "name-cn":"公開", "des":"Age 18 or above","des-cn":">=18", "price":20
                },
        },  
    },

    "Theme" :{
        "2023 Theme - Peace" : {
            999:{"cn-name":"和平"},
            1:{
                "id":1,"number":"T01", "name":"Debut", "name-cn":"幼兒", "des":"Age 7 or below", "des-cn":"<=7", "price":20
                },
                2:{
                "id":2,"number":"T02", "name":"Junior", "name-cn":"兒童", "des":"Age 7 to 9", "des-cn":"7-9","price":20
                },
                3:{
                "id":3,"number":"T03", "name":"Emerging", "name-cn":"小童","des":"Age 9 to 12","des-cn":"9-12","price":20
                },
                4:{
                "id":4,"number":"T04", "name":"Youth", "name-cn":"青少年","des":"Age 12 to 15", "des-cn":"12-15","price":20
                },
                5:{
                "id":5,"number":"T05", "name":"Young Artist", "name-cn":"青年","des":"Age 15 to 18","des-cn":"15-18", "price":20
                },
                6:{
                "id":6,"number":"T06", "name":"Artist", "name-cn":"公開", "des":"Age 18 or above","des-cn":">=18", "price":20
                },
        },  
    },

    "Colouring" :{
        "Preschool Group" : {
            1:{
                "id":1,"number":"C-1", "name":"Debut", "des":"Age 4 or below",  "price":47
                },
                2:{
                "id":2,"number":"C-2", "name":"Junior	", "des":"Age 4 to 5", "price":47
                },
                3:{
                "id":3,"number":"C-3", "name":"Emerging", "des":"Age 5 to 8","price":47
                },
            
        },
       
    },

    "Sculpturing" :{
        "Ceremics" : {
            1:{
                "id":1,"number":"SC-1", "name":"Debut", "des":"Age 7 or below",  "price":55
                },
                2:{
                "id":2,"number":"SC-2", "name":"Junior	", "des":"Age 7 to 9", "price":55
                },
                3:{
                "id":3,"number":"SC-3", "name":"Emerging", "des":"Age 9 to 12","price":55
                },
                4:{
                "id":4,"number":"SC-4", "name":"Youth","des":"Age 12 to 15", "price":65
                },
                5:{
                "id":5,"number":"SC-5", "name":"Young Artist","des":"Age 15 to 18", "price":65
                },
                6:{
                "id":6,"number":"SC-6", "name":"Artist","des":"Age 18 or above", "price":75
                },
        }, 
        "Handcraft" : {			
            1:{
                "id":1,"number":"SH-1", "name":"Debut", "des":"Age 7 or below",  "price":55
                },
                2:{
                "id":2,"number":"SH-2", "name":"Junior	", "des":"Age 7 to 9", "price":55
                },
                3:{
                "id":3,"number":"SH-3", "name":"Emerging", "des":"Age 9 to 12","price":55
                },
                4:{
                "id":4,"number":"SH-4", "name":"Youth","des":"Age 12 to 15", "price":65
                },
                5:{
                "id":5,"number":"SH-5", "name":"Young Artist","des":"Age 15 to 18", "price":65
                },
                6:{
                "id":6,"number":"SH-6", "name":"Artist","des":"Age 18 or above", "price":75
                },
        
        },
      
    },

}

export default iyfaoGroupCat;