import '../../App.css';
import React, {Component, Container} from 'react';
import {Row,Col,Image,Navbar, Nav, NavItem, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import YoutubeBackground from 'react-youtube-background';
import Card from './card';
import NavBarTop from "./navbar";
import Footer from "./footer";

var count = []

class TeacherProfile extends Component {
  constructor(props){
    super(props);
    this.state = {
       
    };

    this.setval = this.setval.bind(this)
  }

  setval(obj){
    this.setState(obj)
  }
  componentDidMount(){
    document.title="International Classical Music Talent Competition"
    var link1 = document.getElementById('favicon')
    var link2 = document.getElementById('touchicon')
    link1.href="/assets/icmt_logo.PNG"
    link2.href="/assets/icmt_logo.PNG"
     console.log(this.props.selectedTeacher)
    this.props.setval({comp:"icmt"}) 
  }      
      
  render(){

    return (
      <div style={{display:'block', marginBottom:"100px"}}>

      {/* <div className="jury-profile">
          <img src={"assets/icmtteacher2024/"+this.props.selectedTeacher["name"]+".png"} alt="" width="100%" />
          <h4 style={{marginTop:"5px"}}><b>{this.props.selectedTeacher["name"]}</b></h4>
          <h5><em>{}</em></h5>
          </div>

      <div className="jury-text">
          <p>
            {
              this.props.selectedTeacher["profile"]
            }
          </p>
      </div> */}


  </div>
    );
  }
  }
  

export default TeacherProfile;
