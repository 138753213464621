import '../../App.css';
import React, {Component, Container} from 'react';
import {Row,Col,Image,Navbar, Nav, NavItem, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import YoutubeBackground from 'react-youtube-background';
import Card from './card';
import NavBarTop from "./navbar";
import Footer from "./footer";


class IypcCnPastComp extends Component {
  constructor(props){
    super(props);
    this.state = {
       
    };

    this.setval = this.setval.bind(this)
  }

  setval(obj){
    this.setState(obj)
  }
  componentDidMount(){
    this.props.setval({comp:"iypo-cn",lang:"cn"}) 
  }      
      
  render(){
    return (
        <div>
        <div className="home-content-div" style={{textAlign:"justify",textJustify:"inter-word", padding:"4% 6%",fontFamily:"Arial"}}>
          
          <h2>{"往届比赛"}</h2>
  
          <div style={{width:"100%",display:"flex",flexDirection:"row",margin:"10px",padding:"10px",cursor:"pointer",border:"1px solid rgba(0,0,0,0.3)"}} onClick={(e)=>{
                  window.open("http://youngartistcompetition.org/iyfao")
              }}>
             <div style={{width:"40%",display:"flex",alignItems:"center",justifyContent:"center"}}>
                  <img src="/assets/iyfao-poster.png" width="40%" />
              </div>
              <div style={{width:"60%",display:"flex",alignItems:"center",justifyContent:"center",fontSize:"1.5rem"}} >
                  <b><i>{"国际青年美术家公开赛"}</i></b>
              </div>
          </div>
  
             
          
        </div>
        </div>
    );
  }
  }
  

export default IypcCnPastComp;
