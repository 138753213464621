import '../../App.css';
import React, {Component} from 'react';
import {Row, Col, Button,Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import YoutubeBackground from 'react-youtube-background';

import NavBarTop from "./navbar";
import Footer from "./footer";
import iyfaoCat from './iyfao-cat';

class IYFAOCategory extends Component {
  constructor(props){
    super(props);
    this.state = {  
      group: iyfaoCat["Theme"],
      chosenTab:"Theme",
    };

    this.changeGroup = this.changeGroup.bind(this)
    this.setval = this.setval.bind(this)
  }
  componentDidMount(){
    this.props.setval({comp:"iypo-cn",lang:"cn"})
  }
  setval(obj){
    this.setState(obj)
  }
  changeGroup(val){
   const _group = JSON.parse(JSON.stringify(iyfaoCat[val]))
   //alert(JSON.stringify(_group))
   this.setState({group:_group,chosenTab:val})
  }
  render(){
    return (
      <div className="category-div">
        <video loop autoPlay muted id="myVideo">
          <source
            src="../assets/category.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
        
        <div id="cat-content"> 
          {/* <button className={[(this.state.instrument=="piano")?"cat-button-selected":"cat-button"].join(" ")} 
            style={{fontWeight:"bold",marginBottom:"7px"}} onClick={(e)=>{this.changeGroup("Colouring")}}>Colouring</button> */}
          <button className={[(this.state.instrument=="strings")?"cat-button-selected":"cat-button"].join(" ")} 
             style={{fontWeight:"bold",marginBottom:"7px"}} onClick={(e)=>{this.changeGroup("Theme")}}>主题组別</button>
          <button className={[(this.state.instrument=="vocal")?"cat-button-selected":"cat-button"].join(" ")} 
             style={{fontWeight:"bold",marginBottom:"7px"}} onClick={(e)=>{this.changeGroup("Painting")}}>自由創作組別</button>
          {/* <button className={[(this.state.instrument=="woodwind")?"cat-button-selected":"cat-button"].join(" ")} 
             style={{fontWeight:"bold",marginBottom:"7px"}} onClick={(e)=>{this.changeGroup("Sculpturing")}}>Sculpturing</button> */}
          {/* <button className={[(this.state.instrument=="brass")?"cat-button-selected":"cat-button"].join(" ")} 
             style={{fontWeight:"bold",marginBottom:"7px"}} onClick={(e)=>{this.changeGroup("brass")}}>Brass</button>
           */}
        </div>
        <Container fluid style={{padding:"2% 5%",fontFamily:"Arial",position:"relative",zIndex:"2"}} >

            <div style={{display:"flex",flexDirection:"column",justifyContent:"left"}}>
              <p>{"如果参赛者的年龄与2组重叠，则参赛者可以进入任一组或同时进入两个组。"}</p>
              <p>{"参赛者可以使用任何材料，包括但不限于水彩、丙烯、油画、粉彩、水墨等。"}</p>
            </div>


          {
            (this.state.chosenTab==="Colouring") && 
            <div style={{display:"flex",flexDirection:"column",justifyContent:"left"}}>
              <p>Colouring is a beginner category for pre-school artists. Simply:</p>
              <p>1. Download the template from our website.</p>
              <p>2. Colour the drawing to your liking.</p>
              <p>3. Scan and send the finished artwork to our email.</p>
            </div>
          }

          {
            (this.state.chosenTab==="Theme") && 
            <div style={{display:"flex",flexDirection:"column",justifyContent:"left"}}>
              <p>{"主题组要求参赛者以表达对和平的看法或概念创作一幅绘画/图画。"}</p>
              {/* <p>1. Select the Theme and Group you wish to enter in.</p> */}
              <p>{"1. 发挥你的想象力，创作代表主题的2D艺术作品。"}</p>
              <p>{"2. 扫描完成的作品并将其发送到我们的电子邮件。"}</p>
            </div>
          }

          {
            (this.state.chosenTab==="Painting") && 
            <div style={{display:"flex",flexDirection:"column",justifyContent:"left"}}>
              <p>{"自由主题组允许参赛者创作他们想要的任何主题的艺术作品。"}</p>
              {/* <p>1. Select the Theme and Group you wish to enter in.</p> */}
              <p>{"1. 创建您喜欢的 2D 图稿。"}</p>
              <p>{"2. 扫描完成的作品并将其发送到我们的电子邮件。"}</p>
            </div>
          }
            
          { (this.state.chosenTab!=="Colouring") &&
            Object.keys(this.state.group).map((item,key)=>{
              
                return <div key={key} style={{margin:"15px 5px",border:"1px solid white",display:"flex",flexDirection:"column",alignItems:"center"}}>
                    <div style={{width:"100%",justifyContent:"left",display:"flex",paddingLeft:"3%",fontSize:"1.5rem",border:"1px solid white",alignItems:"baseline"}}>
                      {(this.props.lang==="cn")?this.state.group[item][999]["cn-name"]:item} &nbsp;<div style={{fontSize:"0.8rem",verticalAlign:"baseline"}}>{"(2023 主题组别)"}</div> 
                    </div>
                    <div style={{display:"flex",flexDirection:"row",justifyContent:"space-around",width:"100%",borderBottom:"1px solid rgba(255,255,255,0.5)"}}>
                      <div className={["iyfao-cat-cell"].join(" ")}>组别号码</div>
                      <div className={["iyfao-cat-cell"].join(" ")}>组别</div>
                      <div className={["iyfao-cat-cell"].join(" ")}>年龄</div>
                      <div className={["iyfao-cat-cell"].join(" ")}>{"价格（美元"}</div>
                    </div>
                      <div style={{display:"flex",flexDirection:"row",justifyContent:"space-around",width:"100%",borderBottom:"1px solid rgba(255,255,255,0.2)"}}>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[item][1]["number"]}</div>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[item][1]["name-cn"]}</div>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[item][1]["des-cn"]}</div>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[item][1]["price"]}</div>
                      </div>
                      <div style={{display:"flex",flexDirection:"row",justifyContent:"space-around",width:"100%",borderBottom:"1px solid rgba(255,255,255,0.2)"}}>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[item][2]["number"]}</div>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[item][2]["name-cn"]}</div>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[item][2]["des-cn"]}</div>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[item][2]["price"]}</div>
                      </div>
                      <div style={{display:"flex",flexDirection:"row",justifyContent:"space-evenly",width:"100%",borderBottom:"1px solid rgba(255,255,255,0.2)"}}>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[item][3]["number"]}</div>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[item][3]["name-cn"]}</div>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[item][3]["des-cn"]}</div>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[item][3]["price"]}</div>
                      </div>
                      <div style={{display:"flex",flexDirection:"row",justifyContent:"space-evenly",width:"100%",borderBottom:"1px solid rgba(255,255,255,0.2)"}}>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[item][4]["number"]}</div>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[item][4]["name-cn"]}</div>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[item][4]["des-cn"]}</div>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[item][4]["price"]}</div>
                      </div>
                      <div style={{display:"flex",flexDirection:"row",justifyContent:"space-evenly",width:"100%",borderBottom:"1px solid rgba(255,255,255,0.2)"}}>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[item][5]["number"]}</div>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[item][5]["name-cn"]}</div>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[item][5]["des-cn"]}</div>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[item][5]["price"]}</div>
                      </div>
                      <div style={{display:"flex",flexDirection:"row",justifyContent:"space-evenly",width:"100%",borderBottom:"1px solid rgba(255,255,255,0.2)"}}>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[item][6]["number"]}</div>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[item][6]["name-cn"]}</div>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[item][6]["des-cn"]}</div>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[item][6]["price"]}</div>
                      </div>

                   
                  </div>
              
            })
          }


{ (this.state.chosenTab==="Colouring") &&
            Object.keys(this.state.group).map((item,key)=>{
              
                return <div key={key} style={{margin:"15px 5px",border:"1px solid white",display:"flex",flexDirection:"column",alignItems:"center"}}>
                    <div style={{width:"100%",justifyContent:"left",display:"flex",paddingLeft:"3%",fontSize:"1.5rem",border:"1px solid white"}}>
                      {item}
                    </div>
                    <div style={{display:"flex",flexDirection:"row",justifyContent:"space-around",width:"100%",borderBottom:"1px solid rgba(255,255,255,0.5)"}}>
                      <div className={["iyfao-cat-cell"].join(" ")}>Group Number</div>
                      <div className={["iyfao-cat-cell"].join(" ")}>Group Name</div>
                      <div className={["iyfao-cat-cell"].join(" ")}>Description</div>
                      <div className={["iyfao-cat-cell"].join(" ")}>{"Price (USD)"}</div>
                    </div>
                      <div style={{display:"flex",flexDirection:"row",justifyContent:"space-around",width:"100%",borderBottom:"1px solid rgba(255,255,255,0.2)"}}>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[item][1]["number"]}</div>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[item][1]["name"]}</div>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[item][1]["des"]}</div>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[item][1]["price"]}</div>
                      </div>
                      <div style={{display:"flex",flexDirection:"row",justifyContent:"space-around",width:"100%",borderBottom:"1px solid rgba(255,255,255,0.2)"}}>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[item][2]["number"]}</div>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[item][2]["name"]}</div>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[item][2]["des"]}</div>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[item][2]["price"]}</div>
                      </div>
                      <div style={{display:"flex",flexDirection:"row",justifyContent:"space-evenly",width:"100%",borderBottom:"1px solid rgba(255,255,255,0.2)"}}>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[item][3]["number"]}</div>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[item][3]["name"]}</div>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[item][3]["des"]}</div>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[item][3]["price"]}</div>
                      </div>
                   
                  </div>
              
            })
          }

         {( this.state.chosenTab==="Colouring") && 
         <div style={{position:"relative",zIndex:"2",fontSize:"1.3rem",border:"1px solid white",paddingBottom:"20px"}}>
           <div style={{margin:"20px 0px"}}>
              Download Sketch
            </div>
            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-evenly",alignItems:"center"}}>
                <a href="/assets/C-1.pdf" download="IYFAAO_C-1.pdf" style={{color:"white",background:"rgba(255,255,255,0.1)",padding:"10px 10px"}}>
                <div>{"C-1 Debut"}</div>
                </a>
                <a href="/assets/C-2.pdf" download="IYFAAO_C-2.pdf" style={{color:"white",background:"rgba(255,255,255,0.1)",padding:"10px 10px"}}>
                <div>{"C-2 Junior"}</div>
                </a>
                <a href="/assets/C-3.pdf" download="IYFAAO_C-3.pdf" style={{color:"white",background:"rgba(255,255,255,0.1)",padding:"10px 10px"}}>
                <div>{"C-3 Emerging"}</div>
                </a>
            </div>
          </div>
          }
          </Container>

      </div>
    );
  }
  }
  

export default IYFAOCategory;
