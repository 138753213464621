import '../../App.css';
import React, {Component, Container} from 'react';
import {Row,Col,Image,Navbar, Nav, NavItem, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import YoutubeBackground from 'react-youtube-background';
import Card from './card';
import NavBarTop from "./navbar";
import Footer from "./footer";
import About from "./about";

import {Adsense} from '@ctrl/react-adsense';

const content = {
    "en":{
      "home":"Home","market":"Market", "others":"Others", "companyname": "International Classical Music Talent Competition", "companytagline":"Welcome worldwide entry", "extratagline": "Music Category", "companyintro": "ONLINE COMPETITION", "introcontent":" Open to Piano, Violin, Cello, Voice, Harp, Guitar\n and more\n\rDeadline : 8th May 2023",
      },
    "zh":{
      "home":"主頁","market":"市場動態", "others":"其他",  "companyname": "海克斯國際有限公司", "companytagline":"您的財務合作夥伴", "extratagline": "---全新NFT即將發售---", "companyintro": "公司簡介", "introcontent":"海克斯國際有限公司是一家領先的數字化金融科技公司，專注於為用戶提供覆蓋多個市場的全數字化在線券商服務，繼而變革投資體驗。海克斯通過自主研發的一站式數字化金融服務平台，提供面向C端的市場數據、資訊、社交等服務，海克斯提供數字貨幣、現貨黃金、外匯交易、財富管理等服務，並以用户為中心構建完善的金融科技生態系統，連接用戶、投資者、企業、分析師、媒體和意見領袖。同時，從而實現企業業務與零售經紀業務的高度互補。"+"\n\r"+"槓桿式外匯交易、就數字貨幣提供意見、就現貨黃金提供意見、提供自動化交易服務、提供資產管理的資質。"+"\n\r"+"未來，海克斯將繼續秉承體驗至上，用戶第一的理念，不斷推動產品疊代升級，推出更多普惠投資者、企業和行業的產品及服務，成為有顯著影響力的全球金融服務企業。",
    },
    "cn":{
      "home":"主页","market":"市场动态", "others":"其他", "companyname": "国际古典音乐才艺大赛", "companytagline":"欢迎全球参赛", "extratagline": "音乐类別", "companyintro": "线上報名", "introcontent":"設有钢琴、小提琴、大提琴、声乐、竖琴、吉他等組別\n\r截止日期：2023 年 5 月 8 日",
    },
  }

class IcmtCnHome extends Component {
  constructor(props){
    super(props);
    this.state = {
       
    };

    this.setval = this.setval.bind(this)
  }

  setval(obj){
    this.setState(obj)
  }
  componentDidMount(){
    this.props.setval({comp:"icmt-cn"}) 
  }      
      
  render(){
    return (
        <div style={{background:"black"}}>
           <img src="./assets/home-bg.jpg" className={['home-frame'].join(" ")} />
        <div className="vid-wrapper">
        
        <div className="front-layer-white">
          <h1 className="home-comp-title">{content["cn"]["companyname"]}</h1>
          {/* <h6 className="home-comp-subtitle">{content[this.props.lang]["companytagline"]}</h6> */}
          {/* <h3 style={{fontStyle:"italic",color:"rgba(255,255,255,1)",fontSize:"2.5vw"}}>{content[this.state.lang]["extratagline"]}</h3> */}
          <div>&nbsp;</div>

          {/* <h4 style={{color:"rgba(255,255,255,1)",fontSize:"2vw"}}>{content[this.state.lang]["companyintro"]}</h4> */}
          {
          content["cn"]["introcontent"].split('\n').map(
            (paragraph) => {
             return <div className="home-comp-subtitle2">{paragraph}</div>;
            })
        }
          <div className="home-break">&nbsp;</div>
        <a href="/icmt/apply"><button className="home-goldbutton" onClick={(e)=>{
          //window.open(applyurl, '_blank', 'noopener,noreferrer');
          //this.setState({pageSelected:"apply"})
          }}>{"立即報名"}</button></a>
        </div>
        <div style={{maxWidth:"100vw",overflow:"hidden"}}>
            {/* <video loop autoPlay muted className="vid-container">
            <source
              // src="/assets/home_vid.MOV"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video> */}
        </div>
        
      </div>

      {/* <div>
      <Adsense
          client="ca-pub-9547607697889569"
          slot="8476065608"
          style={{ display: 'block' }}
          layout="in-article"
          format="fluid"
        />
      </div> */}

         
       <div className="home-poster">
         <img className="home-poster-img" src="/assets/icmt-poster.png" alt="" height="100%" />
         <Card index={1}
                title={this.props.cardcontent["cn"]["title1"]}
                tagline={""}
                content={this.props.cardcontent["cn"]["content1"]} 
                setval={this.props.setval} {...this.props}/>
       </div>

        </div>
    );
  }
  }
  

export default IcmtCnHome;
