const iyfaaoPrice = {

    "P01":28,
    "P02":28,
    "P03":28,
    "P04":38,
    "P05":38,
    "P06":38,

    "T01":28,
    "T02":28,
    "T03":28,
    "T04":38,
    "T05":38,
    "T06":38,

    "C-1":47,
    "C-2":47,
    "C-3":47,

    "SC-1":55,
    "SC-2":55,
    "SC-3":55,
    "SC-4":65,
    "SC-5":65,
    "SC-6":75,

    "SH-1":55,
    "SH-2":55,
    "SH-3":55,
    "SH-4":65,
    "SH-5":65,
    "SH-6":75,

    "TEST":0.01,
}

export default iyfaaoPrice;