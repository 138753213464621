import '../../App.css';
import React, {Component, Container} from 'react';
import {Image,Navbar, Nav, NavItem, NavDropdown} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import YoutubeBackground from 'react-youtube-background';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import NavBarTop from "./navbar";
import Footer from "./footer";
const applyurl = "https://docs.google.com/forms/d/e/1FAIpQLScoSIrXM_CqsXcaw6mSZmB8f6E1MoXcis3QFeRaE_SO6cP_5A/viewform?usp=sf_link"

class IcmtCnTerms extends Component {
  constructor(props){
    super(props);
    this.state = {  
       
    };

    this.setval = this.setval.bind(this)
  }
  componentDidMount(){
    this.props.setval({comp:"icmt-cn"}) 
  }
  setval(obj){
    this.setState(obj)
  }

  render(){
    return (
      <div>
       {/* <img src="/assets/term.png" width="100%" style={{borderRadius:"0px",position:'absolute',top:"0",bottom:"0",left:"0",right:"0",opacity:"0.3",height:"100vh"}} /> */}
      <div style={{display:"flex",flexDirection:"column",paddingLeft:"15%",paddingRight:"15%",paddingTop:"4%",paddingBottom:"2%",textAlign:"left",fontFamily:"Courgette",color:"black",backgroundImage:"url('/assets/term.png')",
      filter:"brightness(100%)",opacity:"0.7",backgroundBlendMode:"luminosity",backgroundRepeat:"no-repeat",backgroundSize:"100vw 100vh",maxHeight:"100vh",overflow:"scroll"}}>
      <h4 style={{fontSize:"1.8em",fontWeight:"bold"}}><u><i>{"比赛规則"}</i></u></h4>
      <ul style={{textAlign:"justify",fontWeight:"bold"}}>
        <li><p style={{fontSize:"1.4em"}}>{"比赛欢迎全球申请者。 对申请人的来源没有限制。"} </p></li>
        <li><p style={{fontSize:"1.4em"}}>{"所有评审团成员不得评判自己学生的作品或作为参赛者参加比赛。"}</p></li>
      <li><p style={{fontSize:"1.4em"}}>{"参赛者不得联系任何陪审团成员。"}</p></li>
      <li><p style={{fontSize:"1.4em"}}>{"评审团成员的结果和评论是无可争议的和最终的。"}</p></li>
      <li><p style={{fontSize:"1.4em"}}>{"违反比赛规章制度的参赛者或将会被取消资格。"} </p></li>
      <li><p style={{fontSize:"1.4em"}}>{"参赛者可以根据记忆或乐谱进行表演。"}</p></li>
      <li><p style={{fontSize:"1.4em"}}>{"一名参赛者可以申请多个比赛组别。"}</p></li>
      <li><p style={{fontSize:"1.4em"}}>{"参赛者可以向同一组提交多件作品，惟不可以以同一作品多次报名，否则将被视作违规。"}</p></li>
      </ul>
      
     
    
    <br></br>

    <h4 style={{fontSize:"1.8em",fontWeight:"bold"}}><u><i>{"条款"}</i></u></h4>
    <ul style={{textAlign:"justify",fontWeight:"bold"}}>
    <li><p style={{fontSize:"1.4em"}}>{"所有申请费用概不退还。"}</p></li>
    <li><p style={{fontSize:"1.4em"}}>{"主办方有权修改比赛规则、奖品内容和评审团组成。"}</p></li>
    <li><p style={{fontSize:"1.4em"}}>{"主办方拥有最终解释权。"}</p></li>
    <li><p style={{fontSize:"1.4em"}}>{"本页面未刊登的其他规则和规定仅供参考，主办方拥有解释权。"}</p></li>
    <li><p style={{fontSize:"1.4em"}}>{"参赛者的所有信息将予以保密，仅用于与本次大赛相关的通知及宣传事宜。"}</p></li>
    <li><p style={{fontSize:"1.4em"}}>{"对比赛申请或提交信息的任何部分进行的任何更改都可能需要支付最高 15 美元或等值的行政费。"} </p></li>
    </ul>
       <br></br>

{/* 
        <h2>2022 International Young Artist Open Competition</h2>
        <h4>Deadline: 15th November 2022</h4>
        <h4>Results Date: 19th December 2022</h4>
        <br/>      

      <br/><br/>
    <h6>After successful payment, we will send a confirmation email to you.</h6>
    <p style={{fontSize:"0.4em"}}>*All application fees are non-refundable.</p> */}
      </div>
      </div>
    );
  }
  }
  

export default IcmtCnTerms;
