import './App.css';
import React, {Component, Container} from 'react';
import {Image,Navbar, Nav, NavItem, NavDropdown, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import NavBarTop from "./component/iyao/navbar";
import Home from "./component/iyao/home";
import Footer from "./component/iyao/footer";
import Apply from "./component/iyao/apply";
import About from "./component/iyao/about";
import Awards from './component/iyao/awards';
import Category from "./component/iyao/category";
import Terms from './component/iyao/terms';
import Jury from  './component/iyao/jury';
import Card from "./component/iyao/card";
import Contact from './component/iyao/contact';
import Winners from './component/iyao/winners';
import TeacherList from './component/iyao/teacherlist';
import TeacherProfile from './component/iyao/teacherprofile';

import IcmtCnNavBarTop from "./component/icmt-cn/navbar";
import IcmtCnHome from "./component/icmt-cn/home";
import IcmtCnApply from "./component/icmt-cn/apply";
import IcmtCnAbout from "./component/icmt-cn/about";
import IcmtCnAwards from './component/icmt-cn/awards';
import IcmtCnCategory from "./component/icmt-cn/category";
import IcmtCnTerms from './component/icmt-cn/terms';
import IcmtCnJury from  './component/icmt-cn/jury';
import IcmtCnCard from "./component/icmt-cn/card";
import IcmtCnContact from './component/icmt-cn/contact';
import IcmtCnFooter from './component/icmt-cn/footer';

import IypcNavBarTop from "./component/iypc/navbar";
import IypcHome from "./component/iypc/home";
import IypcApply from "./component/iypc/apply";
import IypcAbout from "./component/iypc/about";
import IypcAwards from './component/iypc/awards';
import IypcCategory from "./component/iypc/category";
import IypcTerms from './component/iypc/terms';
import IypcJury from  './component/iypc/jury';
import IypcCard from "./component/iypc/card";
import IypcContact from './component/iypc/contact';
import IypcPastComp from './component/iypc/pastcomp';
import IypcFooter from './component/iypc/footer';

import IypcCnNavBarTop from "./component/iypc-cn/navbar";
import IypcCnHome from "./component/iypc-cn/home";
import IypcCnApply from "./component/iypc-cn/apply";
import IypcCnAbout from "./component/iypc-cn/about";
import IypcCnAwards from './component/iypc-cn/awards';
import IypcCnCategory from "./component/iypc-cn/category";
import IypcCnTerms from './component/iypc-cn/terms';
import IypcCnJury from  './component/iypc-cn/jury';
import IypcCnCard from "./component/iypc-cn/card";
import IypcCnContact from './component/iypc-cn/contact';
import IypcCnPastComp from './component/iypc-cn/pastcomp';
import IypcCnFooter from './component/iypc-cn/footer';


import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import IndxePage from './component/indexpage';
import IcmtCnWinners from './component/icmt-cn/winners';
import Magazine from './component/iyao/magazine';
import IYPOMagazine from './component/iypc/magazine';
const applyurl = "https://docs.google.com/forms/d/1JQvox_w4JTU0rvRcnRxPnfGxWmQBuq5V0LoMmVBY9Gs/"



const content = {
  "en":{
    "home":"Home","market":"Market", "others":"Others", "companyname": "International Young Artist Open Competition", "companytagline":"Hong Kong", "extratagline": "Music Category", "companyintro": "ONLINE COMPETITION", "introcontent":" Open to Piano , Strings , Brass , Woodwind , Voice\nDeadline : 15th November 2022",
    },
  "zh":{
    "home":"主頁","market":"市場動態", "others":"其他",  "companyname": "海克斯國際有限公司", "companytagline":"您的財務合作夥伴", "extratagline": "---全新NFT即將發售---", "companyintro": "公司簡介", "introcontent":"海克斯國際有限公司是一家領先的數字化金融科技公司，專注於為用戶提供覆蓋多個市場的全數字化在線券商服務，繼而變革投資體驗。海克斯通過自主研發的一站式數字化金融服務平台，提供面向C端的市場數據、資訊、社交等服務，海克斯提供數字貨幣、現貨黃金、外匯交易、財富管理等服務，並以用户為中心構建完善的金融科技生態系統，連接用戶、投資者、企業、分析師、媒體和意見領袖。同時，從而實現企業業務與零售經紀業務的高度互補。"+"\n\r"+"槓桿式外匯交易、就數字貨幣提供意見、就現貨黃金提供意見、提供自動化交易服務、提供資產管理的資質。"+"\n\r"+"未來，海克斯將繼續秉承體驗至上，用戶第一的理念，不斷推動產品疊代升級，推出更多普惠投資者、企業和行業的產品及服務，成為有顯著影響力的全球金融服務企業。",
  },
  "cn":{
    "home":"主页","market":"市场动态", "others":"其他", "companyname": "海克斯国际有限公司", "companytagline":"您的财务合作伙伴.", "extratagline": "---全新NFT即将发售---", "companyintro": "公司简介", "introcontent":"海克斯国际有限公司是一家领先的数字化金融科技公司，专注于为用户提供覆盖多个市场的全数字化在线券商服务，继而变革投资体验。海克斯通过自主研发的一站式数字化金融服务平台，提供面向C端的市场数据、资讯、社交等服务，海克斯提供数字货币、现货黄金、外汇交易、财富管理等服务，并以用户为中心构建完善的金融科技生态系统，连接用户、投资者、企业、分析师、媒体和意见领袖。同时，从而实现企业业务与零售经纪业务的高度互补。 "+"\n\r"+"杠杆式外汇交易、就数字货币提供意见、就现货黄金提供意见、提供自动化交易服务、提供资产管理的资质。 "+"\n\r"+"未来，海克斯将继续秉承体验至上，用户第一的理念，不断推动产品叠代升级，推出更多普惠投资者、企业和行业的产品及服务，成为有显著影响力的全球金融服务企业。",
  },
}
class App extends Component {
  constructor(props){
    super(props);
    this.state = {  
        lang:"en",
        language:{"en":"ENG", "zh":"繁","cn":"简"},
        companyname:{
          "en":"Hicus",
          "zh":"海克斯",
          "cn":"海克斯"
        },
        pageSelected:"home",
        chosencurrency:"USD",
        pagepos:"",
        cardcontent: {
          "en": { "title1":"About the Competition",
                  "tagline1":"Our Vision",
                  "content1":"The International Classical Music Talent Competition adopts a new approach in classical music competition. We offer composer groups to contestants specializing the needs of instrumentalists. This year we also offer the Hong Kong School Music Festival Groups, and World Music Group to let musicians celebrate occasions of seasonal events.\n In addition, we also welcome contestants as Harpists and Classical Guitarists to make use of the opportunity and join us.\n This year's winner will win, in addition to awards, a reference letter and be invited to have their soundtracks produced as CD recordings.\n A warm welcome to all talented musicians, and we hope to see you in the competition.\n The competition is organised by the Yréte Ténue International Culture Fellowship.",
                  "title2":"Industry",
                  "tagline2":"Redefining Finance",
                  "content2":"View this year's categories and groups available.",
                  "title3":"Society",
                  "tagline3":"Mission to Society",
                  "content3":"Ready to become a contestant?",
                  "content4":"The International Young Painter Open Competition aims at nurturing talented youngsters through unleashing their artistic potentials. We offer competition groups by age, and this year we offer the special group with the themed 'Peace'. Given the current challenges in our world today, it is and will become an even more important aspect of an individual's life to have peace.\n The contestants' submissions will be assessed by professionl jury members, and we offer various awards this year for our contestants. \n This year's Champion, Second Prize and Third Prize winners will be invited complimentarily to the 2024 International Young Fine Art Artist Open Competition.\n A warm welcome to all talented painters, and we hope to see you in the competition."
                },
          "zh": { "title1":"願景",
                      "tagline1":"海克斯的願景",
                      "content1":"海克斯國際作為香港金融界的一份子，我們秉承「誠信、穩健、創新」的服務宗旨，當中「誠信」是我們最重視的。",
                      "title2":"業界",
                      "tagline2":"對金融業界的使命",
                      "content2":"海克斯國際的使命就是增強投資客戶對海克斯國際及業界的信心，同時希望自己能成為同業的好榜樣，為香港以及海外的金融業出一分力！",
                      "title3":"社會",
                      "tagline3":"對社會的使命",
                      "content3":"作為社會的一份子，本着「取諸社會，用諸社會」的原則，我們有責任。支援香港非政府組織的慈善工作，回饋社會。"
                    },
          "cn": { "title1":"关于我们",
                      "tagline1":"海克斯的愿景",
                      "content1":"国际古典音乐才艺大赛以一种湛新的古典音乐比赛方式為主導。 我们为专门乐器演奏家参赛者提供為其樂器作曲的作曲家组別。 今年我们还提供香港学校際音乐节組別和世界音乐組別，让音乐家庆祝本年度的季节性音樂項目。\n此外，我们也欢迎竖琴演奏家和古典吉他演奏家等选手把握机会加入成為我们的比參賽者。\n今年的获胜者除了可以获得奖项外，还能夠获得推荐信一封，并會受邀将其參賽聲音檔制作成唱片。\n我們热烈欢迎所有才华横溢的音乐家参賽，希望能夠在比赛中见到各位精彩的演出。",
                      "title2":"业界",
                      "tagline2":"对金融业界的使命",
                      "content2":"查看今年的比赛组别。",
                      "title3":"社会",
                      "tagline3":"Mission to Society",
                      "content3":"准备好成为参赛者了吗？",
                      "content4":"国际青年画家公开赛旨在培养有才华的青少年，释放他们的艺术潜力。我们按年龄设置比赛组别，今年我们设置了以“和平”为主题的特殊组。鉴于我们当前面临的挑战， 当今世界，和平已经并将成为个人生活中更加重要的一个方面。\n 参赛者提交的作品将由专业评审团成员进行评估，今年我们为参赛者提供了各种奖项。\n 今年的冠军、二等奖和三等奖获得者将被免费邀请参加 2024年国际青年美术家公开赛。\n 我们以热烈的气氛 欢迎所有才华横溢的画家，我们希望在比赛中见到你们。"
                    },
        },
        iyfaocardcontent: {
          "en": { "title1":"About the Competition",
                  "tagline1":"Our Vision",
                  "content1":"The International Young Painter Open Competition serves as one of the leading platforms for young artists to realize their endeavours, and to learn and communicate with talented artists around the region and the world. We are a prominent advocate of Global Peace via artistic visions. \nThe competition is part of the competition series covering Music, Art and Speech that provides a comfortable environment and experience for participants across the globe.\nThe competition's professional jury from Europe and US offer advice for participants who would otherwise difficult to reach out to. Participants in Asia with technical restrictions can benefit from expert comments and artistic guidance from our jury. It is a competition for young artists of all levels of proficiency to enjoy, and a great opportunity for future artists to gain much formal experience, techniques and style assessment, and of course, the joy of performing.\nThis year's Champion, Second Prize and Third Prize winners will be invited complimentarily to the coming International Young Fine Art Artist Open Competition. A warm welcome to all talented painters, and we hope to see you in the competition.\n The competition is organised by the Yréte Ténue International Culture Fellowship.",
                  "title2":"Industry",
                  "tagline2":"Redefining Finance",
                  "content2":"The mission of Hicus International is to enhance the confidence of investment clients in Hicus International and the industry, and at the same time hope that it can become a good example for the industry and contribute to the financial industry in Hong Kong and overseas!",
                  "title3":"Society",
                  "tagline3":"Mission to Society",
                  "content3":"As a part of society, we have a responsibility based on the principle of reciprocating the society. We thus support the charitable work of Hong Kong NGOs and give back to the community."
                },
          "zh": { "title1":"願景",
                      "tagline1":"海克斯的願景",
                      "content1":"海克斯國際作為香港金融界的一份子，我們秉承「誠信、穩健、創新」的服務宗旨，當中「誠信」是我們最重視的。",
                      "title2":"業界",
                      "tagline2":"對金融業界的使命",
                      "content2":"海克斯國際的使命就是增強投資客戶對海克斯國際及業界的信心，同時希望自己能成為同業的好榜樣，為香港以及海外的金融業出一分力！",
                      "title3":"社會",
                      "tagline3":"對社會的使命",
                      "content3":"作為社會的一份子，本着「取諸社會，用諸社會」的原則，我們有責任。支援香港非政府組織的慈善工作，回饋社會。"
                    },
          "cn": { "title1":"愿景",
                      "tagline1":"海克斯的愿景",
                      "content1":"海克斯国际作为香港金融界的一份子，我们秉承「诚信、稳健、创新」的服务宗旨，当中「诚信」是我们最重视的。",
                      "title2":"业界",
                      "tagline2":"对金融业界的使命",
                      "content2":"海克斯国际的使命就是增强投资客户对海克斯国际及业界的信心，同时希望自己能成为同业的好榜样，为香港以及海外的金融业出一分力！",
                      "title3":"社会",
                      "tagline3":"对社会的使命",
                      "content3":"作为社会的一份子，本着「取诸社会，用诸社会」的原则，我们有责任。支援香港非政府组织的慈善工作，回馈社会。"
                    },
        },
        teacherlist:{
          "hk":{
            "Cheuk Yee Wong":{
              "name":"Cheuk Yee Wong",
              "profile":"  Ms Wong (MM. in Voice Pedagogy & Performance, BA in Music) is both an academic and private studio voice teacher. She teaches Pop Vocal Techniques at Hong Kong Baptist University while she runs Cherir Voice Studio. Currently, she is the president of the Hong Kong Chapter of National Association of Teachers of Singing. Ms Wong also serves the Hong Kong Inter-school Choral Festival as Artistic Advisor. Originally classically trained, Ms Wong ventured out into musical theatre and pop singing training during the pandemic. She now possesses ARSM in Musical Theatre and Grade 8 Distinction from Rockschool.",
              "picture":"",
            },
          
          },
          "ml":{
            "Chan Nguk Ling":{
              "name":"Chan Nguk Ling",
              "profile":"  a music teacher born in a city Sandakan of Sabah, Malaysia, is a delicate music educator with a passion for nurturing talent and inspiring creativity in her students. She holds a BA (Hons) in Music from the UK and is passionate about fostering students' creativity and technical skills. Known for my innovative teaching methods and unwavering support and also commitment to fostering a love for music has earned me recognition in the community. Chang tailors lessons to each student’s individual goals, whether for exams, performances, or personal growth. She believes in inspiring a lifelong love of music and helping students achieve their fullest potential.",
              "picture":"",
            },
            "Esther Sitorus":{
              "name":"Esther Sitorus",
              "profile":"Esther Sitorus has been a teacher for more than 20 years. She is a teacher in the Yamaha Music School.",
              "picture":"",
            "Joyce Deborah Thungriallu":{
              "name":"Joyce Deborah Thungriallu",
              "profile":"Joyce Deborah Thungriallu, BCM Graduated from Singapore Bible College Music Dept (Vocal Major) in 1998. Soloist in Arion & the Dolphin children opera (Singapore, 1995), Singapore Symphony Orchestra Fund Raising evet at Chijmes (Singapore, 1995), various classical concert in Jakarta, Surabaya, Kuala Lumpur (1997 - 2019). Teaching classical singing foundation since 1998 - now in Surabaya, East Java, Indonesia.",
              "picture":"",
            },
         
            },
          
            
          },
          "in":{
          
            "Akhsanah Istiati": {
              "name":"Akhsanah Istiati",
              "profile":"Akhsanah Istiati has been a piano teacher since 1994 based in Indonesia.",
              "picture":"",
            },
            "Angelia Soegito": {
              "name":"Angelia Soegito",
              "profile":"Angelia Soegito has a rich background in piano and violin, holding a distinction in the LRSM for piano performance. With a Bachelor of Architecture, she balances her passion for music and education, performing with various orchestras under conductors such as Andrew Massey, Kanako Abe, Avip Priatna, and Addie MS. As a piano accompanist, she has collaborated with renowned musicians including Asep Hidayat, Gia Jashvili, Foo Say Ming, and Zhao Tian. Recently, Angelia participated in the Georgetown String Festival in Penang and performed with STRIVNGS in Singapore, and she is currently an active member of the String Orchestra of Surabaya.",
              "picture":"assets/icmtteacher2024/Angelia Soegito",
            },
            "Jelia Megawati Heru": {
              "name":"Jelia Megawati Heru",
              "profile":"Jelia Megawati Heru received master degree in music education (Instrumental Pädagogik) at Fachhochschule Osnabrück Konservatorium, Institut für Musikpädagogik – Germany, majoring in Classical Piano. She was keynote speaker for RSL Awards Indonesia for Classical Piano, Contemporary Piano and Keys. Jelia’s Piano Studio was the best piano studio of the year in 2020 – 2023 and won 51 Rockstar Awards. Jelia received Teacher Award from Chopin Avenue Piano Competition in 2022-2024; Excellent Music Teaching from Wien International Young Musician Music Competition (W-IYYMC) in 2024. Jelia was a member of the National Music Ministry of National Education Consortium in 2008, She wrote three books “Piano Teaching’s Guide”, “Pianolicious”, and “Basic Music Theory for all Instruments”. She is writer for STACCATO Magazine and <a>www.jeliaedu.blogspot.com</a>.",
              "picture":"",
            },
            

          },
          "th":{
            "Tipok Phinitsakul": {
              "name":"Tipok Phinitsakul",
              "profile":"Tipok Phinitsakul, a Yamaha artist and Thailand’s most distinguished flute teacher, graduated with first-class honors in flute performance from Mahidol University and earned an Artist Diploma from Penn State University. Currently pursuing a Doctorate at Chulalongkorn University, he is highly respected for his exceptional teaching. As Co-Director of the biennial Thailand Flute Festival, Tipok plays a vital role in nurturing young talent. At Shrewsbury International School Bangkok, his students consistently achieve the highest ABRSM scores and win major national and international competitions, solidifying his reputation as one of the country’s most influential flute educators.",
              "picture":"assets/icmtteacher2024/Angelia Soegito",
            },
             }
        },
        selectedTeacher:null,
        comp:null,
    };

    this.setval = this.setval.bind(this)
  }
  componentDidMount(){
    // fetch("https://dataapi.2rich.net/QueryDataHistory/QueryDataJsonByCountJson.aspx?userCode=FX168&bCode=FEAGUS&succ_callback=jQuery112304707026314812346_1651155474723&_=1651155474738")
    // .then((response)=>{console.log(response.json())})
  }

  setval(obj){
    this.setState(obj)
  }

  render(){
    return (
    //  { <PayPalScriptProvider options={{'client-id':"AYjS8JJEeQjpxiAmTyDqOJANSOJgxgmjuf4IPFRIqmqsu0oZlrGZ-vz0NB5p86pfNcf55-zEWpznAy4O",
    //                                    //dcurrency:this.state.chosencurrency,
    //                                    }}>}
      <div className="App">
        
          
        <div className={["nav-container"].join(" ")}>
            {
              (this.state.comp==="icmt") && <NavBarTop {...this.state} setval={this.setval} />
            }
             {
              (this.state.comp==="icmt-cn") && <IcmtCnNavBarTop {...this.state} setval={this.setval} />
            }
            {
              (this.state.comp==="iypo") && <IypcNavBarTop {...this.state} setval={this.setval} />
            }
             {
              (this.state.comp==="iypo-cn") && <IypcCnNavBarTop {...this.state} setval={this.setval} />
            }
        </div>

          <BrowserRouter>
            <Routes>

                <Route index element={<IndxePage {...this.state} setval={this.setval}/>} />
                {/* <Route path="iyao" element={<Home {...this.state} setval={this.setval}/>}> */}
                  <Route path="/icmt" element={<Home {...this.state} setval={this.setval}/>} />
                  <Route path="/icmt/about" element={<About {...this.state} setval={this.setval}/>} />
                  <Route path="/icmt/apply" element={<Apply {...this.state} setval={this.setval} />} />
                  <Route path="/icmt/category" element={<Category {...this.state} setval={this.setval}/>} />
                  <Route path="/icmt/awards" element={<Awards {...this.state} setval={this.setval} />} />
                  <Route path="/icmt/jury" element={<Jury {...this.state} setval={this.setval}/> } />
                  <Route path="/icmt/terms" element={<Terms {...this.state} setval={this.setval} />}  />
                  <Route path="/icmt/contact" element={<Contact {...this.state} setval={this.setval}/>}  />
                  <Route path="/icmt/winners" element={<Winners {...this.state} setval={this.setval}/>}  />
                  <Route path="/icmt/magazine" element={<Magazine {...this.state} setval={this.setval}/>}  />
                  <Route path="/icmt/teacherlist" element={<TeacherList {...this.state} setval={this.setval}/>}  />
                  {/* <Route path="/icmt/teacherlist/profile" element={<TeacherProfile {...this.state} setval={this.setval}/>}  /> */}
                {/* </Route> */}

                {/* <Route path="iyfao" element={<IYFAOHome {...this.state} setval={this.setval}/>}> */}
                  <Route path="/icmt-cn" element={<IcmtCnHome {...this.state} setval={this.setval}/>} />
                  <Route path="/icmt-cn/about" element={<IcmtCnAbout {...this.state} setval={this.setval}/>} />
                  <Route path="/icmt-cn/apply" element={<IcmtCnApply {...this.state} setval={this.setval} />} />
                  <Route path="/icmt-cn/category" element={<IcmtCnCategory {...this.state} setval={this.setval}/>} />
                  <Route path="/icmt-cn/awards" element={<IcmtCnAwards {...this.state} setval={this.setval} />} />
                  <Route path="/icmt-cn/jury" element={<IcmtCnJury {...this.state} setval={this.setval}/> } />
                  <Route path="/icmt-cn/terms" element={<IcmtCnTerms {...this.state} setval={this.setval}/>}  />
                  <Route path="/icmt-cn/contact" element={<IcmtCnContact {...this.state} setval={this.setval}/>}  />
                  <Route path="/icmt-cn/winners" element={<IcmtCnWinners {...this.state} setval={this.setval}/>}  />
                {/* </Route> */}

                  <Route path="/iypo" element={<IypcHome {...this.state} setval={this.setval}/>} />
                  <Route path="/iypo/about" element={<IypcAbout {...this.state} setval={this.setval}/>} />
                  <Route path="/iypo/apply" element={<IypcApply {...this.state} setval={this.setval} />} />
                  <Route path="/iypo/category" element={<IypcCategory {...this.state} setval={this.setval}/>} />
                  <Route path="/iypo/awards" element={<IypcAwards {...this.state} setval={this.setval} />} />
                  <Route path="/iypo/jury" element={<IypcJury {...this.state} setval={this.setval}/> } />
                  <Route path="/iypo/terms" element={<IypcTerms {...this.state} setval={this.setval}/>}  />
                  <Route path="/iypo/contact" element={<IypcContact {...this.state} setval={this.setval}/>}  />
                  <Route path="/iypo/links" element={<IypcPastComp {...this.state} setval={this.setval}/>}  />
                  <Route path="/iypo/magazine" element={<IYPOMagazine {...this.state} setval={this.setval}/>}  />

                  <Route path="/iypo-cn" element={<IypcCnHome {...this.state} setval={this.setval}/>} />
                  <Route path="/iypo-cn/about" element={<IypcCnAbout {...this.state} setval={this.setval}/>} />
                  <Route path="/iypo-cn/apply" element={<IypcCnApply {...this.state} setval={this.setval} />} />
                  <Route path="/iypo-cn/category" element={<IypcCnCategory {...this.state} setval={this.setval}/>} />
                  <Route path="/iypo-cn/awards" element={<IypcCnAwards {...this.state} setval={this.setval} />} />
                  <Route path="/iypo-cn/jury" element={<IypcCnJury {...this.state} setval={this.setval}/> } />
                  <Route path="/iypo-cn/terms" element={<IypcCnTerms {...this.state} setval={this.setval}/>}  />
                  <Route path="/iypo-cn/contact" element={<IypcCnContact {...this.state} setval={this.setval}/>}  />
                  <Route path="/iypo-cn/links" element={<IypcCnPastComp {...this.state} setval={this.setval}/>}  />

                
            </Routes>
          </BrowserRouter>
      
      {
        (this.state.comp==="icmt") && <Footer {...this.state} setval={this.setval}/>
      }

      {
        (this.state.comp==="icmt-cn") && <IcmtCnFooter {...this.state} setval={this.setval}/>
      }

      {
        (this.state.comp==="iypo") && <IypcFooter {...this.state} setval={this.setval}/>
      }

{
        (this.state.comp==="iypo-cn") && <IypcCnFooter {...this.state} setval={this.setval}/>
      }
      
      {/* {
        (this.state.comp==="iyfao") && <IYFAOFooter {...this.state} setval={this.setval}/>
      } */}
        

      </div>
      // {</PayPalScriptProvider>}
    );
  }
  }
  

export default App;
