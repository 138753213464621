import '../../App.css';
import React, {Component, Container} from 'react';
import {Image,Navbar, Nav, NavItem, NavDropdown} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import YoutubeBackground from 'react-youtube-background';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import NavBarTop from "./navbar";
import Footer from "./footer";
const applyurl = "https://docs.google.com/forms/d/e/1FAIpQLScoSIrXM_CqsXcaw6mSZmB8f6E1MoXcis3QFeRaE_SO6cP_5A/viewform?usp=sf_link"

class IYFAOJury extends Component {
  constructor(props){
    super(props);
    this.state = {  
       
    };

    this.setval = this.setval.bind(this)
  }
  componentDidMount(){
    document.title="International Young Painter Open Competition"
    var link1 = document.getElementById('favicon')
    var link2 = document.getElementById('touchicon')
    link1.href="/assets/iypo-logo.PNG"
    link2.href="/assets/iypo-logo.PNG"
    this.props.setval({comp:"iypo",lang:"en"})  
  }
  setval(obj){
    this.setState(obj)
  }

  render(){
    return (
      <div>
       {/* <img src="/assets/term.png" width="100%" style={{borderRadius:"0px"}} /> */}
      <div style={{display:"flex",flexDirection:"column",paddingLeft:"15%",paddingRight:"15%",paddingTop:"3%",paddingBottom:"2%",textAlign:"left"}}>
      <h4 style={{fontSize:"1.8em",textAlign:"center",marginBottom:"100px"}}>Principal Jury</h4>

      <div style={{display:'block', marginBottom:"100px"}}>
        <div className="jury-profile">
            <img src="/assets/ida.jpg" alt=""  width="100%"/>
            <h4 style={{marginTop:"5px"}}><b>Ida Domisljanovic</b></h4>
            <h5><em>Artist / Professor</em></h5>
            </div>
        <div style={{position:"absolute",width:"40%",height:"100%"}}>
            <img className="jury-flag" alt="" src="/assets/croatia-flag.jpg"/></div>
        <div className="jury-text">
            <p> 
            Ida Domisljanovic was born in 1982 in Croatia. She graduated from the Academy of Fine Arts in Zagreb in 2012.
            She works as an art teacher in an elementary school. Active in many projects with children. Privately, she is involved in group exhibitions, art colonies, humanitarian exhibitions and preparing independent exhibitions.
            Head of art workshops. Organizer of the exhibition for children.</p>
            <p>
            Several times a member of the commission for the competition/exhibition of primary and secondary schools in the field of visual arts and design LIK. 
            Several times a guest lecturer for examples of good practice at expert meetings and the Creativity in Teaching web classroom. 
            Many national and international recognitions for students at competitions under mentorship. 
            Completed education Art therapy techniques, Teacher with attitude, Children's drawings and creative techniques - how to help a child and calm turbulent emotions.She attended many professional educational seminars. She is currently attending training on recognizing mental health problems in children and young people. 
            She illustrated collection of poetry, children's picture books and scientific and art memory games.
            </p>
        </div>
    </div>

      <div style={{display:'block', marginBottom:"100px"}}>
        <div className="jury-profile">
            <img src="/assets/allen.jpg" alt="" width="100%" />
            <h4 style={{marginTop:"5px"}}><b>Austin Van Allen</b></h4>
            <h5><em>Artist / Senior Art Instructor</em></h5>
            </div>
        <div style={{position:"absolute",width:"40%",height:"100%"}}>
            <img className="jury-flag" alt="" src="/assets/usa-flag.jpg" />
        </div>
        <div className="jury-text">
            <p>Austin Van Allen is an American artist and art teacher. Born and raised in Pennsylvania, he studied education at Penn State University attaining both undergraduate and graduate degrees.  Shortly after graduating, Austin attained his art teaching certification, which drastically changed his career trajectory.
                </p>
                <p>Since starting to create art in this watercolor and ink style during the spring of 2021, Austin’s artwork has been featured in solo exhibitions at the Rhoneymeade gallery in Centre Hall, PA, Otto’s Pub and Brewery in State College, PA, the Bellefonte Museum of Art,  and at Curio Cool Gallery in Zelienople, PA.   His work has been published in the Centre Arts Magazine, Bellefonte Bold Magazine, Artery NYC Magazine, and in a documentary for Power of Positivity . Select artworks have been featured at the Bellefonte Art Museum, the State College Framing Co, and 3 Dots Downtown State College gallery,  as well as internationally at galleries in Milan, Luxembourg City, Madrid, Monaco, and the London-based Virtual Artist’s 2022 International Virtual Art Gallery. 
            </p>
            <p>“My artwork represents casting off control in favor of freedom of expression . During the pandemic of 2020, the need for control was overwhelming. This along with career challenges affected my mental state, and my art.  Instead of focusing on realism, I started throwing paint onto paper.  The ink that is drawn over top of the dried paint is only meant to highlight the beauty of the watercolor as well as to provide depth and texture;  it is reactionary and subservient to the freedom expressed by the watercolor paint.  Instead of painting what I draw, I draw what I paint. When I create my art, all the thoughts and feelings that I keep locked up explode out in bursts of brushstrokes, swirls of color, and masses of lines.  There is a release of emotion and energy that comes from casting off control, as well as an acceptance and appreciation of the imperfect result.  Just like my watercolor paintings, life can offer something unique and beautiful when left to its own devices.”
                 </p>
        </div>
    </div>

    <div style={{display:'block', marginBottom:"100px"}}>
        <div className="jury-profile">
            <img src="/assets/marissa.jpg" alt="" width="100%" />
            <h4 style={{marginTop:"5px"}}><b>Marissa Oosterlee</b></h4>
            <h5><em>Painter </em></h5>
            </div>
        <div style={{position:"absolute",width:"40%",height:"100%"}}>
            <img className="jury-flag" alt="" src="/assets/spain-flag.png" />
        </div>
        <div className="jury-text">
            <p>Marissa Oosterlee is recognised for her hyperrealistic figurative pieces that captivate the viewer with not only their technical detail, 
              but also the narratives that she brings to life. 
              The works show her adept hand and subtle nuances, making it hard to believe that art was not Oosterlee’s first career. 
            </p>
            <p> Marissa has received the 2021 Best Portrait Award IGOR, the 2021 Finalist ARC Renewal Salon, and the 2020 American Art Collector’s choice Award.
              Her works have been in various exhibitions across cities in the USA and the Netherlands since 2016. 
              In addition, she worked with corporations such as Netflix and Nintendo as an illustrator.
            </p>

            <p> She started off, instead, as an aspiring semi-professional cyclist, but a heavy accident at age 20 ended the career early. 
              One year after recovering from the accident and picking up brushes and pencils again, she became a professional illustrator for game covers and movie posters, later a full time artist.
            </p>
                
            <p>Marissa has won numerous awards and prizes in art competitions and gained collectors of her art worldwide. 
            </p>
        </div>
    </div>


    
    {/* <div style={{display:'block', marginBottom:"100px"}}>
        <div className="jury-profile">
            <img src="/assets/alexandre.jpg" alt=""  width="100%"/>
            <h4 style={{marginTop:"5px"}}><b>Mr. Alexandre Teheux</b></h4>
            <h5><em>Pianist and Music Educationist</em></h5>
            </div>
        <div style={{position:"absolute"}}>
            <img className="jury-flag" alt="" src="/assets/belgium-flag.jpg" /></div>
        <div className="jury-text"><p>
            Born in Paris in 1959, Alexandre Teheux studied under Frederic Delvaux, and began his musical studies at Academie Gretry in Liege with Jeanne Bernard-Knapen.
            He then studied in Liege Conservatory with Chantal Bohets and Marcelle Mercenier. He later received a grant from Belgian government which 
            led him to travel and study at Chopin Academy in Warsaw.</p><p>
             With more than 20 years of teaching experience, Alexandre teaches regulary at Academie Gretry, as well as performing in concerts, notably with the violinist Benoit Paquay.
        </p></div>
    </div> */}

      </div>
      </div>
    );
  }
  }
  

export default IYFAOJury;
