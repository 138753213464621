import '../../App.css';
import React, {Component} from 'react';
import {Row, Col, Button,Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import YoutubeBackground from 'react-youtube-background';

import NavBarTop from "./navbar";
import Footer from "./footer";
import groupCat from '../../cat';

class Category extends Component {
  constructor(props){
    super(props);
    this.state = {  
       group0:groupCat["piano"][0],
       group1:groupCat["piano"][1],
       group2:groupCat["piano"][2],
       group3:groupCat["piano"][3],
       group4:groupCat["piano"][4],
       group5:groupCat["piano"][5],
       instrument: "piano",
    };

    this.changeGroup = this.changeGroup.bind(this)
    this.setval = this.setval.bind(this)
  }
  componentDidMount(){
    document.title="International Classical Music Talent Competition"
    var link1 = document.getElementById('favicon')
    var link2 = document.getElementById('touchicon')
    link1.href="/assets/icmt_logo.PNG"
    link2.href="/assets/icmt_logo.PNG"
    this.props.setval({comp:"icmt"})
  }
  setval(obj){
    this.setState(obj)
  }
  changeGroup(val){
   const _group = JSON.parse(JSON.stringify(groupCat[val]))
   //alert(JSON.stringify(_group))
   this.setState({group0:_group[0],group1:_group[1],group2:_group[2],group3:_group[3],group4:_group[4],group5:_group[5],instrument:val})
  }
  render(){
    return (
      <div className="category-div">
        <video loop autoPlay muted id="myVideo">
          <source
            src="../assets/category.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
     
        
        <div id="cat-content"> 
          <button className={[(this.state.instrument=="piano")?"cat-button-selected":"cat-button"].join(" ")} 
            style={{fontWeight:"bold",marginBottom:"7px"}} onClick={(e)=>{this.changeGroup("piano")}}>Piano</button>
          <button className={[(this.state.instrument=="violin")?"cat-button-selected":"cat-button"].join(" ")} 
             style={{fontWeight:"bold",marginBottom:"7px"}} onClick={(e)=>{this.changeGroup("violin")}}>Violin</button>
              <button className={[(this.state.instrument=="cello")?"cat-button-selected":"cat-button"].join(" ")} 
             style={{fontWeight:"bold",marginBottom:"7px"}} onClick={(e)=>{this.changeGroup("cello")}}>Cello</button>
          <button className={[(this.state.instrument=="vocal")?"cat-button-selected":"cat-button"].join(" ")} 
             style={{fontWeight:"bold",marginBottom:"7px"}} onClick={(e)=>{this.changeGroup("vocal")}}>Vocal</button>
          <button className={[(this.state.instrument=="flute")?"cat-button-selected":"cat-button"].join(" ")} 
             style={{fontWeight:"bold",marginBottom:"7px"}} onClick={(e)=>{this.changeGroup("flute")}}>Flute</button>
              <button className={[(this.state.instrument=="clarinet")?"cat-button-selected":"cat-button"].join(" ")} 
             style={{fontWeight:"bold",marginBottom:"7px"}} onClick={(e)=>{this.changeGroup("clarinet")}}>Clarinet</button>
              <button className={[(this.state.instrument=="trumpet")?"cat-button-selected":"cat-button"].join(" ")} 
             style={{fontWeight:"bold",marginBottom:"7px"}} onClick={(e)=>{this.changeGroup("trumpet")}}>Trumpet</button>
                 <button className={[(this.state.instrument=="trombone")?"cat-button-selected":"cat-button"].join(" ")} 
             style={{fontWeight:"bold",marginBottom:"7px"}} onClick={(e)=>{this.changeGroup("trombone")}}>Trombone</button>
          <button className={[(this.state.instrument=="harp")?"cat-button-selected":"cat-button"].join(" ")} 
             style={{fontWeight:"bold",marginBottom:"7px"}} onClick={(e)=>{this.changeGroup("harp")}}>Harp</button>
             <button className={[(this.state.instrument=="guitar")?"cat-button-selected":"cat-button"].join(" ")} 
             style={{fontWeight:"bold",marginBottom:"7px"}} onClick={(e)=>{this.changeGroup("guitar")}}>Guitar</button>
          <button className={[(this.state.instrument=="other")?"cat-button-selected":"cat-button"].join(" ")} 
             style={{fontWeight:"bold",marginBottom:"7px"}} onClick={(e)=>{this.changeGroup("other")}}>Other</button>
        </div>
        <Container fluid style={{padding:"2% 5%",fontFamily:"Courgette",zIndex:"10",position:"relative"}} >
        
        { (Object.keys(this.state.group5).length>0) &&
         <div style={{padding:"0% 1%"}}>
         <Row>
          <Col xs={12} md={12} style={{textAlign:"left", marginLeft:"0%",fontSize:"1.4em"}}>Original Composition Group</Col>
          </Row> 
          <Row>
          <Col xs={2} md={1}>Group No.</Col>
          <Col xs={4} md={7}>Group</Col>
          <Col xs={4}  md={3}>Description</Col>
          <Col xs={2} md={1}>Price (USD)</Col>
          </Row> 
          </div>}
          { (Object.keys(this.state.group5).length>0) &&
            Object.keys(this.state.group5).map((idx)=>
              <Row key={idx} style={{padding:"0% 1%"}}>
              <Col xs={2} md={1} style={{fontSize:"0.8em"}}>{this.state.group5[idx]["number"]}</Col>
              <Col xs={4} md={7}>{this.state.group5[idx]["name"]+"  "+" ( download score "}<a 
              href={"/assets/"+this.state.group5[idx]["link"]}
              color="transparent"
              target="_blank">HERE</a>{")  "}</Col>
              <Col xs={4} md={3}>{this.state.group5[idx]["des"]}</Col>
              <Col xs={2} md={1}>{this.state.group5[idx]["price"]}</Col>
              </Row> 
            )
          }

          <br></br>


         { (Object.keys(this.state.group0).length>0) &&
         <div style={{padding:"0% 1%"}}>
         <Row>
          <Col xs={12} md={12} style={{textAlign:"left", marginLeft:"0%",fontSize:"1.4em"}}>COMPOSER GROUP</Col>
          </Row> 
          <Row>
          <Col xs={2} md={1}>Group No.</Col>
          <Col xs={4} md={7}>Group</Col>
          <Col xs={4}  md={3}>Description</Col>
          <Col xs={2} md={1}>Price (USD)</Col>
          </Row> 
          </div>}
          { (Object.keys(this.state.group0).length>0) &&
            Object.keys(this.state.group0).map((idx)=>
              <Row key={idx} style={{padding:"0% 1%"}}>
              <Col xs={2} md={1} style={{fontSize:"0.8em"}}>{this.state.group0[idx]["number"]}</Col>
              <Col xs={4} md={7}>{this.state.group0[idx]["name"]}</Col>
              <Col xs={4} md={3}>{this.state.group0[idx]["des"]}</Col>
              <Col xs={2} md={1}>{this.state.group0[idx]["price"]}</Col>
              </Row> 
            )
          }

          <br></br>
          {(Object.keys(this.state.group3).length>0) && 
          <div style={{padding:"0% 1%"}}>
            <Row>
          <Col xs={12} md={12} style={{textAlign:"left", marginLeft:"0%",fontSize:"1.4em"}}>AGE GROUP</Col>
          </Row> 
          <Row>
          <Col xs={2} md={1}>Group No.</Col>
          <Col xs={4} md={7}>Group</Col>
          <Col xs={4}  md={3}>Description</Col>
          <Col xs={2} md={1}>Price (USD)</Col>
          </Row>
          </div>} 
          { (Object.keys(this.state.group3).length>0) &&
            Object.keys(this.state.group3).map((idx)=>
              <Row key={idx} style={{padding:"0% 1%"}} >
              <Col xs={2} md={1} style={{fontSize:"0.8rem"}}>{this.state.group3[idx]["number"]}</Col>
              <Col xs={4} md={7}>{this.state.group3[idx]["name"]}</Col>
              <Col xs={4} md={3}>{this.state.group3[idx]["des"]}</Col>
              <Col xs={2} md={1}>{this.state.group3[idx]["price"]}</Col>
              </Row> 
            )
          }
         
          <br></br>

          {(Object.keys(this.state.group1).length>0) &&
          <div style={{padding:"0% 1%"}}><Row>
          <Col xs={12} md={12} style={{textAlign:"left", marginLeft:"0%",fontSize:"1.4em"}}>{(this.state.instrument==="recorder")?"Primary School Groups":"GRADED EXAM GROUP"}</Col>
          </Row> 
          <Row>
          <Col xs={2} md={1} style={{fontSize:"1.2em"}}>{"Group No."}</Col>
          <Col xs={4} md={7} style={{fontSize:"1.2em"}}>{"Group"}</Col>
          <Col xs={4}  md={3} style={{fontSize:"1.2em"}}>{"Description"}</Col>
          <Col xs={2} md={1} style={{fontSize:"1.2em"}}>{"Price (USD)"}</Col>
          </Row></div>}
          { (Object.keys(this.state.group1).length>0) &&
            Object.keys(this.state.group1).map((idx)=>
              <Row key={idx} style={{padding:"0% 1%"}} >
              <Col xs={2} md={1} style={{fontSize:"0.8rem"}}>{this.state.group1[idx]["number"]}</Col>
              <Col xs={4} md={7}>{this.state.group1[idx]["name"]}</Col>
              <Col xs={4} md={3}>{this.state.group1[idx]["des"]}</Col>
              <Col xs={2} md={1}>{this.state.group1[idx]["price"]}</Col>
              </Row> 
            )
          }

<br></br>
          {(Object.keys(this.state.group4).length>0) &&
          <div style={{padding:"0% 1%"}}><Row>
          <Col xs={12} md={12} style={{textAlign:"left", marginLeft:"0%",fontSize:"1.4em"}}>{"OTHER GROUPS"}</Col>
          </Row> 
          <Row>
          <Col xs={2} md={1}>Group No.</Col>
          <Col xs={4} md={7}>Group</Col>
          <Col xs={4}  md={3}>Description</Col>
          <Col xs={2} md={1}>Price (USD)</Col>
          </Row></div>}
          { (Object.keys(this.state.group4).length>0) &&
            Object.keys(this.state.group4).map((idx)=>
              <Row key={idx} style={{padding:"0% 1%"}}>
              <Col xs={2} md={1} style={{fontSize:"0.8rem"}}>{this.state.group4[idx]["number"]}</Col>
              <Col xs={4} md={7}>{this.state.group4[idx]["name"]}</Col>
              <Col xs={4} md={3}>{this.state.group4[idx]["des"]}</Col>
              <Col xs={2} md={1}>{this.state.group4[idx]["price"]}</Col>
              </Row> 
            )
          }


          <br></br>

          {(Object.keys(this.state.group2).length>0) &&
          <div style={{padding:"0% 1%"}}>
            <Row>
          <Col xs={12} md={12} style={{textAlign:"left", marginLeft:"0%",fontSize:"1.4em"}}>{"2023 SPECIAL GROUP"}</Col>
          </Row> 
          <Row>
          <Col xs={12} md={12} style={{textAlign:"left", marginLeft:"0%",fontSize:"1.4em"}}>{"HONG KONG SCHOOL MUSIC FESTIVAL GROUP"}</Col>
          </Row> 
          <Row>
          <Col xs={2} md={1}>Group No.</Col>
          <Col xs={4} md={7}>Group</Col>
          <Col xs={4}  md={3}>Description</Col>
          <Col xs={2} md={1}>Price (USD)</Col>
          </Row></div>} 
          { (Object.keys(this.state.group2).length>0) &&
            Object.keys(this.state.group2).map((idx)=>
              <Row key={idx} style={{padding:"0% 1%"}} >
              <Col xs={2} md={1} style={{fontSize:"0.8rem"}}>{this.state.group2[idx]["number"]}</Col>
              <Col xs={4} md={7}>{this.state.group2[idx]["name"]}</Col>
              <Col xs={4} md={3}>{this.state.group2[idx]["des"]}</Col>
              <Col xs={2} md={1}>{this.state.group2[idx]["price"]}</Col>
              </Row> 
            )
          }
 
          </Container>

      </div>
    );
  }
  }
  

export default Category;
