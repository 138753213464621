import '../../App.css';
import React, {Component, Container} from 'react';
import {Image,Navbar, Nav, NavItem, NavDropdown} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import YoutubeBackground from 'react-youtube-background';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import IYFAONavBarTop from "./navbar";
import IYFAOFooter from "./footer";
const applyurl = "https://docs.google.com/forms/d/e/1FAIpQLScoSIrXM_CqsXcaw6mSZmB8f6E1MoXcis3QFeRaE_SO6cP_5A/viewform?usp=sf_link"

class IYFAOTerms extends Component {
  constructor(props){
    super(props);
    this.state = {  
       
    };

    this.setval = this.setval.bind(this)
  }
  componentDidMount(){
    this.props.setval({comp:"iypo-cn",lang:"cn"})  
  }
  setval(obj){
    this.setState(obj)
  }

  render(){
    return (
      <div>
       <img src="/assets/term.png" width="100%" style={{borderRadius:"0px"}} />
      <div style={{display:"flex",flexDirection:"column",paddingLeft:"15%",paddingRight:"15%",paddingTop:"4%",paddingBottom:"2%",textAlign:"left",fontFamily:"Arial"}}>
      <h4 style={{fontSize:"1.8em"}}><u><i>比赛规则</i></u></h4>
      <ul style={{textAlign:"justify"}}>
        <li><p style={{fontSize:"1.4em"}}>该竞赛向国际申请者开放。 对申请人的原籍没有限制。 </p></li>
        <li><p style={{fontSize:"1.4em"}}>所有评审团成员不得评判自己学生的作品或作为参赛者参加比赛。</p></li>
      <li><p style={{fontSize:"1.4em"}}>禁止参赛者联系任何评审团成员。 违规可能会导致取消资格。</p></li>
      <li><p style={{fontSize:"1.4em"}}>评审团成员的结果和评论是最终决定，无可争议。</p></li>
      <li><p style={{fontSize:"1.4em"}}>主办方有权拒绝和/或取消违反竞赛规则的参赛者的参赛资格。 </p></li>
      <li><p style={{fontSize:"1.4em"}}>提交作品的内容不应包含与极端暴力、淫秽和其他不雅行为相关的内容。</p></li>
      <li><p style={{fontSize:"1.4em"}}>已在其他场合提交的作品可以在本次比赛中使用。</p></li>
      <li><p style={{fontSize:"1.4em"}}>请确保提交内容清晰可见。</p></li> {/*For sculptures and handcrafts, guidlines on taking multiple shots will be given.*/}
      <li><p style={{fontSize:"1.4em"}}>主办方有权修改竞赛规则、奖品内容和评审团组成。 任何修改可能不会公布。</p></li>
      <li><p style={{fontSize:"1.4em"}}>比赛的正式规则和条例应为在官方网站上发布的版本。</p></li>
      <li><p style={{fontSize:"1.4em"}}>主办方拥有最终解释权。</p></li>
    </ul>
      
     
    
    <br></br>

    <h4 style={{fontSize:"1.8em"}}><u><i>条款</i></u></h4>
    <ul style={{textAlign:"justify"}}>
    <li><p style={{fontSize:"1.4em"}}>参赛者有权申请一个以上的比赛组别。 </p></li>
      <li><p style={{fontSize:"1.4em"}}>所有申请费均不予退还。</p></li>
    <li><p style={{fontSize:"1.4em"}}>参赛者的所有资料将予以保密，并仅用于与本次比赛相关的联络、通知及推广事宜。</p></li>
    <li><p style={{fontSize:"1.4em"}}>申请或提交信息任何部分的任何变更均需缴纳 15 美元或等值的管理费。</p></li>
    </ul>
       <br></br>

{/* 
        <h2>2022 International Young Artist Open Competition</h2>
        <h4>Deadline: 15th November 2022</h4>
        <h4>Results Date: 19th December 2022</h4>
        <br/>      

      <br/><br/>
    <h6>After successful payment, we will send a confirmation email to you.</h6>
    <p style={{fontSize:"0.4em"}}>*All application fees are non-refundable.</p> */}
      </div>
      </div>
    );
  }
  }
  

export default IYFAOTerms;
