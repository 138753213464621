import "./pricelist";
import pricelist from "./pricelist";

const groupCat = {
    "piano" :{
        0 : {
            1:{
            "id":1,"number":"PFC1", "name":"Bach - Age 12 or under", "des":"Any 1 piece or movement by/attributed to J.S. Bach under 8 minutes", "des-cn":"任何一首 J.S. Bach 创作， 8 分钟或以下的乐曲或乐章","price":pricelist["PFC1"]
            },
            2:{
                "id":2,"number":"PFC2", "name":"Bach - Age 12 to 18", "des":"Any 1 piece or movement by/attributed to J.S. Bach under 8 minutes", "des-cn":"任何一首 J.S. Bach 创作， 8 分钟或以下的乐曲或乐章", "price":pricelist["PFC2"]
                },
            3:{
                "id":3,"number":"PFC3", "name":"Bach - Age 18 or above", "des":"Any 1 piece or movement by/attributed to J.S. Bach under 15 minutes", "des-cn":"任何一首 J.S. Bach 创作， 15 分钟或以下的乐曲或乐章", "price":pricelist["PFC3"]
                },
            4:{
            "id":4,"number":"PFC4", "name":"Mozart - Age 12 or under", "des":"Any 1 piece or movement by W.A. Mozart under 8 minutes", "des-cn":"任何一首 W.A. Mozart 创作， 8 分钟或以下的乐曲或乐章", "price":pricelist["PFC4"]
            },
            5:{
                "id":5,"number":"PFC5", "name":"Mozart - Age 12 to 18", "des":"Any 1 piece or movement by W.A. Mozart under 8 minutes", "des-cn":"任何一首 W.A. Mozart 创作， 8 分钟或以下的乐曲或乐章", "price":pricelist["PFC5"]
                },
            6:{
                "id":6,"number":"PFC6", "name":"Mozart - Age 18 or above", "des":"Any 1 piece or movement by W.A. Mozart under 15 minutes", "des-cn":"任何一首 W.A. Mozart 创作， 15 分钟或以下的乐曲或乐章", "price":pricelist["PFC6"]
                },
            7:{
            "id":7,"number":"PFC7", "name":"Haydn - Age 12 or under", "des":"Any 1 piece or movement by Joseph Haydn under 8 minutes", "des-cn":"任何一首 Joseph Haydn 创作， 8 分钟或以下的乐曲或乐章", "price":pricelist["PFC7"]
            },
            8:{
                "id":8,"number":"PFC8", "name":"Haydn - Age 12 to 18", "des":"Any 1 piece or movement by Joseph Haydn under 8 minutes", "des-cn":"任何一首 Joseph Haydn 创作， 8 分钟或以下的乐曲或乐章", "price":pricelist["PFC8"]
                },
            9:{
                "id":9,"number":"PFC9", "name":"Haydn - Age 18 or above", "des":"Any 1 piece or movement by Joseph Haydn under 15 minutes", "des-cn":"任何一首 Joseph Haydn 创作， 15 分钟或以下的乐曲或乐章", "price":pricelist["PFC9"]
                },
            10:{
            "id":10,"number":"PFC10", "name":"Beethoven - Age 12 or under", "des":"Any 1 piece or movement by L.V. Beethoven under 10 minutes", "des-cn":"任何一首 L.V. Beethoven 创作， 10 分钟或以下的乐曲或乐章", "price":pricelist["PFC10"]
            },
            11:{
                "id":11,"number":"PFC11", "name":"Beethoven - Age 12 to 18", "des":"Any 1 piece or movement by L.V. Beethoven under 10 minutes", "des-cn":"任何一首 L.V. Beethoven 创作， 10 分钟或以下的乐曲或乐章", "price":pricelist["PFC11"]
                },
            12:{
                "id":12,"number":"PFC12", "name":"Beethoven - Age 18 or above", "des":"Any 1 piece or movement by L.V. Beethoven under 15 minutes", "des-cn":"任何一首 L.V. Beethoven 创作， 15 分钟或以下的乐曲或乐章", "price":pricelist["PFC12"]
                },
            13:{
            "id":13,"number":"PFC13", "name":"Chopin - Age 12 or under", "des":"Any 1 piece or movement by Frédéric Chopin under 10 minutes", "des-cn":"任何一首 Frédéric Chopin 创作， 10 分钟或以下的乐曲或乐章", "price":pricelist["PFC13"]
            },
            14:{
                "id":14,"number":"PFC14", "name":"Chopin - Age 12 to 18", "des":"Any 1 piece or movement by Frédéric Chopin  under 10 minutes", "des-cn":"任何一首 Frédéric Chopin 创作， 10 分钟或以下的乐曲或乐章", "price":pricelist["PFC14"]
                },
            15:{
                "id":15,"number":"PFC15", "name":"Chopin - Age 18 or above", "des":"Any 1 piece or movement by Frédéric Chopin under 15 minutes", "des-cn":"任何一首 Frédéric Chopin 创作， 15 分钟或以下的乐曲或乐章", "price":pricelist["PFC15"]
                },
            16:{
            "id":16,"number":"PFC16", "name":"Czerny - Age 12 or under", "des":"Any 1 piece or movement by Carl Czerny under 6 minutes", "des-cn":"任何一首 Carl Czerny 创作， 6 分钟或以下的乐曲或乐章", "price":pricelist["PFC16"]
            },
            17:{
                "id":17,"number":"PFC17", "name":"Czerny - Age 12 to 18", "des":"Any 1 piece or movement by Carl Czerny under 6 minutes", "des-cn":"任何一首 Carl Czerny 创作， 6 分钟或以下的乐曲或乐章", "price":pricelist["PFC17"]
                },
            18:{
                "id":18,"number":"PFC18", "name":"Czerny - Age 18 or above", "des":"Any 1 piece or movement by Carl Czerny under 10 minutes", "des-cn":"任何一首 Carl Czerny 创作， 10 分钟或以下的乐曲或乐章", "price":pricelist["PFC18"]
                },
        },   
        2 : {			
            // 1:{
            // "id":1,"number":"PFSMF1", "name":"School Music Festival - Grade 1", "des":"Any 1 solo or accompanied piece", "des-cn":"任何一首独奏或伴奏曲目",  "price":40
            // },
            // 2:{
            // "id":2,"number":"PFSMF2", "name":"School Music Festival - Grade 2", "des":"Any 1 solo or accompanied piece", "des-cn":"任何一首独奏或伴奏曲目", "price":40
            // },
            // 3:{
            // "id":3,"number":"PFSMF3", "name":"School Music Festival - Grade 3", "des":"Any 1 solo or accompanied piece","des-cn":"任何一首独奏或伴奏曲目", "price":40
            // },
            // 4:{
            // "id":4,"number":"PFSMF4", "name":"School Music Festival - Grade 4","des":"Any 1 solo or accompanied piece", "des-cn":"任何一首独奏或伴奏曲目", "price":45
            // },
            // 5:{
            // "id":5,"number":"PFSMF5", "name":"School Music Festival - Grade 5","des":"Any 1 solo or accompanied piece", "des-cn":"任何一首独奏或伴奏曲目", "price":45
            // },
            // 6:{
            // "id":6,"number":"PFSMF6", "name":"School Music Festival - Grade 6","des":"Any 1 solo or accompanied piece", "des-cn":"任何一首独奏或伴奏曲目", "price":45
            // },
            // 7:{
            // "id":7,"number":"PFSMF7", "name":"School Music Festival - Grade 7","des":"Any 1 solo or accompanied piece",  "des-cn":"任何一首独奏或伴奏曲目","price":50
            // },
            // 8:{
            // "id":8,"number":"PFSMF8", "name":"School Music Festival - Grade 8","des":"Any 1 solo or accompanied piece", "des-cn":"任何一首独奏或伴奏曲目", "price":50
            // },
            // 9:{
            // "id":9,"number":"PFSMF9", "name":"School Music Festival - Primary Duet","des":"Any 1 duet piece for 2 primary school students",  "des-cn":"任何一首二人独奏或伴奏曲目 - 小学组","price":50
            // },
            // 10:{
            // "id":10,"number":"PFSMF10", "name":"School Music Festival - Secondary Duet","des":"Any 1 duet piece for 2 secondary school students",  "des-cn":"任何一首二人独奏或伴奏曲目 - 中学组","price":50
            // },
        },
        3 : {			
            1:{
            "id":1,"number":"PFA1", "name":"Debut", "des":"7 or below; Any one piece less than 2 minutes", "des-cn":"7岁或以下；任何首少于2分钟的乐目", "price":pricelist["PFA1"]
            },
            2:{
            "id":2,"number":"PFA2", "name":"Junior	", "des":"7 to 9; Any one piece less than 3 minutes",  "des-cn":"7岁至9岁；任何首少于3分钟的乐目","price":pricelist["PFA2"]
            },
            3:{
            "id":3,"number":"PFA3", "name":"Emerging", "des":"9 to 12; Any one piece less than 3 minutes", "des-cn":"9岁至12岁；任何首少于3分钟的乐目","price":pricelist["PFA3"]
            },
            4:{
            "id":4,"number":"PFA4", "name":"Youth","des":"12 to 15; Any one piece less than 5 minutes", "des-cn":"12岁至15岁；任何首少于5分钟的乐目", "price":pricelist["PFA4"]
            },
            5:{
            "id":5,"number":"PFA5", "name":"Young Artist","des":"15 to 18; Any one piece less than 6 minutes",  "des-cn":"15岁至18岁；任何首少于6分钟的乐目","price":pricelist["PFA5"]
            },
            6:{
            "id":6,"number":"PFA6", "name":"Artist","des":"18 or above; Any one piece less than 12 minutes",  "des-cn":"18岁或以上；任何首少于12分钟的乐目","price":pricelist["PFA6"]
            },
        
        },
        1 : {			
            1:{
            "id":1,"number":"PFG1", "name":"Grade 1", "des":"Any 1 piece from any recognized Graded Exam Syllabus",  "des-cn":"任何国际分级考试中的任何 1 首乐曲", "price":pricelist["PFG1"]
            },
            2:{
            "id":2,"number":"PFG2", "name":"Grade 2", "des":"Any 1 piece from any recognized Graded Exam Syllabus", "des-cn":"任何国际分级考试中的任何 1 首乐曲", "price":pricelist["PFG2"]
            },
            3:{
            "id":3,"number":"PFG3", "name":"Grade 3", "des":"Any 1 piece from any recognized Graded Exam Syllabus", "des-cn":"任何国际分级考试中的任何 1 首乐曲","price":pricelist["PFG3"]
            },
            4:{
            "id":4,"number":"PFG4", "name":"Grade 4","des":"Any 1 piece from any recognized Graded Exam Syllabus",  "des-cn":"任何国际分级考试中的任何 1 首乐曲","price":pricelist["PFG4"]
            },
            5:{
            "id":5,"number":"PFG5", "name":"Grade 5","des":"Any 1 piece from any recognized Graded Exam Syllabus", "des-cn":"任何国际分级考试中的任何 1 首乐曲", "price":pricelist["PFG5"]
            },
            6:{
            "id":6,"number":"PFG6", "name":"Grade 6","des":"Any 1 piece from any recognized Graded Exam Syllabus",  "des-cn":"任何国际分级考试中的任何 1 首乐曲","price":pricelist["PFG6"]
            },
            7:{
            "id":7,"number":"PFG7", "name":"Grade 7", "des":"Any 1 piece from any recognized Graded Exam Syllabus", "des-cn":"任何国际分级考试中的任何 1 首乐曲", "price":pricelist["PFG7"]
            },
            8:{
            "id":8,"number":"PFG8", "name":"Grade 8", "des":"Any 1 piece from any recognized Graded Exam Syllabus",  "des-cn":"任何国际分级考试中的任何 1 首乐曲","price":pricelist["PFG8"]
            },
            9:{
            "id":9,"number":"PFDA", "name":"ARSM, DipABRSM, ATCL", "des":"Any 1 piece from ARSM, DipABRSM, ATCL under 7 minutes", "des-cn":"任何ARSM, DipABRSM, ATCL考试中的任何 1 首7分钟或以下乐曲", "price":pricelist["PFDA"]
            },
            10:{
            "id":10,"number":"PFDL", "name":"LRSM, LTCL", "des":"Any 1 piece from LRSM/LTCL under 10 minutes", "des-cn":"任何LRSM/LTCL考试中的任何 1 首10分钟或以下乐曲", "price":pricelist["PFDL"]
            },
            11:{
            "id":11,"number":"PFDF", "name":"FRSM,FTCL", "des":"Any 1 piece from FRSM/FTCL under 15 minutes",  "des-cn":"任何FRSM/FTCL考试中的任何 1 首15分钟或以下乐曲","price":pricelist["PFDF"]
            },
        
        },       
        4 : {			
            1:{
            "id":1,"number":"PFS1", "name":"Sonatina Group - Debut", "des":"Age 7 or below; Any 1 movement under 5 minutes", "des-cn":"任何 1 首5 分钟内的乐曲", "price":pricelist["PFS1"]
            },
            2:{
            "id":2,"number":"PFS2", "name":"Sonatina Group - Junior", "des":"Age 7 to 9; Any 1 movement under 5 minutes", "des-cn":"任何 1 首5 分钟内的乐曲", "price":pricelist["PFS2"]
            },
            3:{
            "id":3,"number":"PFS3", "name":"Sonatina Group - Emerging", "des":"Age 9 to 12; Any 1 movement under 5 minutes", "des-cn":"任何 1 首5 分钟内的乐曲", "price":pricelist["PFS3"]
            },
            4:{
            "id":4,"number":"PFS4", "name":"Sonatina Group - Youth", "des":"Age 12 to 15; Any 1 movement under 5 minutes", "des-cn":"任何 1 首5 分钟内的乐曲", "price":pricelist["PFS4"]
            },
            5:{
            "id":5,"number":"PFS5", "name":"Sonatina Group - Young Artist", "des":"Age 15 to 18; Any 1 movement under 5 minutes", "des-cn":"任何 1 首5 分钟内的乐曲", "price":pricelist["PFS5"]
            },
            6:{
            "id":6,"number":"PFS6", "name":"Sonatina Group - Artist", "des":"Age 18 or above; Any 1 movement under 5 minutes", "des-cn":"任何 1 首5 分钟内的乐曲", "price":pricelist["PFS6"]
            },
        
        },
        5: {			
            1:{
            "id":1,"number":"PFQ1", "name":"Two Nocturnes Op.4 by Prof. Manrique", "des":"Complete Piece", "des-cn":"全首乐曲", "price":pricelist["PFQ1"],"link":"2_Nocturnes_Op.4.pdf"
            },
            2:{
                "id":2,"number":"PFQ2", "name":"Sunrise Op.14 by Prof. Manrique", "des":"Complete Piece", "des-cn":"全首乐曲", "price":pricelist["PFQ2"],"link":"Sunrise_Op.14.pdf"
                },
            3:{
                "id":3,"number":"PFQ3", "name":"Romanze Op.17 by Prof. Manrique", "des":"Complete Piece", "des-cn":"全首乐曲", "price":pricelist["PFQ3"],"link":"Romanze_Op.17.pdf"
                },
            4:{
                "id":4,"number":"PFQ4", "name":"Für Rachel I & II by Prof. Manrique", "des":"Complete Piece", "des-cn":"全首乐曲", "price":pricelist["PFQ4"],"link":"Für_Rachel_I&II.pdf"
                },
            5:{
                "id":5,"number":"PFQ5", "name":"Nocturne for Piano Four Hands Op.19 by Prof. Manrique", "des":"Complete Piece", "des-cn":"全首乐曲", "price":pricelist["PFQ5"],"link":"Nocturne_Op.19.pdf"
                },
        
        },
    },

    "violin" :{
        5:{},
        0 : {
            1:{
                "id":1,"number":"VNC1", "name":"Bach - Age 12 or under", "des":"Any 1 piece or movement by/attributed to J.S. Bach under 8 minutes", "des-cn":"任何一首 J.S. Bach 创作， 8 分钟或以下的乐曲或乐章","price":pricelist["VNC1"]
                },
                2:{
                    "id":2,"number":"VNC2", "name":"Bach - Age 12 to 18", "des":"Any 1 piece or movement by/attributed to J.S. Bach under 8 minutes", "des-cn":"任何一首 J.S. Bach 创作， 8 分钟或以下的乐曲或乐章", "price":pricelist["VNC2"]
                    },
                3:{
                    "id":3,"number":"VNC3", "name":"Bach - Age 18 or above", "des":"Any 1 piece or movement by/attributed to J.S. Bach under 15 minutes", "des-cn":"任何一首 J.S. Bach 创作， 15 分钟或以下的乐曲或乐章", "price":pricelist["VNC3"]
                    },
                4:{
                "id":4,"number":"VNC4", "name":"Mozart - Age 12 or under", "des":"Any 1 piece or movement by W.A. Mozart under 8 minutes", "des-cn":"任何一首 W.A. Mozart 创作， 8 分钟或以下的乐曲或乐章", "price":pricelist["VNC4"]
                },
                5:{
                    "id":5,"number":"VNC5", "name":"Mozart - Age 12 to 18", "des":"Any 1 piece or movement by W.A. Mozart under 8 minutes", "des-cn":"任何一首 W.A. Mozart 创作， 8 分钟或以下的乐曲或乐章", "price":pricelist["VNC5"]
                    },
                6:{
                    "id":6,"number":"VNC6", "name":"Mozart - Age 18 or above", "des":"Any 1 piece or movement by W.A. Mozart under 15 minutes", "des-cn":"任何一首 W.A. Mozart 创作， 15 分钟或以下的乐曲或乐章", "price":pricelist["VNC6"]
                    },
                7:{
                "id":7,"number":"VNC7", "name":"Beethoven - Age 12 or under", "des":"Any 1 piece or movement by L.V. Beethoven under 10 minutes", "des-cn":"任何一首 L.V. Beethoven  创作， 10 分钟或以下的乐曲或乐章", "price":pricelist["VNC7"]
                },
                8:{
                    "id":8,"number":"VNC8", "name":"Beethoven - Age 12 to 18", "des":"Any 1 piece or movement by L.V. Beethoven under 10 minutes", "des-cn":"任何一首 L.V. Beethoven  创作， 10 分钟或以下的乐曲或乐章", "price":pricelist["VNC8"]
                    },
                9:{
                    "id":9,"number":"VNC9", "name":"Beethoven - Age 18 or above", "des":"Any 1 piece or movement by L.V. Beethoven under 15 minutes", "des-cn":"任何一首 L.V. Beethoven  创作， 15 分钟或以下的乐曲或乐章", "price":pricelist["VNC9"]
                    },
                10:{
                "id":10,"number":"VNC10", "name":"Paganini - Age 12 or under", "des":"Any 1 piece or movement by Niccolo Paganini under 8 minutes", "des-cn":"任何一首 Niccolo Paganini 创作， 8 分钟或以下的乐曲或乐章", "price":pricelist["VNC10"]
                },
                11:{
                    "id":11,"number":"VNC11", "name":"Paganini - Age 12 to 18", "des":"Any 1 piece or movement by Niccolo Paganini under 8 minutes", "des-cn":"任何一首 Niccolo Paganini 创作， 8 分钟或以下的乐曲或乐章", "price":pricelist["VNC11"]
                    },
                12:{
                    "id":12,"number":"VNC12", "name":"Paganini - Age 18 or above", "des":"Any 1 piece or movement by Niccolo Paganini under 15 minutes", "des-cn":"任何一首 Niccolo Paganini 创作， 15 分钟或以下的乐曲或乐章", "price":pricelist["VNC12"]
                    },
                13:{
                "id":13,"number":"VNC13", "name":"Vivaldi - Age 12 or under", "des":"Any 1 piece or movement by Antonio Vivaldi under 8 minutes", "des-cn":"任何一首 Antonio Vivaldi 创作， 8 分钟或以下的乐曲或乐章", "price":pricelist["VNC13"]
                },
                14:{
                    "id":14,"number":"VNC14", "name":"Vivaldi - Age 12 to 18", "des":"Any 1 piece or movement by Antonio Vivaldi  under 8 minutes", "des-cn":"任何一首 Antonio Vivaldi 创作， 8 分钟或以下的乐曲或乐章", "price":pricelist["VNC14"]
                    },
                15:{
                    "id":15,"number":"VNC15", "name":"Vivaldi - Age 18 or above", "des":"Any 1 piece or movement by Antonio Vivaldi under 15 minutes", "des-cn":"任何一首 Antonio Vivaldi 创作， 15 分钟或以下的乐曲或乐章", "price":pricelist["VNC15"]
                    },
           
        }, 
        3 : {
            1:{
                "id":1,"number":"VNA1", "name":"Debut", "des":"7 or below; Any one piece less than 2 minutes", "des-cn":"7岁或以下；任何首少于2分钟的乐目", "price":pricelist["VNA1"]
                },
                2:{
                "id":2,"number":"VNA2", "name":"Junior	", "des":"7 to 9; Any one piece less than 3 minutes",  "des-cn":"7岁至9岁；任何首少于3分钟的乐目","price":pricelist["VNA2"]
                },
                3:{
                "id":3,"number":"VNA3", "name":"Emerging", "des":"9 to 12; Any one piece less than 3 minutes","des-cn":"9岁至12岁；任何首少于3分钟的乐目","price":pricelist["VNA3"]
                },
                4:{
                "id":4,"number":"VNA4", "name":"Youth","des":"12 to 15; Any one piece less than 5 minutes","des-cn":"12岁至15岁；任何首少于5分钟的乐目",  "price":pricelist["VNA4"]
                },
                5:{
                "id":5,"number":"VNA5", "name":"Young Artist","des":"15 to 18; Any one piece less than 6 minutes", "des-cn":"15岁至18岁；任何首少于6分钟的乐目","price":pricelist["VNA5"]
                },
                6:{
                "id":6,"number":"VNA6", "name":"Artist","des":"18 or above; Any one piece less than 12 minutes", "des-cn":"18岁或以上；任何首少于12分钟的乐目","price":pricelist["VNA6"]
                },
        },  
        2 : { 
            // 1:{
            // "id":1,"number":"VNSMF1", "name":"School Music Festival - Grade 1", "des":"Any 1 solo or accompanied piece", "des-cn":"任何一首独奏或伴奏曲目",  "price":40
            // },
            // 2:{
            // "id":2,"number":"VNSMF2", "name":"School Music Festival - Grade 2", "des":"Any 1 solo or accompanied piece","des-cn":"任何一首独奏或伴奏曲目",  "price":40
            // },
            // 3:{
            // "id":3,"number":"VNSMF3", "name":"School Music Festival - Grade 3", "des":"Any 1 solo or accompanied piece","des-cn":"任何一首独奏或伴奏曲目", "price":40
            // },
            // 4:{
            // "id":4,"number":"VNSMF4", "name":"School Music Festival - Grade 4","des":"Any 1 solo or accompanied piece", "des-cn":"任何一首独奏或伴奏曲目", "price":45
            // },
            // 5:{
            // "id":5,"number":"VNSMF5", "name":"School Music Festival - Grade 5","des":"Any 1 solo or accompanied piece", "des-cn":"任何一首独奏或伴奏曲目", "price":45
            // },
            // 6:{
            // "id":6,"number":"VNSMF6", "name":"School Music Festival - Grade 6","des":"Any 1 solo or accompanied piece", "des-cn":"任何一首独奏或伴奏曲目", "price":45
            // },
            // 7:{
            // "id":7,"number":"VNSMF7", "name":"School Music Festival - Grade 7","des":"Any 1 solo or accompanied piece", "des-cn":"任何一首独奏或伴奏曲目", "price":50
            // },
            // 8:{
            // "id":8,"number":"VNSMF8", "name":"School Music Festival - Grade 8","des":"Any 1 solo or accompanied piece", "des-cn":"任何一首独奏或伴奏曲目","price":50
            // },
            // 9:{
            // "id":9,"number":"VNSMF9", "name":"School Music Festival - Primary Duet","des":"Any 1 duet piece for 2 primary school students", "des-cn":"任何一首二人独奏或伴奏曲目 - 小学组", "price":50
            // },
            // 10:{
            // "id":10,"number":"VNSMF10", "name":"School Music Festival - Secondary Duet","des":"Any 1 duet piece for 2 secondary school students", "des-cn":"任何一首二人独奏或伴奏曲目 - 中学组", "price":50
            // },			
        
        },
        1 : {			
        1:{
            "id":1,"number":"VNG1", "name":"Grade 1", "des":"Any 1 piece from any recognized Graded Exam Syllabus", "des-cn":"任何国际分级考试中的任何 1 首乐曲",  "price":pricelist["VNG1"]
            },
            2:{
            "id":2,"number":"VNG2", "name":"Grade 2", "des":"Any 1 piece from any recognized Graded Exam Syllabus", "des-cn":"任何国际分级考试中的任何 1 首乐曲", "price":pricelist["VNG2"]
            },
            3:{
            "id":3,"number":"VNG3", "name":"Grade 3", "des":"Any 1 piece from any recognized Graded Exam Syllabus","des-cn":"任何国际分级考试中的任何 1 首乐曲", "price":pricelist["VNG3"]
            },
            4:{
            "id":4,"number":"VNG4", "name":"Grade 4","des":"Any 1 piece from any recognized Graded Exam Syllabus", "des-cn":"任何国际分级考试中的任何 1 首乐曲", "price":pricelist["VNG4"]
            },
            5:{
            "id":5,"number":"VNG5", "name":"Grade 5","des":"Any 1 piece from any recognized Graded Exam Syllabus", "des-cn":"任何国际分级考试中的任何 1 首乐曲", "price":pricelist["VNG5"]
            },
            6:{
            "id":6,"number":"VNG6", "name":"Grade 6","des":"Any 1 piece from any recognized Graded Exam Syllabus", "des-cn":"任何国际分级考试中的任何 1 首乐曲", "price":pricelist["VNG6"]
            },
            7:{
            "id":7,"number":"VNG7", "name":"Grade 7", "des":"Any 1 piece from any recognized Graded Exam Syllabus", "des-cn":"任何国际分级考试中的任何 1 首乐曲", "price":pricelist["VNG7"]
            },
            8:{
            "id":8,"number":"VNG8", "name":"Grade 8", "des":"Any 1 piece from any recognized Graded Exam Syllabus", "des-cn":"任何国际分级考试中的任何 1 首乐曲", "price":pricelist["VNG8"]
            },
            9:{
            "id":9,"number":"VNDA", "name":"ARSM, DipABRSM, ATCL", "des":"Any 1 piece from ARSM, DipABRSM, ATCL under 7 minutes", "des-cn":"任何ARSM, DipABRSM, ATCL考试中的任何 1 首7分钟或以下乐曲", "price":pricelist["VNDA"]
            },
            10:{
            "id":10,"number":"VNDL", "name":"LRSM, LTCL", "des":"Any 1 piece from LRSM/LTCL under 10 minutes", "des-cn":"任何LRSM/LTCL考试中的任何 1 首10分钟或以下乐曲","price":pricelist["VNDL"]
            },
            11:{
            "id":11,"number":"VNDF", "name":"FRSM,FTCL", "des":"Any 1 piece from FRSM/FTCL under 15 minutes", "des-cn":"任何FRSM/FTCL考试中的任何 1 首15分钟或以下乐曲", "price":pricelist["VNDF"]
            },
        
        },       
        4 : {			
            // 1:{
            // "id":1,"number":"VNS1", "name":"Sonata Group", "des":"Any 1 movement under 10 minutes",  "price":80
            // },
            // 2:{
            // "id":2,"number":"S42", "name":"Baroque Period Group", "des":"Any 1 piece less than 10 minutes", "price":700
            // },
            // 3:{
            // "id":3,"number":"S43", "name":"Classical Period Group", "des":"Any 1 piece less than 10 minutes","price":700
            // },
            // 4:{
            // "id":4,"number":"S44", "name":"Romantic Period Group","des":"Any 1 piece less than 10 minutes", "price":700
            // },
            // 5:{
            // "id":5,"number":"S45", "name":"Modern Period Group","des":"Any 1 piece less than 10 minutes", "price":700
            // },
            // 6:{
            // "id":6,"number":"S46", "name":"Etudes","des":"Any 1 etude under 6 minutes", "price":700
            // },
            // 7:{
            // "id":7,"number":"S47", "name":"Concerto", "des":"Any 1 movement under 12 minutes", "price":1000
            // },
            // 8:{
            // "id":8,"number":"S48", "name":"Composition", "des":"1 piece of original composition under 6 minutes", "price":800
            // },
        
        }
    },


    "cello" :{
        5:{},
        0 : { 
            1:{
                "id":1,"number":"VCC1", "name":"Bach - Age 12 or under", "des":"Any 1 piece or movement by/attributed to J.S. Bach under 8 minutes", "des-cn":"任何一首J.S. Bach 创作， 8 分钟或以下的乐曲或乐章", "price":pricelist["VCC1"]
                },
                2:{
                    "id":2,"number":"VCC2", "name":"Bach - Age 12 to 18", "des":"Any 1 piece or movement by/attributed to J.S. Bach under 8 minutes", "des-cn":"任何一首J.S. Bach 创作， 8 分钟或以下的乐曲或乐章", "price":pricelist["VCC2"]
                    },
                3:{
                    "id":3,"number":"VCC3", "name":"Bach - Age 18 or above", "des":"Any 1 piece or movement by/attributed to J.S. Bach under 15 minutes", "des-cn":"任何一首J.S. Bach 创作， 15 分钟或以下的乐曲或乐章", "price":pricelist["VCC3"]
                    },
                4:{
                "id":4,"number":"VCC4", "name":"Boccherini - Age 12 or under", "des":"Any 1 piece or movement by Luigi Boccherini under 8 minutes", "des-cn":"任何一首 Luigi Boccherini 创作， 8 分钟或以下的乐曲或乐章", "price":pricelist["VCC4"]
                },
                5:{
                    "id":5,"number":"VCC5", "name":"Boccherini - Age 12 to 18", "des":"Any 1 piece or movement by Luigi Boccherini under 8 minutes", "des-cn":"任何一首 Luigi Boccherini 创作， 8 分钟或以下的乐曲或乐章", "price":pricelist["VCC5"]
                    },
                6:{
                    "id":6,"number":"VCC6", "name":"Boccherini - Age 18 or above", "des":"Any 1 piece or movement by Luigi Boccherini under 15 minutes","des-cn":"任何一首 Luigi Boccherini 创作， 15 分钟或以下的乐曲或乐章",  "price":pricelist["VCC6"]
                    },
                7:{
                "id":7,"number":"VCC7", "name":"Dvořák - Age 12 or under", "des":"Any 1 piece or movement by Antonín Dvořák under 8 minutes","des-cn":"任何一首 Antonín Dvořák 创作， 8 分钟或以下的乐曲或乐章",  "price":pricelist["VCC7"]
                },
                8:{
                    "id":8,"number":"VCC8", "name":"Dvořák - Age 12 to 18", "des":"Any 1 piece or movement by Antonín Dvořák under 8 minutes", "des-cn":"任何一首 Antonín Dvořák 创作， 8 分钟或以下的乐曲或乐章", "price":pricelist["VCC8"]
                    },
                9:{
                    "id":9,"number":"VCC9", "name":"Dvořák - Age 18 or above", "des":"Any 1 piece or movement by Antonín Dvořák under 15 minutes", "des-cn":"任何一首 Antonín Dvořák 创作， 15 分钟或以下的乐曲或乐章", "price":pricelist["VCC9"]
                    },
                10:{
                "id":10,"number":"VCC10", "name":"Fauré - Age 12 or under", "des":"Any 1 piece or movement by Gabriel Fauré under 8 minutes", "des-cn":"任何一首 Gabriel Fauré 创作， 8 分钟或以下的乐曲或乐章", "price":pricelist["VCC10"]
                },
                11:{
                    "id":11,"number":"VCC11", "name":"Fauré - Age 12 to 18", "des":"Any 1 piece or movement by Gabriel Fauré under 8 minutes", "des-cn":"任何一首 Gabriel Fauré 创作， 8 分钟或以下的乐曲或乐章", "price":pricelist["VCC11"]
                    },
                12:{
                    "id":12,"number":"VCC12", "name":"Fauré - Age 18 or above", "des":"Any 1 piece or movement by Gabriel Fauré under 15 minutes", "des-cn":"任何一首 Gabriel Fauré 创作， 15 分钟或以下的乐曲或乐章", "price":pricelist["VCC12"]
                    },  
        }, 
        3 : {
            1:{
                "id":1,"number":"VCA1", "name":"Debut", "des":"7 or below; Any one piece less than 2 minutes", "des-cn":"7岁或以下；任何首少于2分钟的乐目", "price":pricelist["VCA1"]
                },
                2:{
                "id":2,"number":"VCA2", "name":"Junior	", "des":"7 to 9; Any one piece less than 3 minutes",  "des-cn":"7岁至9岁；任何首少于3分钟的乐目","price":pricelist["VCA2"]
                },
                3:{
                "id":3,"number":"VCA3", "name":"Emerging", "des":"9 to 12; Any one piece less than 3 minutes","des-cn":"9岁至12岁；任何首少于3分钟的乐目","price":pricelist["VCA3"]
                },
                4:{
                "id":4,"number":"VCA4", "name":"Youth","des":"12 to 15; Any one piece less than 5 minutes", "des-cn":"12岁至15岁；任何首少于5分钟的乐目", "price":pricelist["VCA4"]
                },
                5:{
                "id":5,"number":"VCA5", "name":"Young Artist","des":"15 to 18; Any one piece less than 6 minutes", "des-cn":"15岁至18岁；任何首少于6分钟的乐目","price":pricelist["VCA5"]
                },
                6:{
                "id":6,"number":"VCA6", "name":"Artist","des":"18 or above; Any one piece less than 12 minutes","des-cn":"18岁或以上；任何首少于12分钟的乐目", "price":pricelist["VCA6"]
                },
        },  
        2 : { 
            // 1:{
            // "id":1,"number":"VCSMF1", "name":"School Music Festival - Primary 1-2", "des":"Any 1 solo or accompanied piece","des-cn":"任何一首独奏或伴奏曲目",  "price":40
            // },
            // 2:{
            // "id":2,"number":"VCSMF2", "name":"School Music Festival - Primary 3-4", "des":"Any 1 solo or accompanied piece","des-cn":"任何一首独奏或伴奏曲目", "price":40
            // },
            // 3:{
            // "id":3,"number":"VCSMF3", "name":"School Music Festival - Primary 5-6", "des":"Any 1 solo or accompanied piece","des-cn":"任何一首独奏或伴奏曲目","price":45
            // },
            // 4:{
            // "id":4,"number":"VCSMF4", "name":"School Music Festival - Secondary 1-2","des":"Any 1 solo or accompanied piece", "des-cn":"任何一首独奏或伴奏曲目","price":45
            // },
            // 5:{
            // "id":5,"number":"VCSMF5", "name":"School Music Festival - Secondary 3-4","des":"Any 1 solo or accompanied piece","des-cn":"任何一首独奏或伴奏曲目", "price":50
            // },
            // 6:{
            // "id":6,"number":"VCSMF6", "name":"School Music Festival - Secondary 5-6","des":"Any 1 solo or accompanied piece","des-cn":"任何一首独奏或伴奏曲目", "price":50
            // },
        },
        1 : {			
        1:{
            "id":1,"number":"VCG1", "name":"Grade 1", "des":"Any 1 piece from any recognized Graded Exam Syllabus", "des-cn":"任何国际分级考试中的任何 1 首乐曲", "price":pricelist["VCG1"]
            },
            2:{
            "id":2,"number":"VCG2", "name":"Grade 2", "des":"Any 1 piece from any recognized Graded Exam Syllabus","des-cn":"任何国际分级考试中的任何 1 首乐曲", "price":pricelist["VCG2"]
            },
            3:{
            "id":3,"number":"VCG3", "name":"Grade 3", "des":"Any 1 piece from any recognized Graded Exam Syllabus","des-cn":"任何国际分级考试中的任何 1 首乐曲","price":pricelist["VCG3"]
            },
            4:{
            "id":4,"number":"VCG4", "name":"Grade 4","des":"Any 1 piece from any recognized Graded Exam Syllabus", "des-cn":"任何国际分级考试中的任何 1 首乐曲","price":pricelist["VCG4"]
            },
            5:{
            "id":5,"number":"VCG5", "name":"Grade 5","des":"Any 1 piece from any recognized Graded Exam Syllabus", "des-cn":"任何国际分级考试中的任何 1 首乐曲","price":pricelist["VCG5"]
            },
            6:{
            "id":6,"number":"VCG6", "name":"Grade 6","des":"Any 1 piece from any recognized Graded Exam Syllabus", "des-cn":"任何国际分级考试中的任何 1 首乐曲","price":pricelist["VCG6"]
            },
            7:{
            "id":7,"number":"VCG7", "name":"Grade 7", "des":"Any 1 piece from any recognized Graded Exam Syllabus","des-cn":"任何国际分级考试中的任何 1 首乐曲", "price":pricelist["VCG7"]
            },
            8:{
            "id":8,"number":"VCG8", "name":"Grade 8", "des":"Any 1 piece from any recognized Graded Exam Syllabus", "des-cn":"任何国际分级考试中的任何 1 首乐曲","price":pricelist["VCG8"]
            },
            9:{
            "id":9,"number":"VCDA", "name":"ARSM, DipABRSM, ATCL", "des":"Any 1 piece from ARSM, DipABRSM, ATCL under 7 minutes", "des-cn":"任何ARSM, DipABRSM, ATCL考试中的任何 1 首7分钟或以下乐曲","price":pricelist["VCDA"]
            },
            10:{
            "id":10,"number":"VCDL", "name":"LRSM, LTCL", "des":"Any 1 piece from LRSM/LTCL under 10 minutes", "des-cn":"任何LRSM/LTCL考试中的任何 1 首10分钟或以下乐曲","price":pricelist["VCDL"]
            },
            11:{
            "id":11,"number":"VCDF", "name":"FRSM,FTCL", "des":"Any 1 piece from FRSM/FTCL under 15 minutes", "des-cn":"任何FRSM/FTCL考试中的任何 1 首15分钟或以下乐曲","price":pricelist["VCDF"]
            },
        
        },       
        4 : {			
            // 1:{
            // "id":1,"number":"VCS1", "name":"Sonata Group", "des":"Any 1 movement under 8 minutes",  "price":80
            // },
            // 2:{
            // "id":2,"number":"S42", "name":"Baroque Period Group", "des":"Any 1 piece less than 10 minutes", "price":700
            // },
            // 3:{
            // "id":3,"number":"S43", "name":"Classical Period Group", "des":"Any 1 piece less than 10 minutes","price":700
            // },
            // 4:{
            // "id":4,"number":"S44", "name":"Romantic Period Group","des":"Any 1 piece less than 10 minutes", "price":700
            // },
            // 5:{
            // "id":5,"number":"S45", "name":"Modern Period Group","des":"Any 1 piece less than 10 minutes", "price":700
            // },
            // 6:{
            // "id":6,"number":"S46", "name":"Etudes","des":"Any 1 etude under 6 minutes", "price":700
            // },
            // 7:{
            // "id":7,"number":"S47", "name":"Concerto", "des":"Any 1 movement under 12 minutes", "price":1000
            // },
            // 8:{
            // "id":8,"number":"S48", "name":"Composition", "des":"1 piece of original composition under 6 minutes", "price":800
            // },
        
        }
    },

    "vocal" :{
        5:{},
        0 : {
            1:{
                "id":1,"number":"VOC1", "name":"Bach - Age 12 or under", "des":"Any 1 piece or movement by/attributed to J.S. Bach under 8 minutes", "des-cn":"任何一首 J.S. Bach 创作， 8 分钟或以下的乐曲或乐章","price":pricelist["VOC1"]
                },
                2:{
                    "id":2,"number":"VOC2", "name":"Bach - Age 12 to 18", "des":"Any 1 piece or movement by/attributed to J.S. Bach under 8 minutes", "des-cn":"任何一首 J.S. Bach 创作， 8 分钟或以下的乐曲或乐章","price":pricelist["VOC2"]
                    },
                3:{
                    "id":3,"number":"VOC3", "name":"Bach - Age 18 or above", "des":"Any 1 piece or movement by/attributed to J.S. Bach under 15 minutes", "des-cn":"任何一首 J.S. Bach 创作， 15 分钟或以下的乐曲或乐章","price":pricelist["VOC3"]
                    },
                4:{
                "id":4,"number":"VOC4", "name":"Mozart - Age 12 or under", "des":"Any 1 piece or movement by W.A. Mozart under 8 minutes", "des-cn":"任何一首 W.A. Mozart 创作， 8 分钟或以下的乐曲或乐章","price":pricelist["VOC4"]
                },
                5:{
                    "id":5,"number":"VOC5", "name":"Mozart - Age 12 to 18", "des":"Any 1 piece or movement by W.A. Mozart under 8 minutes", "des-cn":"任何一首 W.A. Mozart 创作， 8 分钟或以下的乐曲或乐章","price":pricelist["VOC5"]
                    },
                6:{
                    "id":6,"number":"VOC6", "name":"Mozart - Age 18 or above", "des":"Any 1 piece or movement by W.A. Mozart under 15 minutes", "des-cn":"任何一首 W.A. Mozart 创作， 15 分钟或以下的乐曲或乐章","price":pricelist["VOC6"]
                    },
                7:{
                "id":7,"number":"VOC7", "name":"Brahms - Age 12 or under", "des":"Any 1 piece or movement by Johannes Brahms under 8 minutes", "des-cn":"任何一首 Johannes Brahms 创作， 8 分钟或以下的乐曲或乐章","price":pricelist["VOC7"]
                },
                8:{
                    "id":8,"number":"VOC8", "name":"Brahms - Age 12 to 18", "des":"Any 1 piece or movement by Johannes Brahms under 8 minutes", "des-cn":"任何一首 Johannes Brahms 创作， 8 分钟或以下的乐曲或乐章","price":pricelist["VOC8"]
                    },
                9:{
                    "id":9,"number":"VOC9", "name":"Brahms - Age 18 or above", "des":"Any 1 piece or movement by Johannes Brahms under 15 minutes","des-cn":"任何一首 Johannes Brahms 创作， 15 分钟或以下的乐曲或乐章", "price":pricelist["VOC9"]
                    },
                10:{
                "id":10,"number":"VOC10", "name":"Schubert - Age 12 or under", "des":"Any 1 piece or movement by Franz Schubert under 8 minutes","des-cn":"任何一首 Franz Schubert 创作， 8 分钟或以下的乐曲或乐章", "price":pricelist["VOC10"]
                },
                11:{
                    "id":11,"number":"VOC11", "name":"Schubert - Age 12 to 18", "des":"Any 1 piece or movement by Franz Schubert under 8 minutes", "des-cn":"任何一首 Franz Schubert 创作， 8 分钟或以下的乐曲或乐章","price":pricelist["VOC11"]
                    },
                12:{
                    "id":12,"number":"VOC12", "name":"Schubert - Age 18 or above", "des":"Any 1 piece or movement by Franz Schubert under 15 minutes", "des-cn":"任何一首 Franz Schubert 创作， 15 分钟或以下的乐曲或乐章","price":pricelist["VOC12"]
                    }, 
                13:{
                    "id":13,"number":"VOC13", "name":"Schumann - Age 12 or under", "des":"Any 1 piece or movement by Robert Schumann under 8 minutes", "des-cn":"任何一首 Robert Schumann 创作， 8 分钟或以下的乐曲或乐章","price":pricelist["VOC13"]
                    },
                14:{
                    "id":14,"number":"VOC14", "name":"Schumann - Age 12 to 18", "des":"Any 1 piece or movement by Robert Schumann under 8 minutes", "des-cn":"任何一首 Robert Schumann 创作， 8 分钟或以下的乐曲或乐章","price":pricelist["VOC14"]
                    },
                15:{
                    "id":15,"number":"VOC15", "name":"Schumann - Age 18 or above", "des":"Any 1 piece or movement by Robert Schumann under 15 minutes", "des-cn":"任何一首 Robert Schumann 创作， 15 分钟或以下的乐曲或乐章","price":pricelist["VOC15"]
                    }, 
                16:{
                    "id":16,"number":"VOC16", "name":"Fauré - Age 12 or under", "des":"Any 1 piece or movement by Gabriel Fauré under 8 minutes", "des-cn":"任何一首 Gabriel Fauré 创作， 8 分钟或以下的乐曲或乐章","price":pricelist["VOC16"]
                    },
                17:{
                    "id":17,"number":"VOC17", "name":"Fauré - Age 12 to 18", "des":"Any 1 piece or movement by Gabriel Fauré under 8 minutes","des-cn":"任何一首 Gabriel Fauré 创作， 8 分钟或以下的乐曲或乐章", "price":pricelist["VOC17"]
                    },
                18:{
                    "id":18,"number":"VOC18", "name":"Fauré - Age 18 or above", "des":"Any 1 piece or movement by Gabriel Fauré under 15 minutes", "des-cn":"任何一首 Gabriel Fauré 创作， 15 分钟或以下的乐曲或乐章","price":pricelist["VOC18"]
                    }, 
        },
        3 : {
            1:{
                "id":1,"number":"VOA1", "name":"Debut", "des":"7 or below; Any one piece less than 2 minutes", "des-cn":"7岁或以下；任何首少于2分钟的乐目", "price":pricelist["VOA1"]
                },
                2:{
                "id":2,"number":"VOA2", "name":"Junior	", "des":"7 to 9; Any one piece less than 3 minutes",  "des-cn":"7岁至9岁；任何首少于3分钟的乐目","price":pricelist["VOA2"]
                },
                3:{
                "id":3,"number":"VOA3", "name":"Emerging", "des":"9 to 12; Any one piece less than 3 minutes","des-cn":"9岁至12岁；任何首少于3分钟的乐目","price":pricelist["VOA3"]
                },
                4:{
                "id":4,"number":"VOA4", "name":"Youth","des":"12 to 15; Any one piece less than 5 minutes", "des-cn":"12岁至15岁；任何首少于5分钟的乐目", "price":pricelist["VOA4"]
                },
                5:{
                "id":5,"number":"VOA5", "name":"Young Artist","des":"15 to 18; Any one piece less than 6 minutes", "des-cn":"15岁至18岁；任何首少于6分钟的乐目","price":pricelist["VOA5"]
                },
                6:{
                "id":6,"number":"VOA6", "name":"Artist","des":"18 or above; Any one piece less than 12 minutes", "des-cn":"18岁或以上；任何首少于12分钟的乐目","price":pricelist["VOA6"]
                },
        },   
        2 : {	
            // 1:{
            //     "id":1,"number":"VOSMF1", "name":"School Music Festival - Primary 1-2", "des":"Any 1 solo or accompanied piece", "des-cn":"任何一首独奏或伴奏曲目", "price":40
            //     },
            //     2:{
            //     "id":2,"number":"VOSMF2", "name":"School Music Festival - Primary 3-4", "des":"Any 1 solo or accompanied piece", "des-cn":"任何一首独奏或伴奏曲目","price":40
            //     },
            //     3:{
            //     "id":3,"number":"VOSMF3", "name":"School Music Festival - Primary 5-6", "des":"Any 1 solo or accompanied piece","des-cn":"任何一首独奏或伴奏曲目","price":45
            //     },
            //     4:{
            //     "id":4,"number":"VOSMF4", "name":"School Music Festival - Secondary 1-2","des":"Any 1 solo or accompanied piece","des-cn":"任何一首独奏或伴奏曲目", "price":45
            //     },
            //     5:{
            //     "id":5,"number":"VOSMF5", "name":"School Music Festival - Secondary 3-4","des":"Any 1 solo or accompanied piece","des-cn":"任何一首独奏或伴奏曲目", "price":50
            //     },
            //     6:{
            //     "id":6,"number":"VOSMF6", "name":"School Music Festival - Secondary 5-6","des":"Any 1 solo or accompanied piece", "des-cn":"任何一首独奏或伴奏曲目","price":50
            //     },
            //     7:{
            //     "id":7,"number":"VOSMF7", "name":"School Music Festival - Primary Duet","des":"Any 1 duet piece for 2 primary school students","des-cn":"任何一首二人独奏或伴奏曲目 - 小学组", "price":50
            //     },
            //     8:{
            //     "id":8,"number":"VOSMF8", "name":"School Music Festival - Secondary Duet","des":"Any 1 duet piece for 2 primary school students", "des-cn":"任何一首二人独奏或伴奏曲目 - 中学组","price":50
            //     },
            //     9:{
            //     "id":9,"number":"VOSMF9", "name":"School Music Festival - Children's Voice","des":"Any 1 duet piece for contestant age under 7", "des-cn":"任何一首独奏曲目 - 7岁或以下","price":40
            //     },
                // 10:{
                // "id":4,"number":"PF-SMF10", "name":"School Music Festival - Secondary Duet","des":"Any 1 duet piece for 2 secondary school students", "price":90
                // },		
          
        },
        1 : {			
            1:{
            "id":1,"number":"VOG1", "name":"Grade 1", "des":"Any 1 piece from any recognized Graded Exam Syllabus", "des-cn":"任何国际分级考试中的任何 1 首乐曲", "price":pricelist["VOG1"]
            },
            2:{
            "id":2,"number":"VOG2", "name":"Grade 2", "des":"Any 1 piece from any recognized Graded Exam Syllabus", "des-cn":"任何国际分级考试中的任何 1 首乐曲","price":pricelist["VOG2"]
            },
            3:{
            "id":3,"number":"VOG3", "name":"Grade 3", "des":"Any 1 piece from any recognized Graded Exam Syllabus","des-cn":"任何国际分级考试中的任何 1 首乐曲","price":pricelist["VOG3"]
            },
            4:{
            "id":4,"number":"VOG4", "name":"Grade 4","des":"Any 1 piece from any recognized Graded Exam Syllabus","des-cn":"任何国际分级考试中的任何 1 首乐曲", "price":pricelist["VOG4"]
            },
            5:{
            "id":5,"number":"VOG5", "name":"Grade 5","des":"Any 1 piece from any recognized Graded Exam Syllabus", "des-cn":"任何国际分级考试中的任何 1 首乐曲","price":pricelist["VOG5"]
            },
            6:{
            "id":6,"number":"VOG6", "name":"Grade 6","des":"Any 1 piece from any recognized Graded Exam Syllabus","des-cn":"任何国际分级考试中的任何 1 首乐曲", "price":pricelist["VOG6"]
            },
            7:{
            "id":7,"number":"VOG7", "name":"Grade 7", "des":"Any 1 piece from any recognized Graded Exam Syllabus", "des-cn":"任何国际分级考试中的任何 1 首乐曲","price":pricelist["VOG7"]
            },
            8:{
            "id":8,"number":"VOG8", "name":"Grade 8", "des":"Any 1 piece from any recognized Graded Exam Syllabus", "des-cn":"任何国际分级考试中的任何 1 首乐曲","price":pricelist["VOG8"]
            },
            9:{
            "id":9,"number":"VODA", "name":"ARSM, DipABRSM, ATCL", "des":"Any 1 piece from ARSM, DipABRSM, ATCL under 7 minutes","des-cn":"任何ARSM, DipABRSM, ATCL考试中的任何 1 首7分钟或以下乐曲", "price":pricelist["VODA"]
            },
            10:{
            "id":10,"number":"VODL", "name":"LRSM, LTCL", "des":"Any 1 piece from LRSM/LTCL under 10 minutes","des-cn":"任何LRSM/LTCL考试中的任何 1 首10分钟或以下乐曲", "price":pricelist["VODL"]
            },
            11:{
            "id":11,"number":"VODF", "name":"FRSM,FTCL", "des":"Any 1 piece from FRSM/FTCL under 15 minutes","des-cn":"任何FRSM/FTCL考试中的任何 1 首15分钟或以下乐曲", "price":pricelist["VODF"]
            },
        
        },       
        4 : {			
            // 1:{
            // "id":1,"number":"V41", "name":"Composition", "des":"1 piece of original composition under 6 minutes",  "price":800
            // },
            // 2:{
            // "id":2,"number":"V42", "name":"Disney Song Group", "des":"Any 1 song less than 5 minutes", "price":600
            // },
            // 3:{
            // "id":3,"number":"V43", "name":"Bel Canto Group", "des":"Any 1 song less than 6 minutes","price":600
            // },
            // 4:{
            // "id":4,"number":"V44", "name":"Pop Song Group","des":"Any 1 song less than 5 minutes", "price":600
            // },
        }
    },

    "flute" :{
        5:{},
        0 : {
            1:{
                "id":1,"number":"FLC1", "name":"Bach Set Pieces - Age 12 or under", "des":"Any 1 movement of Sonata BWV 1032, BWV 1034 or BWV 1035 by J.S. Bach", "des-cn":"J.S. Bach 奏鸣曲 BWV 1032、BWV 1034 或 BWV 1035 的任何 1 乐章","price":pricelist["FLC1"]
                },
                2:{
                    "id":2,"number":"FLC2", "name":"Bach Set Pieces - Age 12 to 18", "des":"Any 1 movement of Sonata BWV 1032, BWV 1034 or BWV 1035 by J.S. Bach", "des-cn":"J.S. Bach 奏鸣曲 BWV 1032、BWV 1034 或 BWV 1035 的任何 1 乐章","price":pricelist["FLC2"]
                    },
                3:{
                    "id":3,"number":"FLC3", "name":"Bach Set Pieces - Age 18 or above", "des":"Any 1 movement of Sonata BWV 1032, BWV 1034 or BWV 1035 by J.S. Bach", "des-cn":"J.S. Bach 奏鸣曲 BWV 1032、BWV 1034 或 BWV 1035 的任何 1 乐章","price":pricelist["FLC3"]
                    },
                4:{
                "id":4,"number":"FLC4", "name":"Mozart Set Pieces- Age 12 or under", "des":"Any 1 movement of Concerto K313 or K314 by W.A. Mozart", "des-cn":"W.A. 莫扎特协奏曲 K313 或 K314 的任何 1 乐章","price":pricelist["FLC4"]
                },
                5:{
                    "id":5,"number":"FLC5", "name":"Mozart Set Pieces - Age 12 to 18", "des":"Any 1 movement of Concerto K313 or K314 by W.A. Mozart", "des-cn":"W.A. 莫扎特协奏曲 K313 或 K314 的任何 1 乐章","price":pricelist["FLC5"]
                    },
                6:{
                    "id":6,"number":"FLC6", "name":"Mozart Set Pieces - Age 18 or above", "des":"Any 1 movement of Concerto K313 or K314 by W.A. Mozart","des-cn":"W.A. 莫扎特协奏曲 K313 或 K314 的任何 1 乐章", "price":pricelist["FLC6"]
                    },
                7:{
                "id":7,"number":"FLC7", "name":"Fauré Set Pieces- Age 12 or under", "des":"Complete piece of Fantaisie, Op. 79 by Gabriel Fauré","des-cn":"Gabriel Fauré 的幻想曲 Op. 79 - 全曲", "price":pricelist["FLC7"]
                },
                8:{
                    "id":8,"number":"FLC8", "name":"Fauré Set Pieces- Age 12 to 18", "des":"Complete piece of Fantaisie, Op. 79 by Gabriel Fauré", "des-cn":"Gabriel Fauré 的幻想曲 Op. 79 - 全曲","price":pricelist["FLC8"]
                    },
                9:{
                    "id":9,"number":"FLC9", "name":"Fauré Set Pieces- Age 18 or above", "des":"Complete piece of Fantaisie, Op. 79 by Gabriel Fauré", "des-cn":"Gabriel Fauré 的幻想曲 Op. 79 - 全曲","price":pricelist["FLC9"]
                    },
                10:{
                "id":10,"number":"FLC10", "name":"Poulenc Set Pieces- Age 12 or under", "des":"Any 1 movement of Sonata, FP 164 by Francis Poulenc", "des-cn":"Francis Poulenc 奏鸣曲的任何 1 乐章，FP 164","price":pricelist["FLC10"]
                },
                11:{
                    "id":11,"number":"FLC11", "name":"Poulenc Set Pieces - Age 12 to 18", "des":"Any 1 movement of Sonata, FP 164 by Francis Poulenc", "des-cn":"Francis Poulenc 奏鸣曲的任何 1 乐章，FP 164","price":pricelist["FLC11"]
                    },
                12:{
                    "id":12,"number":"FLC12", "name":"Poulenc Set Pieces - Age 18 or above", "des":"Complete piece of Sonata, FP 164 by Francis Poulenc", "des-cn":"Francis Poulenc 奏鸣曲全曲，FP 164","price":pricelist["FLC12"]
                    }, 
        }, 
        3 : {
            1:{
                "id":1,"number":"FLA1", "name":"Debut", "des":"7 or below; Any one piece less than 2 minutes", "des-cn":"7岁或以下；任何首少于2分钟的乐目", "price":pricelist["FLA1"]
                },
                2:{
                "id":2,"number":"FLA2", "name":"Junior", "des":"7 to 9; Any one piece less than 3 minutes",  "des-cn":"7岁至9岁；任何首少于3分钟的乐目","price":pricelist["FLA2"]
                },
                3:{
                "id":3,"number":"FLA3", "name":"Emerging", "des":"9 to 12; Any one piece less than 3 minutes","des-cn":"9岁至12岁；任何首少于3分钟的乐目","price":pricelist["FLA3"]
                },
                4:{
                "id":4,"number":"FLA4", "name":"Youth","des":"12 to 15; Any one piece less than 5 minutes", "des-cn":"12岁至15岁；任何首少于5分钟的乐目", "price":pricelist["FLA4"]
                },
                5:{
                "id":5,"number":"FLA5", "name":"Young Artist","des":"15 to 18; Any one piece less than 6 minutes", "des-cn":"15岁至18岁；任何首少于6分钟的乐目","price":pricelist["FLA5"]
                },
                6:{
                "id":6,"number":"FLA6", "name":"Artist","des":"18 or above; Any one piece less than 12 minutes", "des-cn":"18岁或以上；任何首少于12分钟的乐目","price":pricelist["FLA6"]
                },
        },  
        2 : {			
            // 1:{
            //     "id":1,"number":"FLSMF1", "name":"School Music Festival - Primary 1-2", "des":"Any 1 solo or accompanied piece",  "des-cn":"任何一首独奏或伴奏曲目","price":40
            //     },
            //     2:{
            //     "id":2,"number":"FLSMF2", "name":"School Music Festival - Primary 3-4", "des":"Any 1 solo or accompanied piece", "des-cn":"任何一首独奏或伴奏曲目","price":40
            //     },
            //     3:{
            //     "id":3,"number":"FLSMF3", "name":"School Music Festival - Primary 5-6", "des":"Any 1 solo or accompanied piece","des-cn":"任何一首独奏或伴奏曲目","price":45
            //     },
            //     4:{
            //     "id":4,"number":"FLSMF4", "name":"School Music Festival - Secondary 1-2","des":"Any 1 solo or accompanied piece", "des-cn":"任何一首独奏或伴奏曲目","price":45
            //     },
            //     5:{
            //     "id":5,"number":"FLSMF5", "name":"School Music Festival - Secondary 3-4","des":"Any 1 solo or accompanied piece", "des-cn":"任何一首独奏或伴奏曲目","price":50
            //     },
            //     6:{
            //     "id":6,"number":"FLSMF6", "name":"School Music Festival - Secondary 5-6","des":"Any 1 solo or accompanied piece", "des-cn":"任何一首独奏或伴奏曲目","price":50
            //     },
        },
        1 : {			
            1:{
            "id":1,"number":"FLG1", "name":"Grade 1", "des":"Any 1 piece from any recognized Graded Exam Syllabus",  "des-cn":"任何国际分级考试中的任何 1 首乐曲","price":pricelist["FLG1"]
            },
            2:{
            "id":2,"number":"FLG2", "name":"Grade 2", "des":"Any 1 piece from any recognized Graded Exam Syllabus", "des-cn":"任何国际分级考试中的任何 1 首乐曲","price":pricelist["FLG2"]
            },
            3:{
            "id":3,"number":"FLG3", "name":"Grade 3", "des":"Any 1 piece from any recognized Graded Exam Syllabus","des-cn":"任何国际分级考试中的任何 1 首乐曲","price":pricelist["FLG3"]
            },
            4:{
            "id":4,"number":"FLG4", "name":"Grade 4","des":"Any 1 piece from any recognized Graded Exam Syllabus","des-cn":"任何国际分级考试中的任何 1 首乐曲", "price":pricelist["FLG4"]
            },
            5:{
            "id":5,"number":"FLG5", "name":"Grade 5","des":"Any 1 piece from any recognized Graded Exam Syllabus", "des-cn":"任何国际分级考试中的任何 1 首乐曲","price":pricelist["FLG5"]
            },
            6:{
            "id":6,"number":"FLG6", "name":"Grade 6","des":"Any 1 piece from any recognized Graded Exam Syllabus","des-cn":"任何国际分级考试中的任何 1 首乐曲", "price":pricelist["FLG6"]
            },
            7:{
            "id":7,"number":"FLG7", "name":"Grade 7", "des":"Any 1 piece from any recognized Graded Exam Syllabus", "des-cn":"任何国际分级考试中的任何 1 首乐曲","price":pricelist["FLG7"]
            },
            8:{
            "id":8,"number":"FLG8", "name":"Grade 8", "des":"Any 1 piece from any recognized Graded Exam Syllabus","des-cn":"任何国际分级考试中的任何 1 首乐曲", "price":pricelist["FLG8"]
            },
            9:{
            "id":9,"number":"FLDA", "name":"ARSM, DipABRSM, ATCL", "des":"Any 1 piece from ARSM, DipABRSM, ATCL under 7 minutes","des-cn":"任何ARSM, DipABRSM, ATCL考试中的任何 1 首7分钟或以下乐曲", "price":pricelist["FLDA"]
            },
            10:{
            "id":10,"number":"FLDL", "name":"LRSM, LTCL", "des":"Any 1 piece from LRSM/LTCL under 10 minutes", "des-cn":"任何LRSM/LTCL考试中的任何 1 首10分钟或以下乐曲","price":pricelist["FLDL"]
            },
            11:{
            "id":11,"number":"FLDF", "name":"FRSM,FTCL", "des":"Any 1 piece from FRSM/FTCL under 15 minutes", "des-cn":"任何FRSM/FTCL考试中的任何 1 首15分钟或以下乐曲","price":pricelist["FLDF"]
            },
        
        },       
        4 : {			
            // 1:{
            // "id":1,"number":"FLS1", "name":"Sonata Group", "des":"Any 1 Sonata under 8 minutes",  "price":80
            // },
            // 2:{
            // "id":2,"number":"W42", "name":"Concerto", "des":"Any 1 movement less than 12 minutes", "price":1000
            // },
            // 3:{
            // "id":3,"number":"W43", "name":"Composition", "des":"1 piece of original composition less than 6 minutes","price":800
            // },
        }
    },

    "clarinet" :{
        0 : {
            1:{
                "id":1,"number":"CLC1", "name":"Brahms Set Pieces - Age 12 or under", "des":"Any 1 movement of Sonata 1 or 2, Op.120 by Johannes Brahms", "des-cn":"Johannes Brahms 奏鸣曲 1 或 2 的任何 1 乐章，Op.120","price":pricelist["CLC1"]
                },
                2:{
                    "id":2,"number":"CLC2", "name":"Brahms Set Pieces - Age 12 to 18", "des":"Any 1 movement of Sonata 1 or 2, Op.120 by Johannes Brahms", "des-cn":"Johannes Brahms 奏鸣曲 1 或 2 的任何 1 乐章，Op.120","price":pricelist["CLC2"]
                    },
                3:{
                    "id":3,"number":"CLC3", "name":"Brahms Set Pieces - Age 18 or above", "des":"Any 1 movement of Sonata 1 or 2, Op.120 by Johannes Brahms","des-cn":"Johannes Brahms 奏鸣曲 1 或 2 的任何 1 乐章，Op.120", "price":pricelist["CLC3"]
                    },
                4:{
                "id":4,"number":"CLC4", "name":"Mozart Set Pieces- Age 12 or under", "des":"Any 1 movement of Concerto, K622 by W.A. Mozart", "des-cn":"W.A. 莫扎特协奏曲 K622 的任何 1 乐章","price":pricelist["CLC4"]
                },
                5:{
                    "id":5,"number":"CLC5", "name":"Mozart Set Pieces - Age 12 to 18", "des":"Any 1 movement of Concerto, K622 by W.A. Mozart", "des-cn":"W.A. 莫扎特协奏曲 K622 的任何 1 乐章","price":pricelist["CLC5"]
                    },
                6:{
                    "id":6,"number":"CLC6", "name":"Mozart Set Pieces - Age 18 or above", "des":"Any 1 movement of Concerto, K622 by W.A. Mozart", "des-cn":"W.A. 莫扎特协奏曲 K622 的任何 1 乐章","price":pricelist["CLC6"]
                    },
                7:{
                "id":7,"number":"CLC7", "name":"Poulenc Set Pieces- Age 12 or under", "des":"Any 1 movement of Sonata, FP 184 by Francis Poulenc", "des-cn":"Francis Poulenc 奏鸣曲的任何 1 乐章，FP 184","price":pricelist["CLC7"]
                },
                8:{
                    "id":8,"number":"CLC8", "name":"Poulenc Set Pieces- Age 12 to 18", "des":"Any 1 movement of Sonata, FP 184 by Francis Poulenc", "des-cn":"Francis Poulenc 奏鸣曲的任何 1 乐章，FP 184","price":pricelist["CLC8"]
                    },
                9:{
                    "id":9,"number":"CLC9", "name":"Poulenc Set Pieces- Age 18 or above", "des":"Complete piece of Sonata, FP 184 by Francis Poulenc","des-cn":"Francis Poulenc 奏鸣曲全曲，FP 184", "price":pricelist["CLC9"]
                    },
        }, 
        3 : {
            1:{
                "id":1,"number":"CLA1", "name":"Debut", "des":"7 or below; Any one piece less than 2 minutes", "des-cn":"7岁或以下；任何首少于2分钟的乐目", "price":pricelist["CLA1"]
                },
                2:{
                "id":2,"number":"CLA2", "name":"Junior", "des":"7 to 9; Any one piece less than 3 minutes",  "des-cn":"7岁至9岁；任何首少于3分钟的乐目","price":pricelist["CLA2"]
                },
                3:{
                "id":3,"number":"CLA3", "name":"Emerging", "des":"9 to 12; Any one piece less than 3 minutes","des-cn":"9岁至12岁；任何首少于3分钟的乐目","price":pricelist["CLA3"]
                },
                4:{
                "id":4,"number":"CLA4", "name":"Youth","des":"12 to 15; Any one piece less than 5 minutes", "des-cn":"12岁至15岁；任何首少于5分钟的乐目", "price":pricelist["CLA4"]
                },
                5:{
                "id":5,"number":"CLA5", "name":"Young Artist","des":"15 to 18; Any one piece less than 6 minutes", "des-cn":"15岁至18岁；任何首少于6分钟的乐目","price":pricelist["CLA5"]
                },
                6:{
                "id":6,"number":"CLA6", "name":"Artist","des":"18 or above; Any one piece less than 12 minutes", "des-cn":"18岁或以上；任何首少于12分钟的乐目","price":pricelist["CLA6"]
                },
        },  
        2 : {			
            // 1:{
            //     "id":1,"number":"CLSMF1", "name":"School Music Festival - Primary 1-2", "des":"Any 1 solo or accompanied piece", "des-cn":"任何一首独奏或伴奏曲目", "price":40
            //     },
            //     2:{
            //     "id":2,"number":"CLSMF2", "name":"School Music Festival - Primary 3-4", "des":"Any 1 solo or accompanied piece", "des-cn":"任何一首独奏或伴奏曲目","price":40
            //     },
            //     3:{
            //     "id":3,"number":"CLSMF3", "name":"School Music Festival - Primary 5-6", "des":"Any 1 solo or accompanied piece","des-cn":"任何一首独奏或伴奏曲目","price":45
            //     },
            //     4:{
            //     "id":4,"number":"CLSMF4", "name":"School Music Festival - Secondary 1-2","des":"Any 1 solo or accompanied piece","des-cn":"任何一首独奏或伴奏曲目", "price":45
            //     },
            //     5:{
            //     "id":5,"number":"CLSMF5", "name":"School Music Festival - Secondary 3-4","des":"Any 1 solo or accompanied piece","des-cn":"任何一首独奏或伴奏曲目", "price":50
            //     },
            //     6:{
            //     "id":6,"number":"CLSMF6", "name":"School Music Festival - Secondary 5-6","des":"Any 1 solo or accompanied piece","des-cn":"任何一首独奏或伴奏曲目", "price":50
            //     },
        },
        1 : {			
            1:{
            "id":1,"number":"CLG1", "name":"Grade 1", "des":"Any 1 piece from any recognized Graded Exam Syllabus", "des-cn":"任何国际分级考试中的任何 1 首乐曲", "price":pricelist["CLG1"]
            },
            2:{
            "id":2,"number":"CLG2", "name":"Grade 2", "des":"Any 1 piece from any recognized Graded Exam Syllabus", "des-cn":"任何国际分级考试中的任何 1 首乐曲","price":pricelist["CLG2"]
            },
            3:{
            "id":3,"number":"CLG3", "name":"Grade 3", "des":"Any 1 piece from any recognized Graded Exam Syllabus","des-cn":"任何国际分级考试中的任何 1 首乐曲","price":pricelist["CLG3"]
            },
            4:{
            "id":4,"number":"CLG4", "name":"Grade 4","des":"Any 1 piece from any recognized Graded Exam Syllabus", "des-cn":"任何国际分级考试中的任何 1 首乐曲","price":pricelist["CLG4"]
            },
            5:{
            "id":5,"number":"CLG5", "name":"Grade 5","des":"Any 1 piece from any recognized Graded Exam Syllabus", "des-cn":"任何国际分级考试中的任何 1 首乐曲","price":pricelist["CLG5"]
            },
            6:{
            "id":6,"number":"CLG6", "name":"Grade 6","des":"Any 1 piece from any recognized Graded Exam Syllabus", "des-cn":"任何国际分级考试中的任何 1 首乐曲","price":pricelist["CLG6"]
            },
            7:{
            "id":7,"number":"CLG7", "name":"Grade 7", "des":"Any 1 piece from any recognized Graded Exam Syllabus", "des-cn":"任何国际分级考试中的任何 1 首乐曲","price":pricelist["CLG7"]
            },
            8:{
            "id":8,"number":"CLG8", "name":"Grade 8", "des":"Any 1 piece from any recognized Graded Exam Syllabus", "des-cn":"任何国际分级考试中的任何 1 首乐曲","price":pricelist["CLG8"]
            },
            9:{
            "id":9,"number":"CLDA", "name":"ARSM, DipABRSM, ATCL", "des":"Any 1 piece from ARSM, DipABRSM, ATCL under 7 minutes", "des-cn":"任何ARSM, DipABRSM, ATCL考试中的任何 1 首7分钟或以下乐曲","price":pricelist["CLDA"]
            },
            10:{
            "id":10,"number":"CLDL", "name":"LRSM, LTCL", "des":"Any 1 piece from LRSM/LTCL under 10 minutes", "des-cn":"任何LRSM/LTCL考试中的任何 1 首10分钟或以下乐曲","price":pricelist["CLDL"]
            },
            11:{
            "id":11,"number":"CLDF", "name":"FRSM,FTCL", "des":"Any 1 piece from FRSM/FTCL under 15 minutes", "des-cn":"任何FRSM/FTCL考试中的任何 1 首15分钟或以下乐曲","price":pricelist["CLDF"]
            },
        
        },       
        4 : {			
            // 1:{
            // "id":1,"number":"CLS1", "name":"Sonata Group", "des":"Any 1 Sonata under 8 minutes",  "price":80
            // },
            // 2:{
            // "id":2,"number":"W42", "name":"Concerto", "des":"Any 1 movement less than 12 minutes", "price":1000
            // },
            // 3:{
            // "id":3,"number":"W43", "name":"Composition", "des":"1 piece of original composition less than 6 minutes","price":800
            // },
        },
        5:{
            1:{
                "id":1,"number":"CLQ1", "name":"3 Fantasiestücke Op.28 by Prof. Manrique", "des":"Complete Piece", "des-cn":"全首乐曲","price":pricelist["CLQ1"],"link":"3_Fantasiestücke_Op.28a.pdf"
            },
        }
    },

    "trumpet" :{
        5:{},
        0 : {
            1:{
                "id":1,"number":"TRC1", "name":"Haydn Set Pieces - Age 12 or under", "des":"Any 1 movement of Concerto, Hob. VIIe/1 by Joseph Haydn","des-cn":"Joseph Haydn Hob. VIIe/1 协奏曲的任何 1 乐章", "price":50
                },
                2:{
                    "id":2,"number":"TRC2", "name":"Haydn Set Pieces - Age 12 to 18", "des":"Any 1 movement of Concerto, Hob. VIIe/1 by Joseph Haydn", "des-cn":"Joseph Haydn Hob. VIIe/1 协奏曲的任何 1 乐章","price":60
                    },
                3:{
                    "id":3,"number":"TRC3", "name":"Haydn Set Pieces - Age 18 or above", "des":"Any 1 movement of Concerto, Hob. VIIe/1 by Joseph Haydn", "des-cn":"Joseph Haydn Hob. VIIe/1 协奏曲的任何 1 乐章","price":70
                    },
                4:{
                "id":4,"number":"TRC4", "name":"Vizzutti Set Pieces- Age 12 or under", "des":"Complete Piece of Polka by Allen Vizzutti", "des-cn":"Allen Vizzutti - Polka 全曲","price":50
                },
                5:{
                    "id":5,"number":"TRC5", "name":"Vizzutti Set Pieces - Age 12 to 18", "des":"Complete Piece of Polka by Allen Vizzutti","des-cn":"Allen Vizzutti - Polka 全曲", "price":60
                    },
                6:{
                    "id":6,"number":"TRC6", "name":"Vizzutti Set Pieces - Age 18 or above", "des":"Complete Piece of Polka by Allen Vizzutti","des-cn":"Allen Vizzutti - Polka 全曲", "price":70
                    },
                7:{
                "id":7,"number":"TRC7", "name":"Hubeau Set Pieces- Age 12 or under", "des":"Complete Piece of Spirtual by Jean Hubeau", "des-cn":"Jean Hubeau - Spirtual 全曲","price":50
                },
                8:{
                    "id":8,"number":"TRC8", "name":"Hubeau Set Pieces- Age 12 to 18", "des":"Complete Piece of Spirtual by Jean Hubeau", "des-cn":"Jean Hubeau - Spirtual 全曲","price":60
                    },
                9:{
                    "id":9,"number":"TRC9", "name":"Hubeau Set Pieces- Age 18 or above", "des":"Complete Piece of Spirtual by Jean Hubeau", "des-cn":"Jean Hubeau - Spirtual 全曲","price":70
                    },
        }, 
        3 : {
            1:{
                "id":1,"number":"TRA1", "name":"Debut", "des":"7 or below; Any one piece less than 2 minutes", "des-cn":"7岁或以下；任何首少于2分钟的乐目", "price":50
                },
                2:{
                "id":2,"number":"TRA2", "name":"Junior", "des":"7 to 9; Any one piece less than 3 minutes",  "des-cn":"7岁至9岁；任何首少于3分钟的乐目","price":50
                },
                3:{
                "id":3,"number":"TRA3", "name":"Emerging", "des":"9 to 12; Any one piece less than 3 minutes","des-cn":"9岁至12岁；任何首少于3分钟的乐目","price":60
                },
                4:{
                "id":4,"number":"TRA4", "name":"Youth","des":"12 to 15; Any one piece less than 5 minutes","des-cn":"12岁至15岁；任何首少于5分钟的乐目",  "price":60
                },
                5:{
                "id":5,"number":"TRA5", "name":"Young Artist","des":"15 to 18; Any one piece less than 6 minutes","des-cn":"15岁至18岁；任何首少于6分钟的乐目", "price":70
                },
                6:{
                "id":6,"number":"TRA6", "name":"Artist","des":"18 or above; Any one piece less than 12 minutes", "des-cn":"18岁或以上；任何首少于12分钟的乐目","price":80
                },
        },  
        2 : {			
            // 1:{
            //     "id":1,"number":"TRSMF1", "name":"School Music Festival - Primary 1-2", "des":"Any 1 solo or accompanied piece", "des-cn":"任何一首独奏或伴奏曲目", "price":40
            //     },
            //     2:{
            //     "id":2,"number":"TRSMF2", "name":"School Music Festival - Primary 3-4", "des":"Any 1 solo or accompanied piece", "des-cn":"任何一首独奏或伴奏曲目","price":40
            //     },
            //     3:{
            //     "id":3,"number":"TRSMF3", "name":"School Music Festival - Primary 5-6", "des":"Any 1 solo or accompanied piece","des-cn":"任何一首独奏或伴奏曲目","price":45
            //     },
            //     4:{
            //     "id":4,"number":"TRSMF4", "name":"School Music Festival - Secondary 1-2","des":"Any 1 solo or accompanied piece","des-cn":"任何一首独奏或伴奏曲目", "price":45
            //     },
            //     5:{
            //     "id":5,"number":"TRSMF5", "name":"School Music Festival - Secondary 3-4","des":"Any 1 solo or accompanied piece","des-cn":"任何一首独奏或伴奏曲目", "price":50
            //     },
            //     6:{
            //     "id":6,"number":"TRSMF6", "name":"School Music Festival - Secondary 5-6","des":"Any 1 solo or accompanied piece", "des-cn":"任何一首独奏或伴奏曲目","price":50
            //     },
        },
        1 : {			
            1:{
            "id":1,"number":"TRG1", "name":"Grade 1", "des":"Any 1 piece from any recognized Graded Exam Syllabus", "des-cn":"任何国际分级考试中的任何 1 首乐曲", "price":45
            },
            2:{
            "id":2,"number":"TRG2", "name":"Grade 2", "des":"Any 1 piece from any recognized Graded Exam Syllabus","des-cn":"任何国际分级考试中的任何 1 首乐曲", "price":45
            },
            3:{
            "id":3,"number":"TRG3", "name":"Grade 3", "des":"Any 1 piece from any recognized Graded Exam Syllabus","des-cn":"任何国际分级考试中的任何 1 首乐曲","price":50
            },
            4:{
            "id":4,"number":"TRG4", "name":"Grade 4","des":"Any 1 piece from any recognized Graded Exam Syllabus","des-cn":"任何国际分级考试中的任何 1 首乐曲", "price":50
            },
            5:{
            "id":5,"number":"TRG5", "name":"Grade 5","des":"Any 1 piece from any recognized Graded Exam Syllabus", "des-cn":"任何国际分级考试中的任何 1 首乐曲","price":55
            },
            6:{
            "id":6,"number":"TRG6", "name":"Grade 6","des":"Any 1 piece from any recognized Graded Exam Syllabus","des-cn":"任何国际分级考试中的任何 1 首乐曲", "price":55
            },
            7:{
            "id":7,"number":"TRG7", "name":"Grade 7", "des":"Any 1 piece from any recognized Graded Exam Syllabus", "des-cn":"任何国际分级考试中的任何 1 首乐曲","price":60
            },
            8:{
            "id":8,"number":"TRG8", "name":"Grade 8", "des":"Any 1 piece from any recognized Graded Exam Syllabus", "des-cn":"任何国际分级考试中的任何 1 首乐曲","price":60
            },
            9:{
            "id":9,"number":"TRDA", "name":"ARSM, DipABRSM, ATCL", "des":"Any 1 piece from ARSM, DipABRSM, ATCL under 7 minutes", "des-cn":"任何ARSM, DipABRSM, ATCL考试中的任何 1 首7分钟或以下乐曲","price":70
            },
            10:{
            "id":10,"number":"TRDL", "name":"LRSM, LTCL", "des":"Any 1 piece from LRSM/LTCL under 10 minutes","des-cn":"任何LRSM/LTCL考试中的任何 1 首10分钟或以下乐曲", "price":80
            },
            11:{
            "id":11,"number":"TRDF", "name":"FRSM,FTCL", "des":"Any 1 piece from FRSM/FTCL under 15 minutes","des-cn":"任何FRSM/FTCL考试中的任何 1 首15分钟或以下乐曲", "price":90
            },
        
        },       
        4 : {			
            // 1:{
            // "id":1,"number":"TRS1", "name":"Sonata Group", "des":"Any 1 Sonata under 8 minutes",  "price":80
            // },
            // 2:{
            // "id":2,"number":"W42", "name":"Concerto", "des":"Any 1 movement less than 12 minutes", "price":1000
            // },
            // 3:{
            // "id":3,"number":"W43", "name":"Composition", "des":"1 piece of original composition less than 6 minutes","price":800
            // },
        }
    },

    "trombone" :{
        5:{},
        0 : {
            1:{
                "id":1,"number":"TBC1", "name":"Guilmant Set Pieces - Age 12 or under", "des":"Complete piece of Morceau Symphonique, Op.88 by Alexandre Guilmant", "des-cn":"Alexandre Guilmant - Morceau Symphonique, Op.88 全曲","price":50
                },
                2:{
                    "id":2,"number":"TBC2", "name":"Guilmant Set Pieces - Age 12 to 18", "des":"Complete piece of Morceau Symphonique, Op.88 by Alexandre Guilmant", "des-cn":"Alexandre Guilmant - Morceau Symphonique, Op.88 全曲","price":60
                    },
                3:{
                    "id":3,"number":"TBC3", "name":"Guilmant Set Pieces - Age 18 or above", "des":"Complete piece of Morceau Symphonique, Op.88 by Alexandre Guilmant", "des-cn":"Alexandre Guilmant - Morceau Symphonique, Op.88 全曲","price":70
                    },
                4:{
                "id":4,"number":"TBC4", "name":"David Set Pieces- Age 12 or under", "des":"Complete 2nd movement - Marcia Funebre from Concertino Op.4 by Ferdinand David", "des-cn":"Ferdinand David - Marcia Funebre from Concertino Op.4 全曲","price":50
                },
                5:{
                    "id":5,"number":"TBC5", "name":"David Set Pieces - Age 12 to 18", "des":"Complete 2nd movement - Marcia Funebre from Concertino Op.4 by Ferdinand David", "des-cn":"Ferdinand David - Marcia Funebre from Concertino Op.4 全曲","price":60
                    },
                6:{
                    "id":6,"number":"TBC6", "name":"David Set Pieces - Age 18 or above", "des":"Complete 2nd movement - Marcia Funebre from Concertino Op.4 by Ferdinand David","des-cn":"Ferdinand David - Marcia Funebre from Concertino Op.4 全曲","price":70
                    },
                7:{
                "id":7,"number":"TBC7", "name":"Weber Set Pieces- Age 12 or under", "des":"Complete Piece of Romance for Trombone and Piano by Carl Maria von Weber", "des-cn":"Carl Maria von Weber - Romance for Trombone and Piano 全曲","price":50
                },
                8:{
                    "id":8,"number":"TBC8", "name":"Weber Set Pieces- Age 12 to 18", "des":"Complete Piece of Romance for Trombone and Piano by Carl Maria von Weber","des-cn":"Carl Maria von Weber - Romance for Trombone and Piano 全曲", "price":60
                    },
                9:{
                    "id":9,"number":"TBC9", "name":"Weber Set Pieces- Age 18 or above", "des":"Complete Piece of Romance for Trombone and Piano by Carl Maria von Weber","des-cn":"Carl Maria von Weber - Romance for Trombone and Piano 全曲", "price":70
                    },
        }, 
        3 : {
            1:{
                "id":1,"number":"TBA1", "name":"Debut", "des":"7 or below; Any one piece less than 2 minutes", "des-cn":"7岁或以下；任何首少于2分钟的乐目", "price":50
                },
                2:{
                "id":2,"number":"TBA2", "name":"Junior", "des":"7 to 9; Any one piece less than 3 minutes","des-cn":"7岁至9岁；任何首少于3分钟的乐目", "price":50
                },
                3:{
                "id":3,"number":"TBA3", "name":"Emerging", "des":"9 to 12; Any one piece less than 3 minutes","des-cn":"9岁至12岁；任何首少于3分钟的乐目","price":60
                },
                4:{
                "id":4,"number":"TBA4", "name":"Youth","des":"12 to 15; Any one piece less than 5 minutes","des-cn":"12岁至15岁；任何首少于5分钟的乐目",  "price":60
                },
                5:{
                "id":5,"number":"TBA5", "name":"Young Artist","des":"15 to 18; Any one piece less than 6 minutes", "des-cn":"15岁至18岁；任何首少于6分钟的乐目","price":70
                },
                6:{
                "id":6,"number":"TBA6", "name":"Artist","des":"18 or above; Any one piece less than 12 minutes", "des-cn":"18岁或以上；任何首少于12分钟的乐目","price":80
                },
        },  
        2 : {			
            // 1:{
            //     "id":1,"number":"TBSMF1", "name":"School Music Festival - Primary 1-2", "des":"Any 1 solo or accompanied piece", "des-cn":"任何一首独奏或伴奏曲目", "price":40
            //     },
            //     2:{
            //     "id":2,"number":"TBSMF2", "name":"School Music Festival - Primary 3-4", "des":"Any 1 solo or accompanied piece","des-cn":"任何一首独奏或伴奏曲目", "price":40
            //     },
            //     3:{
            //     "id":3,"number":"TBSMF3", "name":"School Music Festival - Primary 5-6", "des":"Any 1 solo or accompanied piece","des-cn":"任何一首独奏或伴奏曲目","price":45
            //     },
            //     4:{
            //     "id":4,"number":"TBSMF4", "name":"School Music Festival - Secondary 1-2","des":"Any 1 solo or accompanied piece", "des-cn":"任何一首独奏或伴奏曲目","price":45
            //     },
            //     5:{
            //     "id":5,"number":"TBSMF5", "name":"School Music Festival - Secondary 3-4","des":"Any 1 solo or accompanied piece", "des-cn":"任何一首独奏或伴奏曲目","price":50
            //     },
            //     6:{
            //     "id":6,"number":"TBSMF6", "name":"School Music Festival - Secondary 5-6","des":"Any 1 solo or accompanied piece", "des-cn":"任何一首独奏或伴奏曲目","price":50
            //     },
        },
        1 : {			
            1:{
            "id":1,"number":"TBG1", "name":"Grade 1", "des":"Any 1 piece from any recognized Graded Exam Syllabus",  "des-cn":"任何国际分级考试中的任何 1 首乐曲","price":45
            },
            2:{
            "id":2,"number":"TBG2", "name":"Grade 2", "des":"Any 1 piece from any recognized Graded Exam Syllabus", "des-cn":"任何国际分级考试中的任何 1 首乐曲","price":45
            },
            3:{
            "id":3,"number":"TBG3", "name":"Grade 3", "des":"Any 1 piece from any recognized Graded Exam Syllabus","des-cn":"任何国际分级考试中的任何 1 首乐曲","price":50
            },
            4:{
            "id":4,"number":"TBG4", "name":"Grade 4","des":"Any 1 piece from any recognized Graded Exam Syllabus","des-cn":"任何国际分级考试中的任何 1 首乐曲", "price":50
            },
            5:{
            "id":5,"number":"TBG5", "name":"Grade 5","des":"Any 1 piece from any recognized Graded Exam Syllabus", "des-cn":"任何国际分级考试中的任何 1 首乐曲","price":55
            },
            6:{
            "id":6,"number":"TBG6", "name":"Grade 6","des":"Any 1 piece from any recognized Graded Exam Syllabus","des-cn":"任何国际分级考试中的任何 1 首乐曲", "price":55
            },
            7:{
            "id":7,"number":"TBG7", "name":"Grade 7", "des":"Any 1 piece from any recognized Graded Exam Syllabus", "des-cn":"任何国际分级考试中的任何 1 首乐曲","price":60
            },
            8:{
            "id":8,"number":"TBG8", "name":"Grade 8", "des":"Any 1 piece from any recognized Graded Exam Syllabus", "des-cn":"任何国际分级考试中的任何 1 首乐曲","price":60
            },
            9:{
            "id":9,"number":"TBDA", "name":"ARSM, DipABRSM, ATCL", "des":"Any 1 piece from ARSM, DipABRSM, ATCL under 7 minutes", "des-cn":"任何ARSM, DipABRSM, ATCL考试中的任何 1 首7分钟或以下乐曲","price":70
            },
            10:{
            "id":10,"number":"TBDL", "name":"LRSM, LTCL", "des":"Any 1 piece from LRSM/LTCL under 10 minutes", "des-cn":"任何LRSM/LTCL考试中的任何 1 首10分钟或以下乐曲","price":80
            },
            11:{
            "id":11,"number":"TBDF", "name":"FRSM,FTCL", "des":"Any 1 piece from FRSM/FTCL under 15 minutes", "des-cn":"任何FRSM/FTCL考试中的任何 1 首15分钟或以下乐曲","price":90
            },
        
        },       
        4 : {			
            // 1:{
            // "id":1,"number":"TBS1", "name":"Sonata Group", "des":"Any 1 Sonata under 8 minutes",  "price":80
            // },
            // 2:{
            // "id":2,"number":"W42", "name":"Concerto", "des":"Any 1 movement less than 12 minutes", "price":1000
            // },
            // 3:{
            // "id":3,"number":"W43", "name":"Composition", "des":"1 piece of original composition less than 6 minutes","price":800
            // },
        }
    },

    "harp":{
        5:{},
        0 : {
            1:{
                "id":1,"number":"HPC1", "name":"Handel Set Pieces - Age 12 or under", "des":"Any 1 movement from Concerto, HWV 294 by George Frideric Handel", "des-cn":"George Frideric Handel 协奏曲 HWV 294 中的任何 1 乐章","price":50
                },
                2:{
                    "id":2,"number":"HPC2", "name":"Handel Set Pieces - Age 12 to 18", "des":"Any 1 movement from Concerto, HWV 294 by George Frideric Handel", "des-cn":"George Frideric Handel 协奏曲 HWV 294 中的任何 1 乐章","price":60
                    },
                3:{
                    "id":3,"number":"HPC3", "name":"Handel Set Pieces - Age 18 or above", "des":"Complete piece of Concerto HWV 294 by George Frideric Handel", "des-cn":"George Frideric Handel 协奏曲 HWV 294 全曲","price":70
                    },
                4:{
                "id":4,"number":"HPC4", "name":"Dizi Set Pieces- Age 12 or under", "des":"Any 1 complete etude from 48 Etudes for Harp by François Joseph Dizi", "des-cn":"François Joseph Dizi 的 48 首竖琴练习曲中的任何 1 首完整练习曲","price":50
                },
                5:{
                    "id":5,"number":"HPC5", "name":"Dizi Set Pieces - Age 12 to 18", "des":"Any 1 complete etude from 48 Etudes for Harp by François Joseph Dizi", "des-cn":"François Joseph Dizi 的 48 首竖琴练习曲中的任何 1 首完整练习曲","price":60
                    },
                6:{
                    "id":6,"number":"HPC6", "name":"Dizi Set Pieces - Age 18 or above", "des":"Any 1 complete etude from 48 Etudes for Harp by François Joseph Dizi", "des-cn":"François Joseph Dizi 的 48 首竖琴练习曲中的任何 1 首完整练习曲","price":70
                    },
                7:{
                "id":7,"number":"HPC7", "name":"Bach - Age 12 or under", "des":"Any 1 piece or arrangement by/attributed to J.S. Bach under 8 minutes", "des-cn":"任何 1 首J.S.Bach创作，8分钟以下的曲目","price":50
                },
                8:{
                    "id":8,"number":"HPC8", "name":"Bach - Age 12 to 18", "des":"Any 1 piece or arrangement by/attributed to J.S. Bach under 8 minutes", "des-cn":"任何 1 首J.S.Bach创作，8分钟以下的曲目","price":60
                    },
                9:{
                    "id":9,"number":"HPC9", "name":"Bach - Age 18 or above", "des":"Any 1 piece or arrangement by/attributed to J.S. Bach under 15 minutes", "des-cn":"任何 1 首J.S.Bach创作，15分钟以下的曲目","price":70
                    },
        }, 
        3 : {
            1:{
                "id":1,"number":"HPA1", "name":"Debut", "des":"7 or below; Any one piece less than 2 minutes", "des-cn":"7岁或以下；任何首少于2分钟的乐目", "price":50
                },
                2:{
                "id":2,"number":"HPA2", "name":"Junior	", "des":"7 to 9; Any one piece less than 3 minutes",  "des-cn":"7岁至9岁；任何首少于3分钟的乐目","price":50
                },
                3:{
                "id":3,"number":"HPA3", "name":"Emerging", "des":"9 to 12; Any one piece less than 3 minutes","des-cn":"9岁至12岁；任何首少于3分钟的乐目","price":60
                },
                4:{
                "id":4,"number":"HPA4", "name":"Youth","des":"12 to 15; Any one piece less than 5 minutes", "des-cn":"12岁至15岁；任何首少于5分钟的乐目", "price":60
                },
                5:{
                "id":5,"number":"HPA5", "name":"Young Artist","des":"15 to 18; Any one piece less than 6 minutes", "des-cn":"15岁至18岁；任何首少于6分钟的乐目","price":70
                },
                6:{
                "id":6,"number":"HPA6", "name":"Artist","des":"18 or above; Any one piece less than 12 minutes", "des-cn":"18岁或以上；任何首少于12分钟的乐目","price":80
                },
        },  
        2 : {			   
        },
        1 : {			
            1:{
            "id":1,"number":"HPG1", "name":"Grade 1", "des":"Any 1 piece from any recognized Graded Exam Syllabus", "des-cn":"任何国际分级考试中的任何 1 首乐曲", "price":45
            },
            2:{
            "id":2,"number":"HPG2", "name":"Grade 2", "des":"Any 1 piece from any recognized Graded Exam Syllabus", "des-cn":"任何国际分级考试中的任何 1 首乐曲","price":45
            },
            3:{
            "id":3,"number":"HPG3", "name":"Grade 3", "des":"Any 1 piece from any recognized Graded Exam Syllabus","des-cn":"任何国际分级考试中的任何 1 首乐曲","price":50
            },
            4:{
            "id":4,"number":"HPG4", "name":"Grade 4","des":"Any 1 piece from any recognized Graded Exam Syllabus", "des-cn":"任何国际分级考试中的任何 1 首乐曲","price":50
            },
            5:{
            "id":5,"number":"HPG5", "name":"Grade 5","des":"Any 1 piece from any recognized Graded Exam Syllabus", "des-cn":"任何国际分级考试中的任何 1 首乐曲","price":55
            },
            6:{
            "id":6,"number":"HPG6", "name":"Grade 6","des":"Any 1 piece from any recognized Graded Exam Syllabus", "des-cn":"任何国际分级考试中的任何 1 首乐曲","price":55
            },
            7:{
            "id":7,"number":"HPG7", "name":"Grade 7", "des":"Any 1 piece from any recognized Graded Exam Syllabus", "des-cn":"任何国际分级考试中的任何 1 首乐曲","price":60
            },
            8:{
            "id":8,"number":"HPG8", "name":"Grade 8", "des":"Any 1 piece from any recognized Graded Exam Syllabus", "des-cn":"任何国际分级考试中的任何 1 首乐曲","price":60
            },
            9:{
            "id":9,"number":"HPDA", "name":"ARSM, DipABRSM, ATCL", "des":"Any 1 piece from ARSM, DipABRSM, ATCL under 7 minutes", "des-cn":"任何ARSM, DipABRSM, ATCL考试中的任何 1 首7分钟或以下乐曲","price":70
            },
            10:{
            "id":10,"number":"HPDL", "name":"LRSM, LTCL", "des":"Any 1 piece from LRSM/LTCL under 10 minutes", "des-cn":"任何LRSM/LTCL考试中的任何 1 首10分钟或以下乐曲","price":80
            },
            11:{
            "id":11,"number":"HPDF", "name":"FRSM,FTCL", "des":"Any 1 piece from FRSM/FTCL under 15 minutes", "des-cn":"任何FRSM/FTCL考试中的任何 1 首15分钟或以下乐曲","price":90
            },
        
        },       
        4 : {			
            // 1:{
            // "id":1,"number":"H41", "name":"Etudes", "des":"Any 1 etude/study under 6 minutes",  "price":700
            // },
            // 2:{
            // "id":2,"number":"H42", "name":"Concerto", "des":"Any 1 movement less than 12 minutes", "price":1000
            // },
            // 3:{
            // "id":3,"number":"H43", "name":"Composition", "des":"1 piece of original composition less than 6 minutes","price":800
            // },
        }
    },

    "guitar" :{
        5:{},
        0 : {
            1:{
                "id":1,"number":"GTC1", "name":"Tárrega - Age 12 or under", "des":"Any 1 piece or movement by Francisco Tárrega under 8 minutes", "des-cn":"Francisco Tárrega 在 8 分钟内的任何 1 首曲目或乐章", "price":60
                },
                2:{
                    "id":2,"number":"GTC2", "name":"Tárrega - Age 12 to 18", "des":"Any 1 piece or movement by Francisco Tárrega under 8 minutes", "des-cn":"Francisco Tárrega 在 8 分钟内的任何 1 首曲目或乐章","price":70
                    },
                3:{
                    "id":3,"number":"GTC3", "name":"Tárrega - Age 18 or above", "des":"Any 1 piece or movement by Francisco Tárrega under 15 minutes", "des-cn":"Francisco Tárrega 在 15 分钟内的任何 1 首曲目或乐章","price":80
                    },
                4:{
                "id":4,"number":"GTC4", "name":"Weiss - Age 12 or under", "des":"Any 1 piece or movement by Sylvius Leopold Weiss under 8 minutes","des-cn":"Sylvius Leopold Weiss 在 8 分钟内的任何 1 首曲目或乐章", "price":60
                },
                5:{
                    "id":5,"number":"GTC5", "name":"Weiss - Age 12 to 18", "des":"Any 1 piece or movement by Sylvius Leopold Weiss under 8 minutes", "des-cn":"Sylvius Leopold Weiss 在 8 分钟内的任何 1 首曲目或乐章","price":70
                    },
                6:{
                    "id":6,"number":"GTC6", "name":"Weiss - Age 18 or above", "des":"Any 1 piece or movement by Sylvius Leopold Weiss under 15 minutes", "des-cn":"Sylvius Leopold Weiss 在 15 分钟内的任何 1 首曲目或乐章","price":80
                    },
                7:{
                "id":7,"number":"GTC7", "name":"Bach - Age 12 or under", "des":"Any 1 piece or arrangement by/attributed to J.S. Bach under 8 minutes", "des-cn":"J.S. Bach 在 8 分钟内的任何 1 首曲目或乐章","price":60
                },
                8:{
                    "id":8,"number":"GTC8", "name":"Bach - Age 12 to 18", "des":"Any 1 piece or arrangement by/attributed to J.S. Bach under 8 minutes","des-cn":"J.S. Bach 在 8 分钟内的任何 1 首曲目或乐章", "price":70
                    },
                9:{
                    "id":9,"number":"GTC9", "name":"Bach - Age 18 or above", "des":"Any 1 piece or arrangement by/attributed to J.S. Bach under 15 minutes","des-cn":"J.S. Bach 在 15 分钟内的任何 1 首曲目或乐章", "price":80
                    },
        }, 
        3 : {
            1:{
                "id":1,"number":"GTA1", "name":"Debut", "des":"7 or below; Any one piece less than 2 minutes", "des-cn":"7岁或以下；任何首少于2分钟的乐目", "price":50
                },
                2:{
                "id":2,"number":"GTA2", "name":"Junior", "des":"7 to 9; Any one piece less than 3 minutes",  "des-cn":"7岁至9岁；任何首少于3分钟的乐目","price":50
                },
                3:{
                "id":3,"number":"GTA3", "name":"Emerging", "des":"9 to 12; Any one piece less than 3 minutes","des-cn":"9岁至12岁；任何首少于3分钟的乐目","price":60
                },
                4:{
                "id":4,"number":"GTA4", "name":"Youth","des":"12 to 15; Any one piece less than 5 minutes","des-cn":"12岁至15岁；任何首少于5分钟的乐目",  "price":60
                },
                5:{
                "id":5,"number":"GTA5", "name":"Young Artist","des":"15 to 18; Any one piece less than 6 minutes", "des-cn":"15岁至18岁；任何首少于6分钟的乐目","price":70
                },
                6:{
                "id":6,"number":"GTA6", "name":"Artist","des":"18 or above; Any one piece less than 12 minutes", "des-cn":"18岁或以上；任何首少于12分钟的乐目","price":80
                },
        },  
        2 : {			
            // 1:{
            //     "id":1,"number":"GTSMF1", "name":"School Music Festival - Primary 1-2", "des":"Any 1 solo or accompanied piece", "des-cn":"任何一首独奏或伴奏曲目", "price":40
            //     },
            //     2:{
            //     "id":2,"number":"GTSMF2", "name":"School Music Festival - Primary 3-4", "des":"Any 1 solo or accompanied piece", "des-cn":"任何一首独奏或伴奏曲目","price":40
            //     },
            //     3:{
            //     "id":3,"number":"GTSMF3", "name":"School Music Festival - Primary 5-6", "des":"Any 1 solo or accompanied piece","des-cn":"任何一首独奏或伴奏曲目","price":45
            //     },
            //     4:{
            //     "id":4,"number":"GTSMF4", "name":"School Music Festival - Secondary 1-2","des":"Any 1 solo or accompanied piece","des-cn":"任何一首独奏或伴奏曲目", "price":45
            //     },
            //     5:{
            //     "id":5,"number":"GTSMF5", "name":"School Music Festival - Secondary 3-4","des":"Any 1 solo or accompanied piece","des-cn":"任何一首独奏或伴奏曲目", "price":50
            //     },
            //     6:{
            //     "id":6,"number":"GTSMF6", "name":"School Music Festival - Secondary 5-6","des":"Any 1 solo or accompanied piece", "des-cn":"任何一首独奏或伴奏曲目","price":50
            //     },
            //     7:{
            //     "id":7,"number":"GTSMF7", "name":"School Music Festival - Primary Duet","des":"Any 1 duet piece for 2 primary school students", "des-cn":"任何一首二人独奏或伴奏曲目 - 小学组","price":50
            //     },
            //     8:{
            //     "id":8,"number":"GTSMF8", "name":"School Music Festival - Secondary Duet","des":"Any 1 duet piece for 2 secondary school students", "des-cn":"任何一首二人独奏或伴奏曲目 - 中学组","price":50
            //     },
        },
        1 : {			
            1:{
            "id":1,"number":"GTG1", "name":"Grade 1", "des":"Any 1 piece from any recognized Graded Exam Syllabus",  "des-cn":"任何国际分级考试中的任何 1 首乐曲","price":45
            },
            2:{
            "id":2,"number":"GTG2", "name":"Grade 2", "des":"Any 1 piece from any recognized Graded Exam Syllabus", "des-cn":"任何国际分级考试中的任何 1 首乐曲","price":45
            },
            3:{
            "id":3,"number":"GTG3", "name":"Grade 3", "des":"Any 1 piece from any recognized Graded Exam Syllabus","des-cn":"任何国际分级考试中的任何 1 首乐曲","price":50
            },
            4:{
            "id":4,"number":"GTG4", "name":"Grade 4","des":"Any 1 piece from any recognized Graded Exam Syllabus","des-cn":"任何国际分级考试中的任何 1 首乐曲", "price":50
            },
            5:{
            "id":5,"number":"GTG5", "name":"Grade 5","des":"Any 1 piece from any recognized Graded Exam Syllabus", "des-cn":"任何国际分级考试中的任何 1 首乐曲","price":55
            },
            6:{
            "id":6,"number":"GTG6", "name":"Grade 6","des":"Any 1 piece from any recognized Graded Exam Syllabus", "des-cn":"任何国际分级考试中的任何 1 首乐曲","price":55
            },
            7:{
            "id":7,"number":"GTG7", "name":"Grade 7", "des":"Any 1 piece from any recognized Graded Exam Syllabus", "des-cn":"任何国际分级考试中的任何 1 首乐曲","price":60
            },
            8:{
            "id":8,"number":"GTG8", "name":"Grade 8", "des":"Any 1 piece from any recognized Graded Exam Syllabus", "des-cn":"任何国际分级考试中的任何 1 首乐曲","price":60
            },
            9:{
            "id":9,"number":"GTDA", "name":"ARSM, DipABRSM, ATCL", "des":"Any 1 piece from ARSM, DipABRSM, ATCL under 7 minutes", "des-cn":"任何ARSM, DipABRSM, ATCL考试中的任何 1 首7分钟或以下乐曲","price":70
            },
            10:{
            "id":10,"number":"GTDL", "name":"LRSM, LTCL", "des":"Any 1 piece from LRSM/LTCL under 10 minutes", "des-cn":"任何LRSM/LTCL考试中的任何 1 首10分钟或以下乐曲","price":80
            },
            11:{
            "id":11,"number":"GTDF", "name":"FRSM,FTCL", "des":"Any 1 piece from FRSM/FTCL under 15 minutes", "des-cn":"任何FRSM/FTCL考试中的任何 1 首15分钟或以下乐曲","price":90
            },
        
        },       
        4 : {			
            // 1:{
            // "id":1,"number":"GTS1", "name":"Sonata Group", "des":"Any 1 Sonata under 8 minutes",  "price":80
            // },
            // 2:{
            // "id":2,"number":"W42", "name":"Concerto", "des":"Any 1 movement less than 12 minutes", "price":1000
            // },
            // 3:{
            // "id":3,"number":"W43", "name":"Composition", "des":"1 piece of original composition less than 6 minutes","price":800
            // },
        }
    },

    "other" :{
        5:{},
        0 : {}, 
        3 : {},  
        2 : {},
        1 : {			
            // 1:{
            // "id":1,"number":"R11", "name":"Primary 1", "des":"Any 1 piece less than 2 minutes",  "price":350
            // },
            // 2:{
            // "id":2,"number":"R12", "name":"Primary 2", "des":"Any 1 piece less than 2 minutes", "price":350
            // },
            // 3:{
            // "id":3,"number":"R13", "name":"Primary 3", "des":"Any 1 piece less than 2 minutes","price":350
            // },
            // 4:{
            // "id":4,"number":"R14", "name":"Primary 4","des":"Any 1 piece less than 2 minutes", "price":350
            // },
            // 5:{
            // "id":5,"number":"R15", "name":"Primary 5","des":"Any 1 piece less than 2 minutes", "price":350
            // },
            // 6:{
            // "id":6,"number":"R16", "name":"Primary 6","des":"Any 1 piece less than 2 minutes", "price":350
            // },
        },       
        4 : {
            1:{
            "id":1,"number":"OS1", "name":"Concerto Group", "des":"Any 1 movement less than 12 minutes",  "des-cn":"一首少于12分钟任的任何樂章","price":pricelist["OS1"]
            },
            2:{
            "id":2,"number":"OS2", "name":"Composition Group", "des":"Submit 1 piece of original composition under 8 minutes", "des-cn":"提交1篇首8分钟以内的原创乐曲", "price":pricelist["OS2"]
            },
            3:{
            "id":3,"number":"OS3", "name":"World Music Group", "des":"Any 1 traditional piece originally written for the instrument", "des-cn":"任何 1 首最为该乐器创作的传统曲目", "price":pricelist["OS3"]
            },
        }
    },
}

export default groupCat;