import '../../App.css';
import React, {Component, Container} from 'react';
import {Row,Col,Image,Navbar, Nav, NavItem, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import YoutubeBackground from 'react-youtube-background';
import Card from './card';
import NavBarTop from "./navbar";
import Footer from "./footer";

// email: hicusinvestment@outlook.com pw:Investhicus2022
//fixer.io exchange rate
//http://data.fixer.io/api/latest?access_key=c06cd1b831f00ec6c85cbac41eb810b1
// params: latest, historical, convert, time-series, fluctuation

//coinlayer.com cryptocurrency
//http://api.coinlayer.com/api/live?access_key=bca90b9e4854ff3c17f9d4fd088d661b
// params: list, live historical, convert, timeframe, change

//https://news.bitcoin.com/
class IYFAOAbout extends Component {
  constructor(props){
    super(props);
    this.state = {
       content:{
         "en":{ 
            "title":"Real-time Market Prices",
            "title2":"Financial News",
            "title3":"Historical Data",
            "tag1":"Cyptocurrencies",
            "tag2":"Commodity Futures",
            "tag3":"Forex Rates",
         },
         "zh":{ 
          "title":"實時市場價格",
          "title2":"財經新聞",
          "title3":"歷史價格圖表",
          "tag1":"加密貨幣",
          "tag2":"商品期貨",
          "tag3":"外匯匯率",
       },
       "cn":{ 
        "title":"实时市场价格",
        "title2":"财经新闻",
        "title3":"历史价格图表",
        "tag1":"加密货币",
        "tag2":"商品期货",
        "tag3":"外汇汇率",
     },
       },
    };

    this.setval = this.setval.bind(this)
  }

  setval(obj){
    this.setState(obj)
  }
  componentDidMount(){
    document.title="International Young Painter Open Competition"
    var link1 = document.getElementById('favicon')
    var link2 = document.getElementById('touchicon')
    link1.href="/assets/iypo-logo.PNG"
    link2.href="/assets/iypo-logo.PNG"
    this.props.setval({comp:"iypo",lang:"en"})
  }      
      
  render(){
    return (
      <div style={{padding:"1% 0%"}}>
      <div className="home-content-div">
        <div>
          <img  src="../assets/about-art.png" alt="" style={{height:"100%",width:"100%",borderRadius:"0px 0px"}}/>
          </div>
        <div className="cardwrapper">
          
          <Card index={1}
                title={this.props.iyfaocardcontent[this.props.lang]["title1"]}
                tagline={""}
                content={this.props.iyfaocardcontent[this.props.lang]["content1"]} 
                setval={this.props.setval} {...this.props}/>
          {/* <Card index={2}
                title={this.state.cardcontent[this.state.lang]["title2"]}
                tagline={this.state.cardcontent[this.state.lang]["tagline2"]}
                content={this.state.cardcontent[this.state.lang]["content2"]} />
          <Card index={3}
                title={this.state.cardcontent[this.state.lang]["title3"]}
                tagline={this.state.cardcontent[this.state.lang]["tagline3"]}
                content={this.state.cardcontent[this.state.lang]["content3"]} /> */}
        </div>

      </div>

       
        
        
          
      </div>
    );
  }
  }
  

export default IYFAOAbout;
