import '../../App.css';
import React, {Component, Container} from 'react';
import {Row,Col,Image,Navbar, Nav, NavItem, NavDropdown, Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import YoutubeBackground from 'react-youtube-background';
import Card from './card';
import NavBarTop from "./navbar";
import Footer from "./footer";
import PaypalCheckoutBtn from './elements/paypalcheckout';
import AwardCheckout from './elements/awardcheckout';
import laureate from '../../laureate';

const applyurl2 = "https://docs.google.com/forms/d/e/1FAIpQLScI3WtZkmY6NCmGlfib0Kh21KmFRg6UqouS8Rd1dflcdbj1zg/viewform"
class IYFAOAwards extends Component {
  constructor(props){
    super(props);
    this.state = {
       itemType:"请选择您的奖项",
       productDescription:null,
       awardPayment:0,
    };

    this.setval = this.setval.bind(this)
    this.clickapply = this.clickapply.bind(this)
  }

  clickapply(){
    window.open(applyurl2, '_blank', 'noopener,noreferrer');
}
  setval(obj){
    this.setState(obj)
  }
  componentDidMount(){
    this.props.setval({comp:"iypo-cn",lang:"cn"}) 
  }      
      
  render(){
    return (
      <div style={{width:"100%",padding:"1% 0%"}}><img src="/assets/award.png" alt="" width="100%" style={{borderRadius:"0px"}}/>
      <div className="home-content-div" style={{textAlign:"justify",textJustify:"inter-word", padding:"0% 7%",fontFamily:"Arial"}}>
        
        {/* put in award paypal here */}

        <h1 style={{marginBottom:"1vh"}}>{"订购奖杯和证书"}</h1>
        <br/>
        <h5>
          {"请选择您想要的商品并在下面在线支付。 然后，您可以使用下面的按钮继续输入您的货件信息。"}
        </h5>
        <div style={{display:"flex",flexDirection:"column",alignItems:"left",padding:"5px",border:"1px solid white",background:"rgba(255,255,255,0.9)",paddingLeft:"5%",paddingRight:"5%",color:"#1c1f1d",borderRadius:"10px",marginTop:"4%"}}>
            
            <h2  style={{marginLeft:"1%",marginTop:"2%",marginBottom:"3%"}}>{"立即使用信用卡或 PayPal 付款"}
              <p style={{fontSize:"0.56em",textAlign:"justify"}}>{"*可能会收取服务费。 请在 Paypal 弹出窗口中仔细检查您的金额。 支付成功后记得截图。如果您忘记了，您可以截取 PayPal 付款收据电子邮件的屏幕截图。"}</p></h2>
            
                <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      {this.state.itemType}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                    <Dropdown.Item onClick={(e)=>{
                        this.setState({itemType:"奖杯及证书",productDescription:"2023 ICMT 奖杯、证书和运费",awardPayment:"80"})
                      }}>{"奖杯及证书"}</Dropdown.Item>
                      <Dropdown.Item onClick={(e)=>{
                        this.setState({itemType:"只有奖杯",productDescription:"2023 ICMT 奖杯和运费",awardPayment:"65"})
                      }}>{"只有奖杯"}</Dropdown.Item>
                     <Dropdown.Item onClick={(e)=>{
                        this.setState({itemType:"只有证书",productDescription:"2023 ICMT 证书和运费",awardPayment:"25"})
                      }}>{"只有证书"}</Dropdown.Item>
                       {/* <Dropdown.Item onClick={(e)=>{
                        this.setState({itemType:"Testing",productDescription:"Testing",awardPayment:"0.01"})
                      }}>Test</Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>

            <br></br>

                {
                  (this.state.itemType==="奖杯及证书") && 
                  <h3 style={{marginTop:"2%",marginLeft:"2%",marginRight:"2%"}}>
                    <i>{"奖杯、证书和运费 (美元) :  "+this.state.awardPayment}</i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </h3>
                }
                {
                  (this.state.itemType==="只有奖杯") && 
                  <h3 style={{marginTop:"2%",marginLeft:"2%",marginRight:"2%"}}>
                    <i>{"奖杯和运费 (美元) : "+this.state.awardPayment}</i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </h3>
                }

                {
                  (this.state.itemType==="只有证书") && 
                  <h3 style={{marginTop:"2%",marginLeft:"2%",marginRight:"2%"}}>
                    <i>{"证书和运费 (美元) : "+this.state.awardPayment}</i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </h3>
                }

                {
                  (this.state.itemType==="Testing") && 
                  <h3 style={{marginTop:"2%",marginLeft:"2%",marginRight:"2%"}}>
                    <i>{"Certificate and Shipment Fee : USD "+this.state.awardPayment}</i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </h3>
                }
              

            <div style={{marginTop:"3%",marginLeft:"10%",marginRight:"10%"}}>
         {/* { <PaypalCheckoutButton  product={{description:"IYAO",price:52}}/>} */}
         <AwardCheckout {...this.state}/>
          </div>

          
          </div>

     <div style={{marginTop:"4%"}}>
          <h4 className="apply-body-font" style={{fontFamily:"Arial",fontWeight:"bolder",fontSize:"2rem"}}>{"如果您有付款收据，您现在可以点击下面的按钮并填写您的发货信息。"}</h4>
          </div>
          
          <a onClick={(e)=>{this.clickapply()}}><button id="goldbutton-apply" style={{fontSize:"160%",width:"50%"}} >
         {"申请奖项"}</button></a>
          
        

        {/* paypalends */}

        <h1 style={{marginTop:"7%",marginBottom:"1vh"}}>{"奖项"}</h1>

        <ul>
        <li><h5>每个类别都有<b>冠军</b>, <b>亞军</b>, <b>季军</b>和<b>殿军</b>。首四名得獎者的教师将获得<b>优秀导师奖</b>。 </h5></li>
        <li><h5>{"分数>=85的参赛者将获得金奖，75-84的参赛者将获得银奖，65-74的参赛者将获得铜奖，分数低于65的参赛者将获得我们的证明书。"}</h5></li>
        <li><h5>{"冠军、亚军、季军将免费受邀参加 2024年国际青年美术家公开赛。"}</h5></li>
        <li><h5>任何在任何小组中推荐四名或以上学生的教师都将获得<b>杰出指导奖</b>。 </h5></li>
        <li><h5>任何中心或学校推荐八名或以上学生将获得<b>杰出教育机构奖</b>。</h5></li>
        {/* <br/><h5>Champions, Second Prize, Third Prize and Fourth Place winners will also receive a reference letter upon completion of the competition. Champions will be invited to have their profile photo displayed on our website, and other social media platforms. </h5> */}
        <li><h5>奖品将以电子证书形式发送<sup>1</sup>.</h5> </li>
    
        </ul>

        <br></br>
        <h4 className="apply-body-font">满分为100分，将按照以下标准对参赛者进行评估：</h4>
        <ol style={{listStyleType:"upper-roman"}}>
          <li><h4 style={{fontSize:"1.3em"}}>{"创意 (40%)"}</h4></li>
          <li><h4 style={{fontSize:"1.3em"}}>{"技巧 (20%)"}</h4></li>
          <li><h4 style={{fontSize:"1.3em"}}>{"色彩运用 (20%)"}</h4></li>
          <li><h4 style={{fontSize:"1.3em"}}>{"构图 (20%)"}</h4></li>
          {/* <li><h4 style={{fontSize:"1.3em"}}>Musicality</h4></li>
          <li><h4 style={{fontSize:"1.3em"}}>Style</h4></li>
          <li><h4 style={{fontSize:"1.3em"}}>Tone Color</h4></li>
          <li><h4 style={{fontSize:"1.3em"}}>Presentation</h4></li> */}
        </ol>
        <br></br>
        
    {/* <table style={{maxWidth:"100%",padding:"0% 1%"}}>
    <tr>
        <td>Marks</td>
        <td>Class </td>
    </tr>
    <tr>
        <td>&lt;&nbsp;65 </td>
        <td>Severe impairment of submission’s integrity  </td>
    </tr>
    <tr>
        <td>65-75 </td>
        <td>Below satisfactory </td>
    </tr>
    <tr>
        <td>75-80 </td>
        <td>Satisfactory </td>
    </tr>
    <tr>
        <td>80-85 </td>
        <td>Merit </td>
    </tr>
    <tr>
        <td>85-90 </td>
        <td>Distinction and/or Prizes </td>
    </tr>
    <tr>
        <td>&gt;&nbsp;90 </td>
        <td>Distinction and/or Prizes </td>
    </tr>
   
</table> */}

      {<div style={{marginTop:"6%",fontFamily:"Arial",fontSize:"1em"}}>
        <p><sup>1</sup><i>{"如需奖杯和纸质证书，请在收到结果后7天内通过电子邮件通知我们。"}</i></p>
      </div>}
      

      {/* <h1 style={{marginTop:"7%",marginBottom:"1vh"}}>Champion List</h1>
                <br></br> */}

      {/* <div style={{justifyContent:"center",display:"flex",flexDirection:"column",fontSize:"1.3rem"}}>
      <table className="award-laureate-table" >
      {
        Object.keys(laureate).map((key,item)=>{
          return(  
                <tr style={{border:"1px solid white"}}>
                  <td style={{textAlign:"center",borderRight:"1px solid white"}}>{key}</td>
                  <td style={{textAlign:"center"}}>{laureate[key]}</td>
                </tr>    
          )
        })
      }
      </table>
      </div> */}
      

      
      </div>
      </div>
    );
  }
  }
  

export default IYFAOAwards;
