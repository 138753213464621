const pricelist = {
    "PFC1":50,
    "PFC2":60,
    "PFC3":70,
    "PFC4":50,
    "PFC5":60,
    "PFC6":70,
    "PFC7":50,
    "PFC8":60,
    "PFC9":70,
    "PFC10":50,
    "PFC11":60,
    "PFC12":70,
    "PFC13":50,
    "PFC14":60,
    "PFC15":70,
    "PFC16":50,
    "PFC17":60,
    "PFC18":70,

    "PFSMF1":40,
    "PFSMF2":40,
    "PFSMF3":40,
    "PFSMF4":45,
    "PFSMF5":45,
    "PFSMF6":45,
    "PFSMF7":50,
    "PFSMF8":50,
    "PFSMF9":50,
    "PFSMF10":50,

    "PFG1":45,
    "PFG2":45,
    "PFG3":50,
    "PFG4":50,
    "PFG5":55,
    "PFG6":55,
    "PFG7":60,
    "PFG8":60,
    "PFDA":70,
    "PFDL":80,
    "PFDF":90,

    "PFA1":50,
    "PFA2":50,
    "PFA3":60,
    "PFA4":60,
    "PFA5":70,
    "PFA6":80,

    "PFS1":50,
    "PFS2":50,
    "PFS3":60,
    "PFS4":60,
    "PFS5":70,
    "PFS6":80,

    "PFQ1":65,
    "PFQ2":65,
    "PFQ3":65,
    "PFQ4":65,
    "PFQ5":65,

    "VNC1":50,
    "VNC2":60,
    "VNC3":70,
    "VNC4":50,
    "VNC5":60,
    "VNC6":70,
    "VNC7":50,
    "VNC8":60,
    "VNC9":70,
    "VNC10":50,
    "VNC11":60,
    "VNC12":70,
    "VNC13":50,
    "VNC14":60,
    "VNC15":70,

    "VNA1":50,
    "VNA2":50,
    "VNA3":60,
    "VNA4":60,
    "VNA5":70,
    "VNA6":80,

    "VNSMF1":40,
    "VNSMF2":40,
    "VNSMF3":40,
    "VNSMF4":45,
    "VNSMF5":45,
    "VNSMF6":45,
    "VNSMF7":50,
    "VNSMF8":50,
    "VNSMF9":50,
    "VNSMF10":50,

    "VNG1":45,
    "VNG2":45,
    "VNG3":50,
    "VNG4":50,
    "VNG5":55,
    "VNG6":55,
    "VNG7":60,
    "VNG8":60,
    "VNDA":70,
    "VNDL":80,
    "VNDF":90,

    // "VNS1":80,

    "VCC1":50,
    "VCC2":60,
    "VCC3":70,
    "VCC4":50,
    "VCC5":60,
    "VCC6":70,
    "VCC7":50,
    "VCC8":60,
    "VCC9":70,
    "VCC10":50,
    "VCC11":60,
    "VCC12":70,

    "VCA1":50,
    "VCA2":50,
    "VCA3":60,
    "VCA4":60,
    "VCA5":70,
    "VCA6":80,

    "VCSMF1":40,
    "VCSMF2":40,
    "VCSMF3":45,
    "VCSMF4":45,
    "VCSMF5":50,
    "VCSMF6":50,

    "VCG1":45,
    "VCG2":45,
    "VCG3":50,
    "VCG4":50,
    "VCG5":55,
    "VCG6":55,
    "VCG7":60,
    "VCG8":60,
    "VCDA":70,
    "VCDL":80,
    "VCDF":90,

    // "VCS1":80,

    "VOC1":50,
    "VOC2":60,
    "VOC3":70,
    "VOC4":50,
    "VOC5":60,
    "VOC6":70,
    "VOC7":50,
    "VOC8":60,
    "VOC9":70,
    "VOC10":50,
    "VOC11":60,
    "VOC12":70,
    "VOC13":50,
    "VOC14":60,
    "VOC15":70,
    "VOC16":50,
    "VOC17":60,
    "VOC18":70,

    "VOA1":50,
    "VOA2":50,
    "VOA3":60,
    "VOA4":60,
    "VOA5":70,
    "VOA6":80,

    "VOSMF1":40,
    "VOSMF2":40,
    "VOSMF3":45,
    "VOSMF4":45,
    "VOSMF5":50,
    "VOSMF6":50,
    "VOSMF7":50,
    "VOSMF8":50,
    "VOSMF9":40,

    "VOG1":45,
    "VOG2":45,
    "VOG3":50,
    "VOG4":50,
    "VOG5":55,
    "VOG6":55,
    "VOG7":60,
    "VOG8":60,
    "VODA":70,
    "VODL":80,
    "VODF":90,

    "FLC1":50,
    "FLC2":60,
    "FLC3":70,
    "FLC4":50,
    "FLC5":60,
    "FLC6":70,
    "FLC7":50,
    "FLC8":60,
    "FLC9":70,
    "FLC10":50,
    "FLC11":60,
    "FLC12":70,

    "FLA1":50,
    "FLA2":50,
    "FLA3":60,
    "FLA4":60,
    "FLA5":70,
    "FLA6":80,

    "FLSMF1":40,
    "FLSMF2":40,
    "FLSMF3":45,
    "FLSMF4":45,
    "FLSMF5":50,
    "FLSMF6":50,

    "FLG1":45,
    "FLG2":45,
    "FLG3":50,
    "FLG4":50,
    "FLG5":55,
    "FLG6":55,
    "FLG7":60,
    "FLG8":60,
    "FLDA":70,
    "FLDL":80,
    "FLDF":90,

    // "FLS1":80,

    "CLC1":50,
    "CLC2":60,
    "CLC3":70,
    "CLC4":50,
    "CLC5":60,
    "CLC6":70,
    "CLC7":50,
    "CLC8":60,
    "CLC9":70,

    "CLA1":50,
    "CLA2":50,
    "CLA3":60,
    "CLA4":60,
    "CLA5":70,
    "CLA6":80,

    "CLSMF1":40,
    "CLSMF2":40,
    "CLSMF3":45,
    "CLSMF4":45,
    "CLSMF5":50,
    "CLSMF6":50,

    "CLG1":45,
    "CLG2":45,
    "CLG3":50,
    "CLG4":50,
    "CLG5":55,
    "CLG6":55,
    "CLG7":60,
    "CLG8":60,
    "CLDA":70,
    "CLDL":80,
    "CLDF":90,

    // "CLS1":80,
    "CLQ1":65,

    "TRC1":50,
    "TRC2":60,
    "TRC3":70,
    "TRC4":50,
    "TRC5":60,
    "TRC6":70,
    "TRC7":50,
    "TRC8":60,
    "TRC9":70,

    "TRA1":50,
    "TRA2":50,
    "TRA3":60,
    "TRA4":60,
    "TRA5":70,
    "TRA6":80,

    "TRSMF1":40,
    "TRSMF2":40,
    "TRSMF3":45,
    "TRSMF4":45,
    "TRSMF5":50,
    "TRSMF6":50,

    "TRG1":45,
    "TRG2":45,
    "TRG3":50,
    "TRG4":50,
    "TRG5":55,
    "TRG6":55,
    "TRG7":60,
    "TRG8":60,
    "TRDA":70,
    "TRDL":80,
    "TRDF":90,

    // "TRS1":80,

    "TBC1":50,
    "TBC2":60,
    "TBC3":70,
    "TBC4":50,
    "TBC5":60,
    "TBC6":70,
    "TBC7":50,
    "TBC8":60,
    "TBC9":70,

    "TBA1":50,
    "TBA2":50,
    "TBA3":60,
    "TBA4":60,
    "TBA5":70,
    "TBA6":80,

    "TBSMF1":40,
    "TBSMF2":40,
    "TBSMF3":45,
    "TBSMF4":45,
    "TBSMF5":50,
    "TBSMF6":50,

    "TBG1":45,
    "TBG2":45,
    "TBG3":50,
    "TBG4":50,
    "TBG5":55,
    "TBG6":55,
    "TBG7":60,
    "TBG8":60,
    "TBDA":70,
    "TBDL":80,
    "TBDF":90,

    // "TBS1":80,

    "HPC1":50,
    "HPC2":60,
    "HPC3":70,
    "HPC4":50,
    "HPC5":60,
    "HPC6":70,
    "HPC7":50,
    "HPC8":60,
    "HPC9":70,

    "HPA1":50,
    "HPA2":50,
    "HPA3":60,
    "HPA4":60,
    "HPA5":70,
    "HPA6":80,

    "HPG1":45,
    "HPG2":45,
    "HPG3":50,
    "HPG4":50,
    "HPG5":55,
    "HPG6":55,
    "HPG7":60,
    "HPG8":60,
    "HPDA":70,
    "HPDL":80,
    "HPDF":90,

    "GTC1":50,
    "GTC2":60,
    "GTC3":70,
    "GTC4":50,
    "GTC5":60,
    "GTC6":70,
    "GTC7":50,
    "GTC8":60,
    "GTC9":70,

    "GTA1":50,
    "GTA2":50,
    "GTA3":60,
    "GTA4":60,
    "GTA5":70,
    "GTA6":80,

    "GTSMF1":40,
    "GTSMF2":40,
    "GTSMF3":45,
    "GTSMF4":45,
    "GTSMF5":50,
    "GTSMF6":50,
    "GTSMF7":50,
    "GTSMF8":50,

    "GTG1":45,
    "GTG2":45,
    "GTG3":50,
    "GTG4":50,
    "GTG5":55,
    "GTG6":55,
    "GTG7":60,
    "GTG8":60,
    "GTDA":70,
    "GTDL":80,
    "GTDF":90,

    // "GTS1":80,

    "OS1":130,
    "OS2":80,
    "OS3":50,

    "TESTPAYMENT":0.01,
}

export default pricelist;