import '../../App.css';
import React, {Component, Container} from 'react';
import {Row,Col,Image,Navbar, Nav, NavItem, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import YoutubeBackground from 'react-youtube-background';
import Card from './card';
import IypcNavBarTop from "./navbar";
import IypcFooter from "./footer";
import AdsComponent from '../AdsComponent';

import {Adsense} from '@ctrl/react-adsense';


const content = {
    "en":{
      "home":"Home","market":"Market", "others":"Others", "companyname": "International Young Fine Art Artist Open Competition", "companytagline":"Multiple age groups in painting, coloring, sculpturing, and more...", "extratagline": "Unleash your talents today", "companyintro": "ONLINE COMPETITION", "introcontent":" Online submission\nDeadline : 16th March 2023",
    },
    "zh":{
      "home":"主頁","market":"市場動態", "others":"其他",  "companyname": "海克斯國際有限公司", "companytagline":"您的財務合作夥伴", "extratagline": "---全新NFT即將發售---", "companyintro": "公司簡介", "introcontent":"海克斯國際有限公司是一家領先的數字化金融科技公司，專注於為用戶提供覆蓋多個市場的全數字化在線券商服務，繼而變革投資體驗。海克斯通過自主研發的一站式數字化金融服務平台，提供面向C端的市場數據、資訊、社交等服務，海克斯提供數字貨幣、現貨黃金、外匯交易、財富管理等服務，並以用户為中心構建完善的金融科技生態系統，連接用戶、投資者、企業、分析師、媒體和意見領袖。同時，從而實現企業業務與零售經紀業務的高度互補。"+"\n\r"+"槓桿式外匯交易、就數字貨幣提供意見、就現貨黃金提供意見、提供自動化交易服務、提供資產管理的資質。"+"\n\r"+"未來，海克斯將繼續秉承體驗至上，用戶第一的理念，不斷推動產品疊代升級，推出更多普惠投資者、企業和行業的產品及服務，成為有顯著影響力的全球金融服務企業。",
    },
    "cn":{
      "home":"主页","market":"市场动态", "others":"其他", "companyname": "海克斯国际有限公司", "companytagline":"您的财务合作伙伴.", "extratagline": "---全新NFT即将发售---", "companyintro": "公司简介", "introcontent":"海克斯国际有限公司是一家领先的数字化金融科技公司，专注于为用户提供覆盖多个市场的全数字化在线券商服务，继而变革投资体验。海克斯通过自主研发的一站式数字化金融服务平台，提供面向C端的市场数据、资讯、社交等服务，海克斯提供数字货币、现货黄金、外汇交易、财富管理等服务，并以用户为中心构建完善的金融科技生态系统，连接用户、投资者、企业、分析师、媒体和意见领袖。同时，从而实现企业业务与零售经纪业务的高度互补。 "+"\n\r"+"杠杆式外汇交易、就数字货币提供意见、就现货黄金提供意见、提供自动化交易服务、提供资产管理的资质。 "+"\n\r"+"未来，海克斯将继续秉承体验至上，用户第一的理念，不断推动产品叠代升级，推出更多普惠投资者、企业和行业的产品及服务，成为有显著影响力的全球金融服务企业。",
    },
  }

class IYFAOHome extends Component {
  constructor(props){
    super(props);
    this.state = {
       
    };

    this.setval = this.setval.bind(this)
  }

  setval(obj){
    this.setState(obj)
  }
  componentDidMount(){
    document.title="International Young Painter Open Competition"
    var link1 = document.getElementById('favicon')
    var link2 = document.getElementById('touchicon')
    link1.href="/assets/iypo-logo.PNG"
    link2.href="/assets/iypo-logo.PNG"
   this.props.setval({comp:"iypo",lang:"en"})
  }      
      
  render(){
    return (
        <div>

<div className="vid-wrapper">
        
        <div className="front-layer-white">
          {/* <h1 className="home-comp-title">{content[this.props.lang]["companyname"]}</h1>

          <div>&nbsp;</div>
          {
          content[this.props.lang]["introcontent"].split('\n').map(
            (paragraph) => {
             return <div className="home-comp-subtitle2">{paragraph}</div>;
            })
        } */}
          <div className="home-break">&nbsp;</div>
        <a href="/iypo/apply"><button className="home-whitebutton" onClick={(e)=>{
          //window.open(applyurl, '_blank', 'noopener,noreferrer');
          //this.setState({pageSelected:"apply"})
          }}>APPLY NOW</button></a>
        </div>
        <div style={{maxWidth:"100vw",overflow:"hidden"}}>
            <video loop autoPlay muted className="vid-container-icmt">
            <source
              src="/assets/iypo-2024-vid.MP4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
        
      </div>


      <div className="home-poster">
         <img className="home-poster-img" src="/assets/iypc-poster-2024.PNG" alt="" height="100%" />
         {/* <Card index={1}
                title={this.props.cardcontent[this.props.lang]["title1"]}
                tagline={""}
                content={this.props.cardcontent[this.props.lang]["content1"]} 
                setval={this.props.setval} {...this.props}/> */}
       </div>


        {/* <div className="vid-wrapper">
        
        <div className="home-column-container">
        <div className="home-column-strip">
          <img src='/assets/iypc-poster-2024.PNG' width="100%" height="150%" />
        </div>

        <div className={["home-column-strip","home-strip-background-2"].join(" ")}>
          <div className="home-textstrip" style={{minHeight:"None"}}>{"About the Competition"}</div>
          <div className="home-textstrip">
            {this.props.cardcontent[this.props.lang]["content4"]}
          </div>
        </div>

        <div className={["home-column-strip","home-strip-background-3"].join(" ")}>
          <div className="home-textstrip" style={{marginTop:"50px"}}>
            {this.props.cardcontent[this.props.lang]["content2"]}
          </div>

          <div style={{display:"flex",flexDirection:"row",justifyContent:"right",marginRight:"40px"}}>
           <a href="/iypo/category"> <button className="home-goldbutton" 
             style={{width:"70px",height:"50px",fontSize:"small"}}> 
              Our categories
            </button></a>
          </div>

          <div className="home-textstrip" style={{marginTop:"50px"}}>
            {this.props.cardcontent[this.props.lang]["content3"]}
          </div>

          <div style={{display:"flex",flexDirection:"row",justifyContent:"right",marginRight:"40px"}}>
            <a href="/iypo/apply"><button className="home-goldbutton"
              style={{width:"70px",height:"50px",fontSize:"small"}}>
                APPLY NOW
            </button></a>
          </div>

          <div style={{minHeight:"200px"}}>
            <AdsComponent dataAdSlot='8954024389' />
          </div>
          
        </div>
        
        </div>
      </div> */}
         
       {/* <div className="home-poster">
         <img className="home-poster-img" src="assets/icmt-poster.png" alt="" height="100%" />
       </div> */}

        </div>
    );
  }
  }
  

export default IYFAOHome;
