import '../../App.css';
import React, {Component} from 'react';
import {Row, Col, Button,Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import YoutubeBackground from 'react-youtube-background';

import NavBarTop from "./navbar";
import Footer from "./footer";
import groupCat from '../../cat';

class Category extends Component {
  constructor(props){
    super(props);
    this.state = {  
       group0:groupCat["piano"][0],
       group1:groupCat["piano"][1],
       group2:groupCat["piano"][2],
       group3:groupCat["piano"][3],
       group4:groupCat["piano"][4],
       group5:groupCat["piano"][5],
       instrument: "piano",
    };

    this.changeGroup = this.changeGroup.bind(this)
    this.setval = this.setval.bind(this)
  }
  componentDidMount(){
    this.props.setval({comp:"icmt-cn"})
  }
  setval(obj){
    this.setState(obj)
  }
  changeGroup(val){
   const _group = JSON.parse(JSON.stringify(groupCat[val]))
   //alert(JSON.stringify(_group))
   this.setState({group0:_group[0],group1:_group[1],group2:_group[2],group3:_group[3],group4:_group[4],group5:_group[5],instrument:val})
  }
  render(){
    return (
      <div className="category-div">
        <video loop autoPlay muted id="myVideo">
          <source
            src="../assets/category.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
     
        
        <div id="cat-content"> 
          <button className={[(this.state.instrument=="piano")?"cat-button-selected":"cat-button"].join(" ")} 
            style={{fontWeight:"bold",marginBottom:"7px"}} onClick={(e)=>{this.changeGroup("piano")}}>{"钢琴"}</button>
          <button className={[(this.state.instrument=="violin")?"cat-button-selected":"cat-button"].join(" ")} 
             style={{fontWeight:"bold",marginBottom:"7px"}} onClick={(e)=>{this.changeGroup("violin")}}>{"小提琴"}</button>
              <button className={[(this.state.instrument=="cello")?"cat-button-selected":"cat-button"].join(" ")} 
             style={{fontWeight:"bold",marginBottom:"7px"}} onClick={(e)=>{this.changeGroup("cello")}}>{"大提琴"}</button>
          <button className={[(this.state.instrument=="vocal")?"cat-button-selected":"cat-button"].join(" ")} 
             style={{fontWeight:"bold",marginBottom:"7px"}} onClick={(e)=>{this.changeGroup("vocal")}}>{"声乐"}</button>
          <button className={[(this.state.instrument=="flute")?"cat-button-selected":"cat-button"].join(" ")} 
             style={{fontWeight:"bold",marginBottom:"7px"}} onClick={(e)=>{this.changeGroup("flute")}}>{"长笛"}</button>
              <button className={[(this.state.instrument=="clarinet")?"cat-button-selected":"cat-button"].join(" ")} 
             style={{fontWeight:"bold",marginBottom:"7px"}} onClick={(e)=>{this.changeGroup("clarinet")}}>{"单簧管"}</button>
              <button className={[(this.state.instrument=="trumpet")?"cat-button-selected":"cat-button"].join(" ")} 
             style={{fontWeight:"bold",marginBottom:"7px"}} onClick={(e)=>{this.changeGroup("trumpet")}}>{"小号"}</button>
                 <button className={[(this.state.instrument=="trombone")?"cat-button-selected":"cat-button"].join(" ")} 
             style={{fontWeight:"bold",marginBottom:"7px"}} onClick={(e)=>{this.changeGroup("trombone")}}>{"长号"}</button>
          <button className={[(this.state.instrument=="harp")?"cat-button-selected":"cat-button"].join(" ")} 
             style={{fontWeight:"bold",marginBottom:"7px"}} onClick={(e)=>{this.changeGroup("harp")}}>{"竖琴"}</button>
             <button className={[(this.state.instrument=="guitar")?"cat-button-selected":"cat-button"].join(" ")} 
             style={{fontWeight:"bold",marginBottom:"7px"}} onClick={(e)=>{this.changeGroup("guitar")}}>{"古典吉他"}</button>
          <button className={[(this.state.instrument=="other")?"cat-button-selected":"cat-button"].join(" ")} 
             style={{fontWeight:"bold",marginBottom:"7px"}} onClick={(e)=>{this.changeGroup("other")}}>{"其他"}</button>
        </div>
        <Container fluid style={{padding:"2% 5%",fontFamily:"Courgette",zIndex:"10",position:"relative"}} >
        

        { (Object.keys(this.state.group5).length>0) &&
         <div style={{padding:"0% 1%"}}>
         <Row>
          <Col xs={12} md={12} style={{textAlign:"left", marginLeft:"0%",fontSize:"1.4em"}}>{"原創曲目组别"}</Col>
          </Row> 
          <Row>
          <Col xs={2} md={1}>{"编号"}</Col>
          <Col xs={4} md={7}>{"组别"}</Col>
          <Col xs={4}  md={3}>{"比賽項目"}</Col>
          <Col xs={2} md={1}>{"价格（美元）"}</Col>
          </Row> 
          </div>}
          { (Object.keys(this.state.group5).length>0) &&
            Object.keys(this.state.group5).map((idx)=>
              <Row key={idx} style={{padding:"0% 1%"}}>
              <Col xs={2} md={1} style={{fontSize:"0.8em"}}>{this.state.group5[idx]["number"]}</Col>
              <Col xs={4} md={7}>{this.state.group5[idx]["name"]+"  "+" ( "}<a 
              href={"/assets/"+this.state.group5[idx]["link"]}
              color="transparent"
              target="_blank">{"下載曲目"}</a>{" )  "}</Col>
              <Col xs={4} md={3}>{this.state.group5[idx]["des-cn"]}</Col>
              <Col xs={2} md={1}>{this.state.group5[idx]["price"]}</Col>
              </Row> 
            )
          }

          <br></br>


         { (Object.keys(this.state.group0).length>0) &&
         <div style={{padding:"0% 1%"}}>
         <Row>
          <Col xs={12} md={12} style={{textAlign:"left", marginLeft:"0%",fontSize:"1.4em"}}>{"作曲家组别"}</Col>
          </Row> 
          <Row>
          <Col xs={2} md={1}>{"编号"}</Col>
          <Col xs={4} md={7}>{"组别"}</Col>
          <Col xs={4}  md={3}>{"比賽項目"}</Col>
          <Col xs={2} md={1}>{"价格（美元）"}</Col>
          </Row> 
          </div>}
          { (Object.keys(this.state.group0).length>0) &&
            Object.keys(this.state.group0).map((idx)=>
              <Row key={idx} style={{padding:"0% 1%"}}>
              <Col xs={2} md={1} style={{fontSize:"0.8rem"}}>{this.state.group0[idx]["number"]}</Col>
              <Col xs={4} md={7}>{this.state.group0[idx]["name"]}</Col>
              <Col xs={4} md={3}>{this.state.group0[idx]["des-cn"]}</Col>
              <Col xs={2} md={1}>{this.state.group0[idx]["price"]}</Col>
              </Row> 
            )
          }

          <br></br>
          {(Object.keys(this.state.group3).length>0) && 
          <div style={{padding:"0% 1%"}}>
            <Row>
          <Col xs={12} md={12} style={{textAlign:"left", marginLeft:"0%",fontSize:"1.4em"}}>{"年龄组别"}</Col>
          </Row> 
          <Row>
          <Col xs={2} md={1}>{"编号"}</Col>
          <Col xs={4} md={7}>{"组别"}</Col>
          <Col xs={4}  md={3}>{"比賽項目"}</Col>
          <Col xs={2} md={1}>{"价格（美元）"}</Col>
          </Row>
          </div>} 
          { (Object.keys(this.state.group3).length>0) &&
            Object.keys(this.state.group3).map((idx)=>
              <Row key={idx} style={{padding:"0% 1%"}} >
              <Col xs={2} md={1} style={{fontSize:"0.8rem"}}>{this.state.group3[idx]["number"]}</Col>
              <Col xs={4} md={7}>{this.state.group3[idx]["name"]}</Col>
              <Col xs={4} md={3}>{this.state.group3[idx]["des-cn"]}</Col>
              <Col xs={2} md={1}>{this.state.group3[idx]["price"]}</Col>
              </Row> 
            )
          }
         
          <br></br>

          {(Object.keys(this.state.group1).length>0) &&
          <div style={{padding:"0% 1%"}}><Row>
          <Col xs={12} md={12} style={{textAlign:"left", marginLeft:"0%",fontSize:"1.4em"}}>{(this.state.instrument==="recorder")?"Primary School Groups":"分级考试组別"}</Col>
          </Row> 
          <Row>
          <Col xs={2} md={1} style={{fontSize:"1.2em"}}>{"编号"}</Col>
          <Col xs={4} md={7} style={{fontSize:"1.2em"}}>{"组别"}</Col>
          <Col xs={4}  md={3} style={{fontSize:"1.2em"}}>{"比賽項目"}</Col>
          <Col xs={2} md={1} style={{fontSize:"1.2em"}}>{"价格（美元）"}</Col>
          </Row></div>}
          { (Object.keys(this.state.group1).length>0) &&
            Object.keys(this.state.group1).map((idx)=>
              <Row key={idx} style={{padding:"0% 1%"}} >
              <Col xs={2} md={1} style={{fontSize:"0.8rem"}}>{this.state.group1[idx]["number"]}</Col>
              <Col xs={4} md={7}>{this.state.group1[idx]["name"]}</Col>
              <Col xs={4} md={3}>{this.state.group1[idx]["des-cn"]}</Col>
              <Col xs={2} md={1}>{this.state.group1[idx]["price"]}</Col>
              </Row> 
            )
          }

<br></br>
          {(Object.keys(this.state.group4).length>0) &&
          <div style={{padding:"0% 1%"}}><Row>
          <Col xs={12} md={12} style={{textAlign:"left", marginLeft:"0%",fontSize:"1.4em"}}>{"其他组别"}</Col>
          </Row> 
          <Row>
          <Col xs={2} md={1}>{"编号"}</Col>
          <Col xs={4} md={7}>{"组别"}</Col>
          <Col xs={4}  md={3}>{"比賽項目"}</Col>
          <Col xs={2} md={1}>{"价格（美元）"}</Col>
          </Row></div>}
          { (Object.keys(this.state.group4).length>0) &&
            Object.keys(this.state.group4).map((idx)=>
              <Row key={idx} style={{padding:"0% 1%"}}>
              <Col xs={2} md={1} style={{fontSize:"0.8rem"}}>{this.state.group4[idx]["number"]}</Col>
              <Col xs={4} md={7}>{this.state.group4[idx]["name"]}</Col>
              <Col xs={4} md={3}>{this.state.group4[idx]["des-cn"]}</Col>
              <Col xs={2} md={1}>{this.state.group4[idx]["price"]}</Col>
              </Row> 
            )
          }


          <br></br>

          {(Object.keys(this.state.group2).length>0) &&
          <div style={{padding:"0% 1%"}}>
            <Row>
          <Col xs={12} md={12} style={{textAlign:"left", marginLeft:"0%",fontSize:"1.4em"}}>{"2023特别组别"}</Col>
          </Row> 
          <Row>
          <Col xs={12} md={12} style={{textAlign:"left", marginLeft:"0%",fontSize:"1.4em"}}>{"香港校际音乐节组别"}</Col>
          </Row> 
          <Row>
          <Col xs={2} md={1}>{"编号"}</Col>
          <Col xs={4} md={7}>{"组别"}</Col>
          <Col xs={4}  md={3}>{"比賽項目"}</Col>
          <Col xs={2} md={1}>{"价格（美元）"}</Col>
          </Row></div>} 
          { (Object.keys(this.state.group2).length>0) &&
            Object.keys(this.state.group2).map((idx)=>
              <Row key={idx} style={{padding:"0% 1%"}} >
              <Col xs={2} md={1} style={{fontSize:"0.8rem"}}>{this.state.group2[idx]["number"]}</Col>
              <Col xs={4} md={7}>{this.state.group2[idx]["name"]}</Col>
              <Col xs={4} md={3}>{this.state.group2[idx]["des-cn"]}</Col>
              <Col xs={2} md={1}>{this.state.group2[idx]["price"]}</Col>
              </Row> 
            )
          }
 
          </Container>

      </div>
    );
  }
  }
  

export default Category;
