import React from 'react'
import { Navbar,Nav,NavDropdown,Form,FormControl,Button } from 'react-bootstrap'

const year = new Date().getFullYear()
class IcmtCnFooter extends React.Component{
    constructor(props){
        super(props);
        this.state = {  
            copyright : {
                "en":{
                    "tagline":"Copyright @ "+year+"    All Rights Reserved.",
                },
                "zh":{
                    "tagline":"海克斯國際有限公司 @ "+year+"    版權所有",
                },
                "cn":{
                    "tagline":"版权所有 @ "+year+" ",
                },
            }
        };
    
        this.openlink = this.openlink.bind(this)
      }
      componentDidMount(){
        this.props.setval({comp:"icmt-cn"}) 
      }      
      openlink(link){
        window.open(link)
      }
    render(){
        return(
            <div className="footer">
                <div className="footer-img-wrapper">
                    <a href="https://www.facebook.com/profile.php?id=100090897736064&mibextid=LQQJ4d" target="_blank">
                    <img src="../../assets/fb48.png" alt="Facebook" />
                    </a>
                    <a href="https://instagram.com/icmt_icmt?igshid=YmMyMTA2M2Y=" target="_blank">
                    <img src="../../assets/ig48.png" alt="Instagram" />
                    </a>
                    <a href="https://ytmagasin.com/" target="_blank" style={{marginLeft:"60px"}}>
                    <img src="../../assets/yt-magasin-logo2.jpeg" width={"150px"} alt="YT Magasin" style={{padding:"10px"}} />
                    </a>
                    {/* <a href="https://www.youtube.com" target="_blank">
                    <img src="/assets/yt48.png" alt="YouTube" />
                    </a> */}
                </div>

                <div className="footer-text-center">
                    <p style={{fontFamily:"Optima"}}>{this.state.copyright["cn"]["tagline"]}</p>
                </div>
            </div>
        )  
    }
}

export default IcmtCnFooter;