import '../../App.css';
import React, {Component, Container} from 'react';
import {Row,Col,Image,Navbar, Nav, NavItem, NavDropdown, Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import YoutubeBackground from 'react-youtube-background';
import Card from './card';
import NavBarTop from "./navbar";
import Footer from "./footer";
import PaypalCheckoutBtn from './elements/paypalcheckout';
import AwardCheckout from './elements/awardcheckout';
import laureate from '../../laureate';

const applyurl2 = "https://docs.google.com/forms/d/e/1FAIpQLSeMAN9DIvc7Gsc8YqPUeev0eYs2Jcu6m7qgEB6lZQMuinCTZQ/viewform"
class IYFAOAwards extends Component {
  constructor(props){
    super(props);
    this.state = {
       itemType:"Please select your item",
       productDescription:null,
       awardPayment:0,
    };

    this.setval = this.setval.bind(this)
    this.clickapply = this.clickapply.bind(this)
  }

  clickapply(){
    window.open(applyurl2, '_blank', 'noopener,noreferrer');
}
  setval(obj){
    this.setState(obj)
  }
  componentDidMount(){
    document.title="International Young Painter Open Competition"
    var link1 = document.getElementById('favicon')
    var link2 = document.getElementById('touchicon')
    link1.href="/assets/iypo-logo.PNG"
    link2.href="/assets/iypo-logo.PNG"
    this.props.setval({comp:"iypo",lang:"en"}) 
  }      
      
  render(){
    return (
      <div style={{width:"100%",padding:"1% 0%"}}><img src="/assets/award.png" alt="" width="100%" style={{borderRadius:"0px"}}/>
      <div className="home-content-div" style={{textAlign:"justify",textJustify:"inter-word", padding:"0% 7%",fontFamily:"Arial"}}>
        
      <h1 style={{marginBottom:"1vh"}}>Order Trophy and Certificate</h1>
        <br/>
        <h5>
          Please select the item you want and pay online below. You may then proceed to entering your shipment information with the button below.
        </h5>
        <div style={{display:"flex",flexDirection:"column",alignItems:"left",padding:"5px",border:"1px solid white",background:"rgba(255,255,255,0.9)",paddingLeft:"5%",paddingRight:"5%",color:"#1c1f1d",borderRadius:"10px",marginTop:"4%"}}>
            
            <h2  style={{marginLeft:"1%",marginTop:"2%",marginBottom:"3%"}}>Pay now with Credit Card or PayPal 
              <p style={{fontSize:"0.56em",textAlign:"justify"}}>*Service charge may be applied. Please double check your amount in the paypal pop-up window. Remember to take a screenshot after successful payment. 
                If you forgot, you may take a screenshot of your payment receipt email from PayPal.</p></h2>
            
                <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      {this.state.itemType}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                     <Dropdown.Item onClick={(e)=>{
                        this.setState({itemType:"Trophy, Certificate AND Reference Letter",productDescription:"IYPO Trophy, Certificate, Reference Letter and Shipment",awardPayment:"100"})
                      }}>Trophy Certificate, AND Reference Letter</Dropdown.Item>
                    <Dropdown.Item onClick={(e)=>{
                        this.setState({itemType:"Trophy AND Certificate",productDescription:"IYPO Trophy, Certificate and Shipment",awardPayment:"80"})
                      }}>Trophy AND Certificate</Dropdown.Item>
                      <Dropdown.Item onClick={(e)=>{
                        this.setState({itemType:"Trophy ONLY",productDescription:"IYPO Trophy and Shipment",awardPayment:"65"})
                      }}>Trophy ONLY</Dropdown.Item>
                     <Dropdown.Item onClick={(e)=>{
                        this.setState({itemType:"Certificate ONLY",productDescription:"IYPO Certificate and Shipment",awardPayment:"25"})
                      }}>Certificate ONLY</Dropdown.Item>
                       {/* <Dropdown.Item onClick={(e)=>{
                        this.setState({itemType:"Testing",productDescription:"Testing",awardPayment:"0.01"})
                      }}>Test</Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>

            <br></br>

                {
                  (this.state.itemType==="Trophy, Certificate AND Reference Letter") && 
                  <h3 style={{marginTop:"2%",marginLeft:"2%",marginRight:"2%"}}>
                    <i>{"Trophy, Certificate, Reference Letter and Shipment Fee : USD "+this.state.awardPayment}</i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </h3>
                }
                {
                  (this.state.itemType==="Trophy AND Certificate") && 
                  <h3 style={{marginTop:"2%",marginLeft:"2%",marginRight:"2%"}}>
                    <i>{"Trophy, Certificate and Shipment Fee : USD "+this.state.awardPayment}</i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </h3>
                }
                {
                  (this.state.itemType==="Trophy ONLY") && 
                  <h3 style={{marginTop:"2%",marginLeft:"2%",marginRight:"2%"}}>
                    <i>{"Trophy and Shipment Fee : USD "+this.state.awardPayment}</i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </h3>
                }

                {
                  (this.state.itemType==="Certificate ONLY") && 
                  <h3 style={{marginTop:"2%",marginLeft:"2%",marginRight:"2%"}}>
                    <i>{"Certificate and Shipment Fee : USD "+this.state.awardPayment}</i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </h3>
                }

                {
                  (this.state.itemType==="Testing") && 
                  <h3 style={{marginTop:"2%",marginLeft:"2%",marginRight:"2%"}}>
                    <i>{"Certificate and Shipment Fee : USD "+this.state.awardPayment}</i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </h3>
                }
              

            <div style={{marginTop:"3%",marginLeft:"10%",marginRight:"10%"}}>
         {/* { <PaypalCheckoutButton  product={{description:"IYAO",price:52}}/>} */}
         <AwardCheckout {...this.state}/>
          </div>

          
          </div>

     <div style={{marginTop:"4%"}}>
          <h4 className="apply-body-font" style={{fontFamily:"Arial",fontWeight:"bolder",fontSize:"2rem"}}>If you have your payment receipt, you may now click the button below and fill in your shipment information.</h4>
          </div>
          
          <a onClick={(e)=>{this.clickapply()}}><button id="goldbutton-apply" style={{fontSize:"160%",width:"50%"}} >
          Apply for Award</button></a>
          
        

        {/* paypalends */}

        

        <h1 style={{marginTop:"5%",marginBottom:"1vh"}}>Awards</h1>
        <br/><h5>For teachers:</h5>

        <ul>
          <li>
            <h5><b>Excellent Mentorship Award</b> - Teachers of Champion, Second Prize, Third Prize and Fourth Prize winners</h5>
          </li>
          <li>
          <h5> <b>Active Art Educator Award</b> - Any teacher who recommends four students or above across any groups</h5>
          </li>
        </ul>
        
        <br/>
        <h5>For centers and institutions:</h5>

        <ul>
          <li>
          <h5> <b>Active Art Institution Award</b> - Any organization which recommended 6 contestants or above across any groups</h5>
          </li>
          <li>
          <h5> <b>Elite Art Institution Award</b> - Any organization with 4 or more associated contestants receiving Champion, 2nd, 3rd or 4th Prize.</h5>
          </li>
        </ul>
        
        <br/>
        
        <h5>For students:</h5>

        <ul>
          <li>
          <h5><b>Champion to 4th Prize winners</b> - Contestants may apply for a reference letter.</h5>
          </li>
          <li>
          <h5><b>Gold Award</b> - Contestants with marks above 80</h5>
          </li>
          <li>
          <h5><b>Silver Award</b> - Contestants with marks above 70</h5>
          </li>
          <li>
          <h5><b>Bronze Award</b> - Contestants with marks above 60</h5>
          </li>
          <br/>
          <li>
          <h5>Teachers of our winners will be invited to the Outstanding Art Teacher List, and be invited for an interview to be featured on our magazine.

Winners will also be invited to feature in our magazine and displayed in winner posters on our social media.</h5>
          </li>
          <li>
          <h5>Prizes will be in the form of e-certificates sent as PDF.</h5>
          </li>
        </ul>
        
        <br/>


        <div style={{width:"100%",overflowX:"scroll",overflowY:"hidden",msOverflowStyle:"none",scrollbarWidth:"none"}} >
          <table style={{width:"100%"}}>
            <tr>
              <th style={{whiteSpace: "nowrap"}}>&nbsp;&nbsp;Places</th>
            </tr>
            <tr>
              <td style={{border:"1px solid black"}}>Champion</td>
              <td style={{border:"1px solid black"}}>Second Prize</td>
              <td style={{border:"1px solid black"}}>Third Prize</td>
              <td style={{border:"1px solid black"}}>Fourth Prize</td>
            </tr>
          </table>


          <br></br>

          <table style={{width:"100%"}}>
            <tr>
              <th style={{whiteSpace: "nowrap"}}>&nbsp;&nbsp;Outstanding Awards</th>
            </tr>
            <tr style={{}}>
              <td style={{border:"1px solid black",textAlign:"center",width:"33%"}}>Gold Award</td>
              <td style={{border:"1px solid black",textAlign:"center",width:"33%"}}>Silver Award</td>
              <td style={{border:"1px solid black",textAlign:"center",width:"33%"}}>Bronze Award</td>
            </tr>
          </table>

          
          <br></br>

          <table style={{minWidth:"100%"}}>
            <tr>
              <th style={{whiteSpace: "nowrap"}}>&nbsp;&nbsp;Teacher Awards</th>
            </tr>
            <tr>
              <td style={{border:"1px solid black"}}>Active Art Educator Award</td>
              <td style={{border:"1px solid black"}}>Excellent Mentorship Award</td>
            </tr>
          </table>

        </div>





        {/* <h1 style={{marginTop:"7%",marginBottom:"1vh"}}>Awards</h1>
        <ul>

        <li><h5>In each category there are <b>Champion</b>, <b>Second Prize Winner</b>, <b>Third Prize Winner</b> and <b>Fourth Prize</b>. Teachers of First, Second, and Third Prize Winners will receive the <b>Outstanding Tutor Award</b>. </h5></li>
        <li><h5>{"Chamapions to Fourth Prize Winners may order our Reference Letter."}</h5></li>
        <li><h5>{"Candidates with score >=85 will receive the Gold Prize, 80-84 the Silver Prize, 65-80 the Bronze Prize, and score under 65 will receive our diploma."}</h5></li>
        <li><h5>{"Champions, Second Prize and Third Prize Winners will be complimentarily invited to the next International Young Fine Art Artist Open Competition."}</h5></li>
        <li><h5>Any teacher who recommends four students or above across any groups will receive an <b>Excellent Mentorship Award</b>. </h5></li>
        <li><h5>Any center or school recommended eight students or above will receive the <b>Outstanding Education Institution Award</b>.</h5></li>
        <li><h5>Prizes will be in the form of e-certificates<sup>1</sup>.</h5></li>

        </ul> */}

        {/* { "Champions,upon announcement, may also receive a winner's trophy on request."} */}
        <br></br>
        <h4 className="apply-body-font">With full mark as 100, contestants will be evaluated with the following rubrics:</h4>
        <ol style={{listStyleType:"upper-roman"}}>
          <li><h4 style={{fontSize:"1.3em"}}>{"Creativity (40%)"}</h4></li>
          <li><h4 style={{fontSize:"1.3em"}}>{"Technique (20%)"}</h4></li>
          <li><h4 style={{fontSize:"1.3em"}}>{"Use of Color (20%)"}</h4></li>
          <li><h4 style={{fontSize:"1.3em"}}>{"Composition (20%)"}</h4></li>
          {/* <li><h4 style={{fontSize:"1.3em"}}>Musicality</h4></li>
          <li><h4 style={{fontSize:"1.3em"}}>Style</h4></li>
          <li><h4 style={{fontSize:"1.3em"}}>Tone Color</h4></li>
          <li><h4 style={{fontSize:"1.3em"}}>Presentation</h4></li> */}
        </ol>
        <br></br>
        
    {/* <table style={{maxWidth:"100%",padding:"0% 1%"}}>
    <tr>
        <td>Marks</td>
        <td>Class </td>
    </tr>
    <tr>
        <td>&lt;&nbsp;65 </td>
        <td>Severe impairment of submission’s integrity  </td>
    </tr>
    <tr>
        <td>65-75 </td>
        <td>Below satisfactory </td>
    </tr>
    <tr>
        <td>75-80 </td>
        <td>Satisfactory </td>
    </tr>
    <tr>
        <td>80-85 </td>
        <td>Merit </td>
    </tr>
    <tr>
        <td>85-90 </td>
        <td>Distinction and/or Prizes </td>
    </tr>
    <tr>
        <td>&gt;&nbsp;90 </td>
        <td>Distinction and/or Prizes </td>
    </tr>
   
</table> */}

      {<div style={{marginTop:"6%",fontFamily:"Arial",fontSize:"1em"}}>
        <p><sup>1</sup><i>For trophies and paper certificates, please notify us within 7 days via email after receiving your results.</i></p>
      </div>}
      

      {/* <h1 style={{marginTop:"7%",marginBottom:"1vh"}}>Champion List</h1>
                <br></br> */}

      {/* <div style={{justifyContent:"center",display:"flex",flexDirection:"column",fontSize:"1.3rem"}}>
      <table className="award-laureate-table" >
      {
        Object.keys(laureate).map((key,item)=>{
          return(  
                <tr style={{border:"1px solid white"}}>
                  <td style={{textAlign:"center",borderRight:"1px solid white"}}>{key}</td>
                  <td style={{textAlign:"center"}}>{laureate[key]}</td>
                </tr>    
          )
        })
      }
      </table>
      </div> */}
      

      
      </div>
      </div>
    );
  }
  }
  

export default IYFAOAwards;
