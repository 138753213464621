import '../../App.css';
import React, {Component, Container} from 'react';
import {Image,Navbar, Nav, NavItem, NavDropdown} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import YoutubeBackground from 'react-youtube-background';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import NavBarTop from "./navbar";
import Footer from "./footer";
const applyurl = "https://docs.google.com/forms/d/e/1FAIpQLScoSIrXM_CqsXcaw6mSZmB8f6E1MoXcis3QFeRaE_SO6cP_5A/viewform?usp=sf_link"

class Terms extends Component {
  constructor(props){
    super(props);
    this.state = {  
       
    };

    this.setval = this.setval.bind(this)
  }
  componentDidMount(){
    document.title="International Classical Music Talent Competition"
    var link1 = document.getElementById('favicon')
    var link2 = document.getElementById('touchicon')
    link1.href="/assets/icmt_logo.PNG"
    link2.href="/assets/icmt_logo.PNG"
    this.props.setval({comp:"icmt"}) 
  }
  setval(obj){
    this.setState(obj)
  }

  render(){
    return (
      <div>
       {/* <img src="/assets/term.png" width="100%" style={{borderRadius:"0px",position:'absolute',top:"0",bottom:"0",left:"0",right:"0",opacity:"0.3",height:"100vh"}} /> */}
      <div style={{display:"flex",flexDirection:"column",paddingLeft:"15%",paddingRight:"15%",paddingTop:"4%",paddingBottom:"2%",textAlign:"left",fontFamily:"Courgette",color:"black",backgroundImage:"url('/assets/term.png')",
      filter:"brightness(100%)",opacity:"0.7",backgroundBlendMode:"luminosity",backgroundRepeat:"no-repeat",backgroundSize:"100vw 100vh",maxHeight:"100vh",overflow:"scroll"}}>
      <h4 style={{fontSize:"1.8em",fontWeight:"bold"}}><u><i>Regulations</i></u></h4>
      <ul style={{textAlign:"justify",fontWeight:"bold"}}>
        <li><p style={{fontSize:"1.4em"}}>The competition welcomes global applicants. There are no limitations on an applicant's origin. </p></li>
        <li><p style={{fontSize:"1.4em"}}>All jury members are not allowed to judge their own students’ works or to enter into the competition as a contestant.</p></li>
      <li><p style={{fontSize:"1.4em"}}>Contestants are not allowed to contact any jury members.</p></li>
      <li><p style={{fontSize:"1.4em"}}>Results and comments by jury members are non-disputable and final .</p></li>
      <li><p style={{fontSize:"1.4em"}}>Contestants who have broken the competition rules and regulationsrefuse may be disqualified. </p></li>
      <li><p style={{fontSize:"1.4em"}}>Contestants may perform from memory or with scores.</p></li>
      <li><p style={{fontSize:"1.4em"}}>A contestant may apply for more than one competition group. </p></li>
      <li><p style={{fontSize:"1.4em"}}>Given that a contestant does not submit the same work, a contestant may submit more than one work to the same group.</p></li>
      </ul>
      
     
    
    <br></br>

    <h4 style={{fontSize:"1.8em",fontWeight:"bold"}}><u><i>Terms</i></u></h4>
    <ul style={{textAlign:"justify",fontWeight:"bold"}}>
    <li><p style={{fontSize:"1.4em"}}>All application fees are non-refundable.</p></li>
    <li><p style={{fontSize:"1.4em"}}>The organizer has the right to amend competition rules, content of prizes and jury composition.</p></li>
    <li><p style={{fontSize:"1.4em"}}>The organizer has the ultimate interpretation right.</p></li>
    <li><p style={{fontSize:"1.4em"}}>Other rules and regulations not posted on this page shall act as reference only and the organizer owns the right to interpretation.</p></li>
    <li><p style={{fontSize:"1.4em"}}>All information of the contestant will be confidential, and will only be used for the purpose of notification and promotion matters related to this competition.</p></li>
    <li><p style={{fontSize:"1.4em"}}>Any change of any part of the application or submission information may be subject to an administration fee up to USD $15 or equivalent. </p></li>
    </ul>
       <br></br>

{/* 
        <h2>2022 International Young Artist Open Competition</h2>
        <h4>Deadline: 15th November 2022</h4>
        <h4>Results Date: 19th December 2022</h4>
        <br/>      

      <br/><br/>
    <h6>After successful payment, we will send a confirmation email to you.</h6>
    <p style={{fontSize:"0.4em"}}>*All application fees are non-refundable.</p> */}
      </div>
      </div>
    );
  }
  }
  

export default Terms;
