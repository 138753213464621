import '../../App.css';
import React, {Component, Container} from 'react';
import {Image,Navbar, Nav, NavItem, NavDropdown} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import YoutubeBackground from 'react-youtube-background';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import NavBarTop from "./navbar";
import Footer from "./footer";
const applyurl = "https://docs.google.com/forms/d/e/1FAIpQLScoSIrXM_CqsXcaw6mSZmB8f6E1MoXcis3QFeRaE_SO6cP_5A/viewform?usp=sf_link"

class IcmtCnJury extends Component {
  constructor(props){
    super(props);
    this.state = {  
       
    };

    this.setval = this.setval.bind(this)
  }
  componentDidMount(){
    this.props.setval({comp:"icmt-cn"})
  }
  setval(obj){
    this.setState(obj)
  }

  render(){
    return (
      <div>
       {/* <img src="/assets/term.png" width="100%" style={{borderRadius:"0px"}} /> */}
      <div style={{display:"flex",flexDirection:"column",paddingLeft:"15%",paddingRight:"15%",paddingTop:"3%",paddingBottom:"2%",textAlign:"left"}}>
      <h4 style={{fontSize:"1.8em",textAlign:"center",marginBottom:"100px"}}>{"首席评审委员"}</h4>

      <div style={{display:'block', marginBottom:"100px"}}>
        <div className="jury-profile">
            <img src="/assets/andy.jpg" alt="" width="100%" />
            <h4 style={{marginTop:"5px"}}><b>{"安德烈 亚斯斯蒂尔 教授"}</b></h4>
            <h5><em>{"钢琴家及教育家"}</em></h5>
            </div>
        <div style={{position:"absolute"}}>
            <img className="jury-flag" alt="" src="/assets/germany-flag.png" />
        </div>
        <div className="jury-text">
            <p>{"亚斯斯蒂尔教授出生于德国汉诺威。 1983年春，他毕业于汉堡大学汉学专业，获文学硕士学位，并于汉堡音乐与剧院音乐学院（HfMT）主修钢琴。"
                 +"随后，他开始在汉堡音乐学院和剧院担任钢琴教授。 在 HfMT 工作的 38 年里，他还教过许多来自中国的学生，"
                 +"韩国和台湾，试图让他们更接近西方音乐和作曲家的心态。"}
                </p>
                <p>{"他曾担任德国官方音乐青年比赛“Jugend Musiziert”的评委"
                 +"为独奏钢琴和钢琴室内乐演奏了三十多年。"}
            </p>
        </div>
    </div>

    <div style={{display:'block', marginBottom:"100px"}}>
        <div className="jury-profile">
            <img src="/assets/dominic.jpg" alt=""  width="100%"/>
            <h4 style={{marginTop:"5px"}}><b>{"多米尼克 萨金特博士"}</b></h4>
            <h5><em>{"指挥家和音乐教育家"}</em></h5>
            </div>
        <div style={{position:"absolute"}}>
            <img className="jury-flag" alt="" src="/assets/uk-flag.png"/></div>
        <div className="jury-text">
            <p> 
            {"萨金特博士的音乐生涯始于 6 岁时加威尔士的班戈大教堂合唱团"
             +"，并开始上钢琴课。 其除後"
             +"获得剑桥大学三一学院奖学金，继续他的音乐剧研究"
             +"师从美国皮博迪音乐学院指挥弗雷德里克·普劳斯尼茨 (Frederik Prausnitz)，并获得音乐艺术博士学位。"}</p>
            <p>{"萨金特博士曾在欧洲和亚洲广泛旅行，从事教学、作曲和表演。 他是"
             +"被称为高效的实践者。 他对表演的不懈倡导"
             +"艺术给他带来了许多有益和丰富的经历，他希望"
             +"与世界各地的年轻音乐家分享。"}</p>
        </div>
    </div>

    <div style={{display:'block', marginBottom:"100px"}}>
        <div className="jury-profile">
            <img src="/assets/julia.jpg" alt="" width="100%"/>
            <h4 style={{marginTop:"5px"}}><b>{"朱莉娅 布什科娃教授"}</b></h4>
            <h5><em>{"小提琴合奏团'Violunti'的音乐总监"}</em></h5>
            </div>
        <div style={{position:"absolute"}}>
            <img className="jury-flag" alt="" src="/assets/usa-flag.jpg" /></div>
        <div className="jury-text">
            <p>
            {"布什科娃教授是美国北德克萨斯大学的小提琴教授。 她是当代最强大的小提琴教育家之一。 她的学生可以在所有主要的交响乐中找到"
             +"美国的管弦乐队和室内乐团，并作为教师在各大学和音乐学院任教。 许多人在学习期间赢得了国内和国际比赛"
             +"她的指导。"}</p>
            <p>{"作为一名表演者，她被描述为拥有“非凡的表现力、异常宏大优美的音色和非凡的技术执行力”（安阿伯新闻），"
                 +"“最高雅的俄罗斯小提琴传统。” 她作为独奏家和独奏家在美国、欧洲、亚洲、加拿大、南美洲和南非演出。"
                 +"她曾在众多电视和广播电台担任专题节目，包括 CBS 公共广播电台（加拿大）和 NPR电台。 Julia 曾为 Crystal Records 和 Toccata Classics 录制唱片。"}
        </p></div>
    </div>

    <div style={{display:'block', marginBottom:"100px"}}>
        <div className="jury-profile">
            <img src="/assets/arsentiy.jpg" alt=""  width="100%"/>
            <h4 style={{marginTop:"5px"}}><b>{"阿森蒂 哈里托诺夫教授"}</b></h4>
            <h5><em>{"世界知名钢琴家和及作曲家"}</em></h5>
            </div>
        <div style={{position:"absolute"}}>
            <img className="jury-flag" alt="" src="/assets/russia-flag.png" /></div>
        <div className="jury-text">
            <p>
            {"哈里托诺夫教授来自圣彼得堡，是李斯特国际钢琴比赛（美国）的一等奖获得者。在赢得拉赫玛尼诺夫比赛（俄罗斯联邦）后，他开始了他的音乐生涯，其足迹遍及欧洲各地，"
            +"包括在爱乐音乐厅、圣彼得堡的马林斯基剧院、斯德哥尔摩皇家音乐学院和赫尔辛基的西贝柳斯学院演出，随后教授亦在亚洲巡回演出，包括在上海、北京和首尔等地的主要大厅。"}
            </p><p>
           {"作为作曲家，他在2020年俄罗斯全国作曲家大赛中同样获得一等奖。大纪元评论称赞他的 “和声斩波，这在当今世界是罕见的作曲家。你可以听到肖斯塔科维奇和普罗科菲耶夫在火花边缘，"
           +"但它的心脏似乎在拉赫玛尼诺夫的抒情中。 听到这些充满活力的作品真的很高兴。”"}
       </p></div>
    </div>

    <div style={{display:'block', marginBottom:"100px"}}>
        <div className="jury-profile">
            <img src="/assets/abraham.png" alt=""  width="100%"/>
            <h4 style={{marginTop:"5px"}}><b>{"亚伯拉罕 特纳 曼里克教授"}</b></h4>
            <h5><em>{"世界钢琴教师作曲协会主席（西班牙）"}</em></h5>
            </div>
        <div style={{position:"absolute"}}>
            <img className="jury-flag" alt="" src="/assets/spain-flag.png" /></div>
        <div className="jury-text"><p>
        {"曼里克教授目前是圣安德鲁市立音乐大學的钢琴和作曲系教授。"}
        </p>
        <p>{"曼里克教授被任命为 世界钢琴教师协会主席。此协会是一个存在于全球 30 多个国家/地区的协会，每年组织会举办会议、钢琴、作曲、和室内乐比赛，以及独奏会和大师班。"
        +"在2021年和2022年，他作为客座作曲家参加了西班牙世界钢琴教师协会和MIMAS音乐节两个国际比赛和音乐节。许多参赛者曾表演了他的作品。同时他的一首作品获得了一项最佳表演者奖学金。"}
         </p></div>
    </div>

    <div style={{display:'block', marginBottom:"100px"}}>
        <div className="jury-profile">
            <img src="/assets/antonio.jpg" alt=""  width="100%"/>
            <h4 style={{marginTop:"5px"}}><b>{"安东尼奥 弗兰克 贾迪利诺 马西尔博士"}</b></h4>
            <h5><em>{"长号演奏家及音乐教育家"}</em></h5>
            </div>
        <div style={{position:"absolute"}}>
            <img className="jury-flag" alt="" src="/assets/italy-flag.png" /></div>
        <div className="jury-text"><p>
            {"马西尔博士毕业于宗座慈幼大学，获得音乐疗法硕士学位。 随后，他获得了葡萄牙埃武拉大学的博士学位。 这些年他还工作於塞阿拉州长号新学士课程的教育项目，此為为同一州份的頂尖大学编写的课程。 他是 Ceara 政府州（巴西）的贵宾，"
            +"其邀请他参加了數次參與“Forum dos trombonistas Cearences”以及他在各种大师班中任教的地方項目。"}</p><p>
            {"他在“I solisti Europei”管弦乐队担任长号独奏，并作为“Mascoulisse”长号四重奏的成员。 他在他工作的佩斯卡拉交响乐团赢得了第一次长号试奏。在急速运行的樂季以及几个歌剧季期间，他还与 Societa Filarmonica di Udine 和 Orchestra Regionale Marchigiana 合作。 弗兰克赢得了其意大利舞台的選拔，他是该樂團的长号首席。"}
        </p></div>
    </div>

    <div style={{display:'block', marginBottom:"100px"}}>
        <div className="jury-profile">
            <img src="/assets/melissa.png"  alt="" width="100%"/>
            <h4 style={{marginTop:"5px"}}><b>{"梅丽莎·索罗汉"}</b></h4>
            <h5><em>{"歌剧演唱家及大学声乐教师"}</em></h5>
            </div>
        <div style={{position:"absolute"}}>
            <img className="jury-flag" alt=""  src="/assets/usa-flag.jpg" /></div>
        <div className="jury-text">
        <p>
           {"歌唱家索罗汉毕业于美国俄亥俄州克利夫兰的克利夫兰音乐学院，获得声乐学士学位。 然后，她在俄亥俄州的阿克伦大学获得了声乐硕士学位。 在学习期间，Melissa 通过参加 Sessene Senese per La 在意大利和瑞士演唱。Musica e L'Arte 总部位于意大利锡耶纳。 她还是 Resonanz Opera 夏季项目的参与者，并通过 Nightingale Opera Theatre 参加了大师班。"}
           </p><p>
            {"索罗汉女士被任命为俄亥俄州坎顿马龙大学的声乐教师。 目前，Melissa 是香港的一名表演者，与 World Family Club 和 Dove Tails Theatre 合作。 她还与圣约翰大教堂晚祷合唱团、香港巴赫合唱团和塞西利安合唱团一起演唱古典歌曲。"}
        </p>
        </div>
    </div>

    
    <div style={{display:'block', marginBottom:"100px"}}>
        <div className="jury-profile">
            <img src="/assets/alexandre.jpg" alt=""  width="100%"/>
            <h4 style={{marginTop:"5px"}}><b>{"亚历山大 特赫"}</b></h4>
            <h5><em>{"钢琴家和音乐教育家"}</em></h5>
            </div>
        <div style={{position:"absolute"}}>
            <img className="jury-flag" alt="" src="/assets/belgium-flag.jpg" /></div>
        <div className="jury-text"><p>
            {"特赫出生于1959年的巴黎，师从 Frederic Delvaux，并在 Academie Gretry 里，从 Jeanne Bernard-Knapen 大师下开始他的音乐学习。随后，他在Academie Gretry 随 Chantal Bohets 大师和 Marcelle Mercenier 学习。"
            +"后来他获得了比利时政府的资助带领他到华沙的肖邦学院旅行和学习。"}</p><p>
             {"Alexandre 拥有 20 多年的教学经验，定期在 Academie Gretry 任教，并在音乐会上表演，特别是与小提琴家 Benoit Paquay 合作。"}
        </p></div>
    </div>

      </div>
      </div>
    );
  }
  }
  

export default IcmtCnJury;
