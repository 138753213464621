import '../../App.css';
import React, {Component, Container} from 'react';
import {Image,Navbar, Nav, NavItem, NavDropdown} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import YoutubeBackground from 'react-youtube-background';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import NavBarTop from "./navbar";
import Footer from "./footer";
const applyurl = "https://docs.google.com/forms/d/e/1FAIpQLScoSIrXM_CqsXcaw6mSZmB8f6E1MoXcis3QFeRaE_SO6cP_5A/viewform?usp=sf_link"

class Jury extends Component {
  constructor(props){
    super(props);
    this.state = {  
       
    };

    this.setval = this.setval.bind(this)
  }
  componentDidMount(){
    document.title="International Classical Music Talent Competition"
    var link1 = document.getElementById('favicon')
    var link2 = document.getElementById('touchicon')
    link1.href="/assets/icmt_logo.PNG"
    link2.href="/assets/icmt_logo.PNG"
    this.props.setval({comp:"icmt"})
  }
  setval(obj){
    this.setState(obj)
  }

  render(){
    return (
      <div>
       {/* <img src="/assets/term.png" width="100%" style={{borderRadius:"0px"}} /> */}
      <div style={{display:"flex",flexDirection:"column",paddingLeft:"15%",paddingRight:"15%",paddingTop:"3%",paddingBottom:"2%",textAlign:"left"}}>
      <h4 style={{fontSize:"1.8em",textAlign:"center",marginBottom:"100px"}}>Past and Current Jury</h4>

      <div style={{display:'block', marginBottom:"100px"}}>
        <div className="jury-profile">
            <img src="/assets/andy.jpg" alt="" width="100%" />
            <h4 style={{marginTop:"5px"}}><b>Professor Andreas Stier</b></h4>
            <h5><em>Pianist and Educationist</em></h5>
            </div>
        <div style={{position:"absolute"}}>
            <img className="jury-flag" alt="" src="/assets/germany-flag.png" />
        </div>
        <div className="jury-text">
            <p>Andreas Stier was born in Hannover, Germany. In spring 1983 he graduated from Hamburg University majoring in Sinology with a Master of Arts degree and majored in piano from Hochschule für Musik und Theater Hamburg (HfMT).
                Subsequently, he began to teach as a Professor for Piano at Hochschule für Musik und Theater Hamburg. Within 38 years of workinng at the HfMT he also taught many students from China,
                Korea and Taiwan, trying to bring them closer to the mentality of western music and composers. 
                </p>
                <p>He has been a jury member of the official German music youth competition "Jugend Musiziert"
                for solo piano and piano chamber music for more than three decades.
            </p>
        </div>
    </div>

    <div style={{display:'block', marginBottom:"100px"}}>
        <div className="jury-profile">
            <img src="/assets/dominic.jpg" alt=""  width="100%"/>
            <h4 style={{marginTop:"5px"}}><b>Dr. Dominic Sargent</b></h4>
            <h5><em>Conductor and Music Educationist</em></h5>
            </div>
        <div style={{position:"absolute"}}>
            <img className="jury-flag" alt="" src="/assets/uk-flag.png"/></div>
        <div className="jury-text">
            <p> 
            Dominic Sargent’s musical career began at the age of 6 when he joined the choir
            of Bangor Cathedral in Wales, and started piano lessons. After winning an Organ
            Scholarship to Trinity Hall, Cambridge University, he continued his musical
            studies with Frederik Prausnitz, and graduated with a Doctor of Musical Arts in
            conducting from Peabody Conservatory in the United States. </p>
            <p>Dr Sargent has travelled widely in Europe and Asia, teaching, composing, and performing. He is
            known as a highly effective practitioner. His untiring advocacy for the performing
            arts has brought him many rewarding and enriching experiences which he hopes
            to share with young musicians all over the world. </p>
        </div>
    </div>

    <div style={{display:'block', marginBottom:"100px"}}>
        <div className="jury-profile">
            <img src="/assets/julia.jpg" alt="" width="100%"/>
            <h4 style={{marginTop:"5px"}}><b>Professor Julia Bushkova</b></h4>
            <h5><em>Director of the Ensemble "Violunti"</em></h5>
            </div>
        <div style={{position:"absolute"}}>
            <img className="jury-flag" alt="" src="/assets/usa-flag.jpg" /></div>
        <div className="jury-text">
            <p>
            Julia Bushkova is a Professor of Violin at the University of North Texas, USA. She was one of the strongest contemporary violin pedagogues. Her students may be found in all major symphony
            orchestras in the US and chamber groups and, as teacher, on faculty in various universities and schools of music. Many have won national and international competitions while studying under 
            her mentorship.</p>
            <p>As a performer, she has been described possessing "remarkable expressiveness, an unusually large beautiful tone and extraordinary technical execution" (Ann Arbor News), 
                "a Russian violin tradition of the highest elegance." She has performed as a soloist and recitalist throughout the United States, Europe, Asia, Canada, South America, and South Africa. 
                She has been featured on numerous television and radio stations, including CBS Public Radio (Canada) and NPR's "Performance Today". Julia has recorded for Crystal Records and Toccata Classics.
        </p></div>
    </div>

    <div style={{display:'block', marginBottom:"100px"}}>
        <div className="jury-profile">
            <img src="/assets/arsentiy.jpg" alt=""  width="100%"/>
            <h4 style={{marginTop:"5px"}}><b>Professor Arsentiy Kharitonov</b></h4>
            <h5><em>Concert Pianist and Composer</em></h5>
            </div>
        <div style={{position:"absolute"}}>
            <img className="jury-flag" alt="" src="/assets/russia-flag.png" /></div>
        <div className="jury-text">
            <p>
            Hailing from St. Petersburg, Arsentiy Kharitonov is the first-prize winner of the Franz Liszt International Piano Competition (US). After also winning the Rachmaninov Competition (Russian Federation), he began his concert 
            career with engagements throughout Europe including appearances at Philharmonic Hall, and the Mariinsky Theater in St. Petersburg, at the Royal Conservatory, Stockholm, and the Sibelius Academy in Helsinki, followed by Asian tours with performances
            in the major halls of, among others, Shanghai, Beijing, and Seoul.
            </p><p>
            As a composer, he has been equally well-received winning the First Prize at the All-National Composer's Competition in Russia in 2020. In a review by the Epoch Times, he was praised for his "harmonic chops, which are rare these days among composers.
            You can hear the sparkle and edge of Shostakovich and Prokofiev, but its heart seems to be in the lyricism of Rachmaninoff. It was a real pleasure to hear these dynamic works."
       </p></div>
    </div>

    <div style={{display:'block', marginBottom:"100px"}}>
        <div className="jury-profile">
            <img src="/assets/abraham.png" alt=""  width="100%"/>
            <h4 style={{marginTop:"5px"}}><b>Professor Abraham Tena Manrique</b></h4>
            <h5><em>Composer, pianist, member of FAIC and President of WPTA Spain Composition</em></h5>
            </div>
        <div style={{position:"absolute"}}>
            <img className="jury-flag" alt="" src="/assets/spain-flag.png" /></div>
        <div className="jury-text"><p>
        Currently, Maestro Tena Manrique has made the recording of all his solo piano compositions for the Japanese record label, Da Vinci. 
        Recordings made in the Barcelona Auditorium counting for the long session a Steinway Gran Cola D274, thanks to Pianoservei. 50 works that will appear on 3CDs in a few months. 
        In autumn-winter of this year he will offer some recitals to present many of the compositions. 
        Some have already been performed before by himself and by numerous musicians, as well as dozens of contestants in international piano competitions.
        </p>

        <p>In 2021 the DaVinci publishing house has concluded the ambitious project of publishing all the works composed by Abraham Tena Manrique. 
            The final result is 7 beautiful volumes in which we find works for piano, for piano 4 hands, the concerto for piano and orchestra Op. 42, the oratorio "The seven last words of Christ on the Cross" Op. 40, chamber works with clarinet and a lied.
         </p>
         
         <p>Abraham Tena Manrique is the President of the WPTA-SPAIN-COMPOSITION (World Piano Teachers Association). The WPTA is an association that exists in more than thirty countries around the world, where it organizes annual conferences, piano-playing competitions, composition and chamber music, as well as recitals and master-classes.
       
         </p></div>
    </div>

    <div style={{display:'block', marginBottom:"100px"}}>
        <div className="jury-profile">
            <img src="/assets/antonio.jpg" alt=""  width="100%"/>
            <h4 style={{marginTop:"5px"}}><b>Dr. Antonio Frank Jardilino Maciel</b></h4>
            <h5><em>Principal Trombone and Music Educationist</em></h5>
            </div>
        <div style={{position:"absolute"}}>
            <img className="jury-flag" alt="" src="/assets/italy-flag.png" /></div>
        <div className="jury-text"><p>
            Antonio Frank Jardilino Maciel graduated with a postgraduate degree in Musicotherapy at the Pontifical Salesian University. He then obtained the doctoral diploma at the Universidade de Evora in Portugal.  In these years he also wrote 
            the Educational Project for the new Bachelor courses in trombone in the Ceara State: program written for the leading universities at the same state. He was an honor's guest of the Ceara Government State (Brazil), who invited him to several
            "Forum dos trombonistas Cearences" and where he taught in various masterclasses.</p><p>
            He worked as a solo trombone in the orchestra "I solisti Europei" and as an integrant of the "Mascoulisse" trombone quartet. He won the first trombone audition at the Orchestra Sinfonica di Pescara where he worked
            in a non-stable running in important programs. He also collaborates with the Societa Filarmonica di Udine and Orchestra Regionale Marchigiana during several opera seasons.
            Frank won the audition for the company Stage Entertainment-Italy, where he was the principal Trombone.
        </p></div>
    </div>

    <div style={{display:'block', marginBottom:"100px"}}>
        <div className="jury-profile">
            <img src="/assets/melissa.png"  alt="" width="100%"/>
            <h4 style={{marginTop:"5px"}}><b>Ms Melissa Sorohan</b></h4>
            <h5><em>Opera Singer and University Vocal Instructor</em></h5>
            </div>
        <div style={{position:"absolute"}}>
            <img className="jury-flag" alt=""  src="/assets/usa-flag.jpg" /></div>
        <div className="jury-text">
        <p>
           Melissa graduated with a Bachelor of Music in Voice from Cleveland Institute of Music in Cleveland, Ohio, USA. She then obtained her Master of Music degree in Voice from the University of Akron in Ohio. During her study, Melissa sung in Italy and Switzerland through participating in the Sessione Senese per La 
           Musica e L'Arte based in Siena, Italy. She was also a participant in the Resonanz Opera summer program and participated in masterclasses through Nightingale Opera Theatre.</p><p>
            She was appointed as a voice instructor at Malone University in Canton, Ohio. Currently Melissa is a performer in Hong Kong, working with World Family Club and Dove Tails Theatre. She also sings classically with St. John's Cathedral Evensong Choir, the Hong Kong Bach Choir, and the Cecilian Singers.
        </p>
        </div>
    </div>

    
    <div style={{display:'block', marginBottom:"100px"}}>
        <div className="jury-profile">
            <img src="/assets/alexandre.jpg" alt=""  width="100%"/>
            <h4 style={{marginTop:"5px"}}><b>Mr. Alexandre Teheux</b></h4>
            <h5><em>Pianist and Music Educationist</em></h5>
            </div>
        <div style={{position:"absolute"}}>
            <img className="jury-flag" alt="" src="/assets/belgium-flag.jpg" /></div>
        <div className="jury-text"><p>
            Born in Paris in 1959, Alexandre Teheux studied under Frederic Delvaux, and began his musical studies at Academie Gretry in Liege with Jeanne Bernard-Knapen.
            He then studied in Liege Conservatory with Chantal Bohets and Marcelle Mercenier. He later received a grant from Belgian government which 
            led him to travel and study at Chopin Academy in Warsaw.</p><p>
             With more than 20 years of teaching experience, Alexandre teaches regulary at Academie Gretry, as well as performing in concerts, notably with the violinist Benoit Paquay.
        </p></div>
    </div>

      </div>
      </div>
    );
  }
  }
  

export default Jury;
