import '../../App.css';
import React, {Component, Container} from 'react';
import {Row,Col,Image,Navbar, Nav, NavItem, NavDropdown, Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import YoutubeBackground from 'react-youtube-background';
import Card from './card';
import NavBarTop from "./navbar";
import Footer from "./footer";
import PaypalCheckoutBtn from './elements/paypalcheckout';
import AwardCheckout from './elements/awardcheckout';
import laureate from '../../laureate';

const applyurl2 = "https://docs.google.com/forms/d/e/1FAIpQLScClATIEBdAW7ZX4xUrU9tdBrT4p5PcMVmzwZRl53yjy0GlSw/viewform"
class IcmtCnAwards extends Component {
  constructor(props){
    super(props);
    this.state = {
       itemType:"请选择您的项目",
       productDescription:null,
       awardPayment:0,
    };

    this.setval = this.setval.bind(this)
    this.clickapply = this.clickapply.bind(this)
  }

  clickapply(){
    window.open(applyurl2, '_blank', 'noopener,noreferrer');
}
  setval(obj){
    this.setState(obj)
  }
  componentDidMount(){
    this.props.setval({comp:"icmt-cn"}) 
  }      
      
  render(){
    return (
      <div><img src="/assets/award.png" alt="" width="100%" style={{borderRadius:"0px"}}/>
      <div className="home-content-div" style={{textAlign:"justify",textJustify:"inter-word", padding:"7%",fontFamily:"Courgette"}}>
        
        <h1 style={{marginTop:"100px",marginBottom:"1vh"}}>{"奖项"}</h1>
        <br/><h5>{"各类别的得奖者和杰出奖项如下："} </h5><br/>

        <br/><h5>{"导师奖项:"}</h5>

        <ul>
          <li>
            <h5><b>{"优秀导师奖"}</b> - {"获冠军、第二名、第三名奖得者的教师"}</h5>
          </li>
          <li>
          <h5> <b>{"杰出教学奖"}</b> - {"在任何组中推荐四名或以上学生的老师"}</h5>
          </li>
        </ul>
        
        <br/>
        
        <h5>{"学生奖项："}</h5>

        <ul>
          <li>
          <h5><b>{"冠军到第四名的获胜者"}</b> - {"参赛者将获一封推荐信，并被邀请将提交的作品录制成 CD"}</h5>
          </li>
          <li>
          <h5><b>{"金奖"}</b> - {"85分或以上的参赛者"}</h5>
          </li>
          <li>
          <h5><b>{"银奖"}</b> - {"75分或以上的参赛者"}</h5>
          </li>
          <li>
          <h5><b>{"铜奖"}</b> - {"65分或以上的参赛者"}</h5>
          </li>
          <br/>
          <li>
          <h5>{"冠军将被邀请在我们的网站和其他社交媒体平台上展示他们的个人资料照片。"} </h5>
          </li>
          <li>
          <h5>{"奖品将以电子证书的形式以 PDF 格式发送。"}</h5>
          </li>
        </ul>
        
        <br/>


        <div style={{width:"100%",overflow:"scroll"}} >
        <table>
          <tr>
            <th>&nbsp;&nbsp;{"名次优胜者"}</th>
          </tr>
          <tr>
            <td style={{border:"1px solid black"}}>{"第一名"}</td>
            <td style={{border:"1px solid black"}}>{"第二名"}</td>
            <td style={{border:"1px solid black"}}>{"第三名"}</td>
            <td style={{border:"1px solid black"}}>{"第四名"}</td>
          </tr>
        </table>


        <br></br>

       
        <table>
          <tr>
            <th>&nbsp;&nbsp;{"杰出奖项"}</th>
          </tr>
          <tr style={{}}>
            <td style={{border:"1px solid black",textAlign:"center",width:"33%"}}>{"金奖"}</td>
            <td style={{border:"1px solid black",textAlign:"center",width:"33%"}}>{"银奖"}</td>
            <td style={{border:"1px solid black",textAlign:"center",width:"33%"}}>{"铜奖"}</td>
          </tr>
        </table>

        
        <br></br>

        <table>
          <tr>
            <th>&nbsp;&nbsp;{"导师奖项"}</th>
          </tr>
          <tr>
            <td style={{border:"1px solid black"}}>{"优秀导师奖"}</td>
            <td style={{border:"1px solid black"}}>{"杰出教学奖"}</td>
          </tr>
        </table>

        </div>

        <br/><br/>


        <h4 className="apply-body-font">{"满分为100分，评判标准包括（但不限于）以下内容"}<sup>*</sup>:</h4>
        <br/>

        <div style={{width:"100%",overflow:"scroll"}} >
        <table>
          <tr>
            <th>&nbsp;&nbsp;{"评分标准"}</th>
          </tr>
          <tr>
            <td style={{border:"1px solid black",textAlign:"center"}}>{"演奏技巧"}</td>
            <td style={{border:"1px solid black",textAlign:"center"}}>{"乐感"}</td>
            <td style={{border:"1px solid black",textAlign:"center"}}>{"音乐风格"}</td>
            <td style={{border:"1px solid black",textAlign:"center"}}>{"音色"}</td>
            <td style={{border:"1px solid black",textAlign:"center"}}>{"视觉表达"}</td>
          </tr>
        </table>
        </div>
        
        <br></br>

        
        
    <table style={{maxWidth:"100%",padding:"0% 1%"}}>
    <tr>
        <td>{"分数"}</td>
        <td>{"等级"}</td>
    </tr>
    <tr>
        <td>&gt;&nbsp;90 </td>
        <td>{"优胜者或金奖"}</td>
    </tr>
    <tr>
        <td>80-90</td>
        <td>{"优胜者或金奖"}</td>
    </tr>
    <tr>
        <td>70-80 </td>
        <td>{"银奖"}</td>
    </tr>
    <tr>
        <td>60-70</td>
        <td>{"铜奖"}</td>
    </tr>
    <tr>
        <td>&lt;&nbsp;60</td>
        <td>{"证书"}</td>
    </tr>
   
</table>

      {/* {<div style={{marginTop:"6%",fontFamily:"Courgette",fontSize:"1em"}}>
        <p><sup>1</sup><i>For trophies and paper certificates, please notify us within 7 days via email after receiving your results.</i></p>
      </div>} */}
      

      {/* <h1 style={{marginTop:"7%",marginBottom:"1vh"}}>Champion List</h1>
                <br></br>

      <div style={{justifyContent:"center",display:"flex",flexDirection:"column",fontSize:"1.3rem"}}>
      <table className="award-laureate-table" >
      {
        Object.keys(laureate).map((key,item)=>{
          return(  
                <tr style={{border:"1px solid white"}}>
                  <td style={{textAlign:"center",borderRight:"1px solid white"}}>{key}</td>
                  <td style={{textAlign:"center"}}>{laureate[key]}</td>
                </tr>    
          )
        })
      }
      </table>
      </div>
       */}

      
      </div>
      </div>
    );
  }
  }
  

export default IcmtCnAwards;
