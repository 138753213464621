import React from 'react'
import { Navbar,Nav,NavDropdown,Form,FormControl,Button,Dropdown } from 'react-bootstrap'

const menu = {
    "en":{
        "home":"HOME", "about":"ABOUT", "category":"CATEGORY", "apply":"APPLY", "awards":"AWARDS","terms":"RULES","jury":"JURY","contact":"CONTACT","winners":"WINNERS"
    },
    "zh":{
        "home":"主頁", "quote":"實時報價", "aboutus":"關於我們", "awards":"Awards", "ourservice":"我們的業務","ourproduct":"我們的產品", "dropdown":"更多","rtprice":"實時市場價格","fnews":"財經新聞","histprice":"歷史價格圖表","riskman":"風險管理及入市計劃","traderecord":"交易詳情","memberrank":"會員階級制度"
    },
    "cn":{
        "home":"主页", "aboutus":"关于我们", "category":"組别", "apply":"報名", "awards":"獎項","terms":"比赛规则","jury":"评委","contact":"联系我们","winners":"得奖者"
    },
}

class icmtCnNavBarTop extends React.Component{
    constructor(props){
        super(props);
        this.state = {  
          
        };
    
        this.clicknav = this.clicknav.bind(this)

      }
    componentDidMount(){
        this.props.setval({comp:"icmt-cn"})
    }
      clicknav(page,pos){
        if(page===this.props.pageSelected){
            document.getElementById(pos).scrollIntoView();
        }else {
            this.props.setval({pageSelected:page,pagepos:pos});           
        } 
      }
//{this.props.companyname[this.props.lang]}
    render(){
        return(
            <div>
                <div className="row">
                    <div className={["col-md-12","fullwidth"].join(" ")}>
                       
                            <Navbar sticky="top" expand="lg" className={["rednav"].join(" ")}>
                                <Navbar.Brand className="navbar-brand" href="/icmt-cn"><img src="/assets/icmt_logo.PNG" width="100px" height="70px" /></Navbar.Brand>
                                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                <Navbar.Collapse className="navbar-collapse" id="basic-navbar-nav">
                                    <Nav className="mr-auto">
                                    <Nav.Link style={{fontFamily:"Optima",fontSize:"1.5rem",color:"white",fontWeight:"bold",marginLeft:"10px"}} href="/icmt-cn" onClick={(e)=>{
                                        //this.props.setval({pageSelected:"home"})
                                        }}>{menu["cn"]["home"]}</Nav.Link>
                                    {/* <Nav.Link style={{fontFamily:"Optima",fontSize:"1.6em",color:"white",fontWeight:"bold",marginLeft:"10px"}} href="/icmt-cn/about" onClick={(e)=>{
                                        //this.props.setval({pageSelected:"about"})
                                        }}>{menu["cn"]["about"]}</Nav.Link> */}
                                    <Nav.Link style={{fontFamily:"Optima",fontSize:"1.5rem",color:"white",fontWeight:"bold",marginLeft:"10px"}} href="/icmt-cn/apply"onClick={(e)=>{
                                        //this.props.setval({pageSelected:"apply"})
                                        }}>{menu["cn"]["apply"]}</Nav.Link>
                                    <Nav.Link style={{fontFamily:"Optima",fontSize:"1.5rem",color:"white",fontWeight:"bold",marginLeft:"10px"}} href="/icmt-cn/category" onClick={(e)=>{
                                        //this.props.setval({pageSelected:"category"})
                                        }}>{menu["cn"]["category"]}</Nav.Link>
                                    <Nav.Link style={{fontFamily:"Optima",fontSize:"1.5rem",color:"white",fontWeight:"bold",marginLeft:"10px"}} href="/icmt-cn/awards" onClick={(e)=>{
                                        //this.props.setval({pageSelected:"awards"})
                                        }}>{menu["cn"]["awards"]}</Nav.Link>
                                    <Nav.Link style={{fontFamily:"Optima",fontSize:"1.5rem",color:"white",fontWeight:"bold",marginLeft:"10px"}} href="/icmt-cn/terms" onClick={(e)=>{
                                        //this.props.setval({pageSelected:"terms"})
                                        }}>{menu["cn"]["terms"]}</Nav.Link>
                                    <Nav.Link style={{fontFamily:"Optima",fontSize:"1.5rem",color:"white",fontWeight:"bold",marginLeft:"10px"}} href="/icmt-cn/jury" onClick={(e)=>{
                                        //this.props.setval({pageSelected:"jury"})
                                        }}>{menu["cn"]["jury"]}</Nav.Link>
                                    <Nav.Link style={{fontFamily:"Optima",fontSize:"1.5rem",color:"white",fontWeight:"bold",marginLeft:"10px"}} href="/icmt-cn/contact" onClick={(e)=>{
                                        //this.props.setval({pageSelected:"jury"})
                                        }}>{menu["cn"]["contact"]}</Nav.Link>
                                    <Nav.Link style={{fontFamily:"Optima",color:"white",fontWeight:"bold",marginLeft:"10px"}} href="/icmt-cn/winners" onClick={(e)=>{
                                        //this.props.setval({pageSelected:"jury"})
                                        }}>{menu["cn"]["winners"]}</Nav.Link>
                                   
                                    </Nav>
                                   
                                </Navbar.Collapse>
                            </Navbar>
                         
                    </div>
                </div>
            </div>
        )  
    }
}

export default icmtCnNavBarTop;